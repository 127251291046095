import Axios, { AxiosInstance } from "axios";
import urljoin from "url-join";
import Routes from "../../data/Routes";
import { IHeartbeatsData } from "./api-interfaces/common/IHeartbeatsData";
import { IViewOtherDatesRequest } from "./api-interfaces/common/IViewOtherDatesRequest";
import ApiCommunicationError from "./api-interfaces/errors/ApiCommunicationError";
import BadRequestError from "./api-interfaces/errors/BadRequestError";
import NotFoundError from "./api-interfaces/errors/NotFoundError";
import IGetAuthTokenRequest from "./api-interfaces/login/IGetAuthTokenRequest";
import IGetAuthTokenResponse from "./api-interfaces/login/IGetAuthTokenResponse";
import IActivateApiKeyIdRequest from "./api-interfaces/projects/api-keys/IActivateApiKeyIdRequest";
import IActivateECApiKeyRequest from "./api-interfaces/projects/api-keys/IActivateECApiKeyRequest";
import IDeactivateApiKeyIdRequest from "./api-interfaces/projects/api-keys/IDeactivateApiKeyIdRequest";
import IDeactivateECApiKeyRequest from "./api-interfaces/projects/api-keys/IDeactivateECApiKeyRequest";
import IGenerateSecretResponse from "./api-interfaces/projects/api-keys/IGenerateSecretResponse";
import IGetApiKeysForEngineCompartmentResponse
  from "./api-interfaces/projects/api-keys/IGetApiKeysForEngineCompartmentResponse";
import IGetApiKeysForProjectResponse from "./api-interfaces/projects/api-keys/IGetApiKeysForProjectResponse";
import IGetDetailsForCreationResponse from "./api-interfaces/projects/api-keys/IGetDetailsForCreationResponse";
import IGetDetailsForSavingResponse from "./api-interfaces/projects/api-keys/IGetDetailsForSavingResponse";
import IGetProjectFullNameResponse from "./api-interfaces/projects/api-keys/IGetProjectFullNameResponse";
import ISaveApiKeyECRequest from "./api-interfaces/projects/api-keys/ISaveApiKeyECRequest";
import ISaveApiKeyECResponse from "./api-interfaces/projects/api-keys/ISaveApiKeyECResponse";
import ISaveApiKeyRequest from "./api-interfaces/projects/api-keys/ISaveApiKeyRequest";
import ISaveApiKeyResponse from "./api-interfaces/projects/api-keys/ISaveApiKeyResponse";
import IGetHomeDataResponse from "./api-interfaces/projects/IGetHomeDataResponse";
import IFilterRequest from "./api-interfaces/projects/logs/IFilterRequest";
import IFilterResponse from "./api-interfaces/projects/logs/IFilterResponse";
import IGetProjectLogsResponse from "./api-interfaces/projects/logs/IGetProjectLogsResponse";
import ICreateProjectGroupRequest from "./api-interfaces/projects/manage/ICreateGroupRequest";
import ICreateProjectRequest from "./api-interfaces/projects/manage/ICreateProjectRequest";
import { IEditGroupRequest } from "./api-interfaces/projects/manage/IEditGroupRequest";
import { IGetGroupDeleteInfoResponse } from "./api-interfaces/projects/manage/IGetGroupDeleteInfoResponse";
import { IGetGroupLocationsResponse } from "./api-interfaces/projects/manage/IGetGroupLocationsResponse";
import { IGetInfoForProjectUpdateResponse } from "./api-interfaces/projects/manage/IGetInfoForProjectUpdateResponse";
import { IGetProjectDeleteInfoResponse } from "./api-interfaces/projects/manage/IGetProjectDeleteInfoResponse";
import { IGetProjectLocationsResponse } from "./api-interfaces/projects/manage/IGetProjectLocationsResponse";
import { IGroupsAndProjects } from "./api-interfaces/projects/manage/IGroupsAndProjects";
import { IMoveGroupRequest } from "./api-interfaces/projects/manage/IMoveGroupRequest";
import { IMoveProjectRequest } from "./api-interfaces/projects/manage/IMoveProjectRequest";
import { IUpdateProjectRequest } from "./api-interfaces/projects/manage/IUpdateProjectRequest";
import ICreateLineRequest from "./api-interfaces/projects/project-info/ICreateLineRequest";
import IGetProjectInfoResponse from "./api-interfaces/projects/project-info/IGetProjectInfoResponse";
import IMoveLineRequest from "./api-interfaces/projects/project-info/IMoveLineRequest";
import IProjectInfoLinesResponse from "./api-interfaces/projects/project-info/IProjectInfoLinesResponse";
import IUpdateLineRequest from "./api-interfaces/projects/project-info/IUpdateLineRequest";
import { IAddUserGroupRequest } from "./api-interfaces/projects/security/IAddUserGroupRequest";
import IGetGroupResponse from "./api-interfaces/projects/security/IGetGroupResponse";
import { IGetProjectSecurityResponse } from "./api-interfaces/projects/security/IGetProjectSecurityResponse";
import { IProjectSecurityResponse } from "./api-interfaces/projects/security/IProjectSecurityResponse";
import { IHomeResponse } from "./api-interfaces/projects/telemetry/IHomeResponse";
import { IFilterDataRequest } from "./api-interfaces/projects/telemetry/performance/IFilterDataRequest";
import { IPerformanceData } from "./api-interfaces/projects/telemetry/performance/IPerformanceData";
import { IPerformanceHomeResponse } from "./api-interfaces/projects/telemetry/performance/IPerformanceHomeResponse";
import { ISaveSystemSettingRequest } from "./api-interfaces/system/ISaveSystemSettingRequest";
import { ISystemFilterResponse } from "./api-interfaces/system/ISystemFilterResponse";
import { ISystemHomeResponse } from "./api-interfaces/system/ISystemHomeResponse";
import { ISystemPerformanceHomeResponse } from "./api-interfaces/system/ISystemPerformanceHomeResponse";
import { ISystemSettingInfoResponse } from "./api-interfaces/system/ISystemSettingInfoResponse";
import { IAddPermissionToGroupRequest } from "./api-interfaces/system/security/IAddPermissionToGroupRequest";
import { IAddUserToGroupRequest } from "./api-interfaces/system/security/IAddUserToGroupRequest";
import { ICreateUserGroupRequest } from "./api-interfaces/system/security/ICreateUserGroupRequest";
import { ISecurityGroupDetailsResponse } from "./api-interfaces/system/security/ISecurityGroupDetailsResponse";
import { ISystemSecurityHomeResponse } from "./api-interfaces/system/security/ISystemSecurityHomeResponse";
import { IUpdateUserGroupRequest } from "./api-interfaces/system/security/IUpdateUserGroupRequest";
import { IUserGroupInfo } from "./api-interfaces/system/security/IUserGroupInfo";
import IActivateRequest from "./api-interfaces/users/IActivateRequest";
import ICreateUserRequest from "./api-interfaces/users/ICreateUserRequest";
import ICreateUserResponse from "./api-interfaces/users/ICreateUserResponse";
import IDisableRequest from "./api-interfaces/users/IDisableRequest";
import IEditUserRequest from "./api-interfaces/users/IEditUserRequest";
import IGetDetailsResponse from "./api-interfaces/users/IGetDetailsResponse";
import IGetUsersResponse from "./api-interfaces/users/IGetUsersResponse";

export default class Api {
  public static async getAuthToken(data: IGetAuthTokenRequest): Promise<IGetAuthTokenResponse> {
    let url = Api.buildUrl('/api/ui/page/login');

    try {
      let response = await Axios.post<IGetAuthTokenResponse>(url, data);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get authentication token');
    }
  }

  public static async getGroupsWithProjects(accessToken: string): Promise<IGetHomeDataResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/projects/home');

    try {
      let response = await client.get<IGetHomeDataResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get groups with projects');
    }
  }

  public static async getGroupsWithProjectsOneLevel(accessToken: string): Promise<IGroupsAndProjects> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/projects/manage');

    try {
      let response = await client.get<IGroupsAndProjects>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get groups with projects one level');
    }
  }

  public static async getGroupOneLevel(accessToken: string, groupId: number): Promise<IGetGroupResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl(`/api/ui/page/projects/manage/group/${groupId}`);

    try {
      let response = await client.get<IGetGroupResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, `Can't get project group by id: ${groupId}`);
    }
  }

  public static async editProjectGroup(accessToken: string, groupId: number, request: IEditGroupRequest): Promise<IGroupsAndProjects> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl(`/api/ui/page/projects/manage/group/${groupId}`);

    try {
      let response = await client.post<IGroupsAndProjects>(url, request);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, `Can't edit project group with id: ${groupId}`);
    }
  }

  public static async createProjectGroup(accessToken: string, request: ICreateProjectGroupRequest): Promise<IGroupsAndProjects> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl(`/api/ui/page/projects/manage/group`);

    try {
      let response = await client.put<IGroupsAndProjects>(url, request);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, `Can't create project group`);
    }
  }

  public static async getGroupLocations(accessToken: string, groupId: number): Promise<IGetGroupLocationsResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl(`/api/ui/page/projects/manage/move/group/${groupId}`);

    try {
      let response = await client.get<IGetGroupLocationsResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, `Can't get group location`);
    }
  }

  public static async moveProjectGroup(accessToken: string, groupId: number, request: IMoveGroupRequest): Promise<void> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl(`/api/ui/page/projects/manage/move/group/${groupId}`);

    try {
      await client.post<void>(url, request);
    } catch (err) {
      throw Api.buildCommunicationException(err, `Can't move project group`);
    }
  }

  public static async getProjectGroupDeleteInfo(accessToken: string, groupId: number): Promise<IGetGroupDeleteInfoResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl(`/api/ui/page/projects/manage/delete/group/${groupId}`);

    try {
      let response = await client.get<IGetGroupDeleteInfoResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get project group delete info');
    }
  }

  public static async deleteProjectGroup(accessToken: string, groupId: number): Promise<IGroupsAndProjects> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl(`/api/ui/page/projects/manage/delete/group/${groupId}`);

    try {
      let response = await client.delete<IGroupsAndProjects>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t delete project group');
    }
  }

  public static async createProject(accessToken: string, request: ICreateProjectRequest): Promise<IGroupsAndProjects> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl(`/api/ui/page/projects/manage/create/project`);

    try {
      let response = await client.put<IGroupsAndProjects>(url, request);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, `Can't create project`);
    }
  }

  public static async getInfoForProjectUpdate(accessToken: string, projectId: number): Promise<IGetInfoForProjectUpdateResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl(`/api/ui/page/projects/manage/update/project/${projectId}`);

    try {
      let response = await client.get<IGetInfoForProjectUpdateResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, `Can't get info for project update`);
    }
  }

  public static async updateProject(accessToken: string, projectId: number, request: IUpdateProjectRequest): Promise<IGroupsAndProjects> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl(`/api/ui/page/projects/manage/update/project/${projectId}`);

    try {
      let response = await client.post<IGroupsAndProjects>(url, request);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, `Can't update project with id: ${projectId}`);
    }
  }

  public static async getProjectLocations(accessToken: string, projectId: number): Promise<IGetProjectLocationsResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl(`/api/ui/page/projects/manage/move/project/${projectId}`);

    try {
      let response = await client.get<IGetProjectLocationsResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, `Can't get project location`);
    }
  }

  public static async moveProject(accessToken: string, projectId: number, request: IMoveProjectRequest): Promise<void> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl(`/api/ui/page/projects/manage/move/project/${projectId}`);

    try {
      await client.post<void>(url, request);
    } catch (err) {
      throw Api.buildCommunicationException(err, `Can't move project`);
    }
  }

  public static async getProjectDeleteInfo(accessToken: string, projectId: number): Promise<IGetProjectDeleteInfoResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl(`/api/ui/page/projects/manage/delete/project/${projectId}`);

    try {
      let response = await client.get<IGetProjectDeleteInfoResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get project delete info');
    }
  }

  public static async deleteProject(accessToken: string, projectId: number): Promise<IGroupsAndProjects> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl(`/api/ui/page/projects/manage/delete/project/${projectId}`);

    try {
      let response = await client.delete<IGroupsAndProjects>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t delete project');
    }
  }

  public static async moveLineUp(accessToken: string, request: IMoveLineRequest): Promise<IProjectInfoLinesResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/project/info/move-up');

    try {
      let response = await client.post<IProjectInfoLinesResponse>(url, request);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t move up project info line');
    }
  }

  public static async moveLineDown(accessToken: string, request: IMoveLineRequest): Promise<IProjectInfoLinesResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/project/info/move-down');

    try {
      let response = await client.post<IProjectInfoLinesResponse>(url, request);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t move down project info line');
    }
  }

  public static async createLine(accessToken: string, request: ICreateLineRequest): Promise<IProjectInfoLinesResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/project/info');

    try {
      let response = await client.post<IProjectInfoLinesResponse>(url, request);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t create line');
    }
  }

  public static async updateLine(accessToken: string, request: IUpdateLineRequest): Promise<IProjectInfoLinesResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/project/info');

    try {
      let response = await client.patch<IProjectInfoLinesResponse>(url, request);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t update line');
    }
  }

  public static async deleteLine(accessToken: string, id: number): Promise<IProjectInfoLinesResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/project/info/' + id);

    try {
      let response = await client.delete<IProjectInfoLinesResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t delete line');
    }
  }

  public static async getProjectInfo(accessToken: string, projectId: number): Promise<IGetProjectInfoResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/project/' + projectId);

    try {
      let response = await client.get<IGetProjectInfoResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get project info');
    }
  }

  public static async getProjectFullName(accessToken: string, projectId: number): Promise<IGetProjectFullNameResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/api-keys/home/' + projectId);

    try {
      let response = await client.get<IGetProjectFullNameResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get project full name');
    }
  }

  public static async getApiKeysForConnectingToProject(accessToken: string, projectId: number): Promise<IGetApiKeysForProjectResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/api-keys/project/?projectId=' + projectId);

    try {
      let response = await client.get<IGetApiKeysForProjectResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get API keys for connecting to project');
    }
  }

  public static async activateApiKey(accessToken: string, request: IActivateApiKeyIdRequest): Promise<void> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/api-keys/project/activate');

    try {
      await client.post<void>(url, request);
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t activate api key');
    }
  }

  public static async deactivateApiKey(accessToken: string, request: IDeactivateApiKeyIdRequest): Promise<void> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/api-keys/project/deactivate');

    try {
      await client.post<void>(url, request);
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t deactivate api key');
    }
  }

  public static async deleteApiKey(accessToken: string, apiKeyId: number): Promise<void> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/api-keys/project/?apiKeyId=' + apiKeyId);

    try {
      await client.delete<void>(url);
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t delete api key');
    }
  }

  public static async getApiKeyDetailsForSaving(accessToken: string, apiKeyId: number): Promise<IGetDetailsForSavingResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/api-keys/project/save/' + apiKeyId);

    try {
      let response = await client.get<IGetDetailsForSavingResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get api key details for saving');
    }
  }

  public static async getApiKeyDetailsForCreation(accessToken: string, projectId: number): Promise<IGetDetailsForCreationResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/api-keys/project/create/' + projectId);

    try {
      let response = await client.get<IGetDetailsForCreationResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get api key details for creation');
    }
  }

  public static async generateSecret(accessToken: string): Promise<IGenerateSecretResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/api-keys/project/generate-secret');

    try {
      let response = await client.get<IGenerateSecretResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t generate secret');
    }
  }

  public static async createOrUpdateApiKey(accessToken: string, request: ISaveApiKeyRequest): Promise<ISaveApiKeyResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/api-keys/project/save');
    try {
      let response = await client.put<ISaveApiKeyResponse>(url, request);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t create or update api key');
    }
  }

  public static async getApiKeyForConnectingToEngineCompartment(accessToken: string, projectId: number): Promise<IGetApiKeysForEngineCompartmentResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/api-keys/engine-compartment/' + projectId);

    try {
      let response = await client.get<IGetApiKeysForEngineCompartmentResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get API key for connecting to engine compartment');
    }
  }

  public static async activateEngineCompartmentApiKey(accessToken: string, request: IActivateECApiKeyRequest): Promise<void> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/api-keys/engine-compartment/activate');

    try {
      await client.post<void>(url, request);
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t activate engine compartment api key');
    }
  }


  public static async deactivateEngineCompartmentApiKey(accessToken: string, request: IDeactivateECApiKeyRequest): Promise<void> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/api-keys/engine-compartment/deactivate');

    try {
      await client.post<void>(url, request);
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t deactivate engine compartment api key');
    }
  }

  public static async deleteEngineCompartmentApiKey(accessToken: string, projectId: number): Promise<void> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/api-keys/engine-compartment/' + projectId);

    try {
      await client.delete<void>(url);
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t delete engine compartment api key');
    }
  }

  public static async generateSecretForEngineCompartment(accessToken: string): Promise<IGenerateSecretResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/api-keys/engine-compartment/generate-secret');

    try {
      let response = await client.get<IGenerateSecretResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t generate secret for engine compartment');
    }
  }

  public static async saveApiKeyForEngineCompartment(accessToken: string, request: ISaveApiKeyECRequest): Promise<ISaveApiKeyECResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/api-keys/engine-compartment');

    try {
      let response = await client.put<ISaveApiKeyECResponse>(url, request);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t save api key for engine compartment');
    }
  }

  public static async getHeartbeatsData(accessToken: string, projectId: number): Promise<IHomeResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/heartbeats/' + projectId);

    try {
      let response = await client.get<IHomeResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get heartbeats data');
    }
  }

  public static async viewOtherDates(accessToken: string, projectId: number, request: IViewOtherDatesRequest): Promise<IHeartbeatsData> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/heartbeats/' + projectId);

    try {
      let response = await client.post<IHeartbeatsData>(url, request);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get heartbeats data with other dates');
    }
  }

  public static async getPerformanceData(accessToken: string, projectId: number): Promise<IPerformanceHomeResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/performance/' + projectId);

    try {
      let response = await client.get<IPerformanceHomeResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get performance data');
    }
  }

  public static async filterData(accessToken: string, projectId: number, request: IFilterDataRequest): Promise<IPerformanceData> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/performance/' + projectId);

    try {
      let response = await client.post<IPerformanceData>(url, request);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get performance filter data');
    }
  }

  public static async getProjectLogs(accessToken: string, projectId: number): Promise<IGetProjectLogsResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/log/' + projectId);

    try {
      let response = await client.get<IGetProjectLogsResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get project logs');
    }
  }

  public static async filterProjectLogs(accessToken: string, request: IFilterRequest, projectId: number): Promise<IFilterResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/log/' + projectId);

    try {
      let response = await client.post<IFilterResponse>(url, request);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t filter project logs');
    }
  }

  public static async getProjectSecurity(accessToken: string, projectId: number): Promise<IGetProjectSecurityResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl(`/api/ui/page/projects/security/${projectId}`);

    try {
      let response = await client.get<IGetProjectSecurityResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get project security');
    }
  }

  public static async addOrUpdateUserGroup(accessToken: string, projectId: number, request: IAddUserGroupRequest): Promise<IProjectSecurityResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl(`/api/ui/page/projects/security/${projectId}`);

    try {
      let response = await client.post<IProjectSecurityResponse>(url, request);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t create project security group');
    }
  }

  public static async getUsers(accessToken: string): Promise<IGetUsersResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/users');

    try {
      let response = await client.get<IGetUsersResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get users');
    }
  }

  public static async activateUser(accessToken: string, request: IActivateRequest): Promise<void> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/users/activate');

    try {
      await client.post<void>(url, request);
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t activate user');
    }
  }

  public static async deactivateUser(accessToken: string, request: IDisableRequest): Promise<void> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/users/deactivate');

    try {
      await client.post<void>(url, request);
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t deactivate user ');
    }
  }

  public static async createUser(accessToken: string, user: ICreateUserRequest): Promise<ICreateUserResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/users/create');

    try {
      let response = await client.put<ICreateUserResponse>(url, user);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t create user');
    }
  }

  public static async getUserDetails(accessToken: string, userId: number): Promise<IGetDetailsResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/users/edit/' + userId);

    try {
      let response = await client.get<IGetDetailsResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get user details');
    }
  }

  public static async updateUser(accessToken: string, request: IEditUserRequest): Promise<void> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/users/edit');

    try {
      await client.patch<void>(url, request);
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t update user');
    }
  }

  public static async deleteUser(accessToken: string, userId: number): Promise<void> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/users/' + userId);

    try {
      await client.delete<void>(url);
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t delete user');
    }
  }

  public static async getSystemHeartbeatsData(accessToken: string): Promise<ISystemHomeResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/system/telemetry/heartbeats');

    try {
      let response = await client.get<ISystemHomeResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get system heartbeats data');
    }
  }

  public static async viewSystemOtherDates(accessToken: string, request: IViewOtherDatesRequest): Promise<IHeartbeatsData> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/system/telemetry/heartbeats');

    try {
      let response = await client.post<IHeartbeatsData>(url, request);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get system heartbeats data with other dates');
    }
  }

  public static async getSystemPerformanceData(accessToken: string): Promise<ISystemPerformanceHomeResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/system/telemetry/performance');

    try {
      let response = await client.get<ISystemPerformanceHomeResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get system performance data');
    }
  }

  public static async filterSystemPerformanceData(accessToken: string, request: IFilterDataRequest): Promise<IPerformanceData> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/system/telemetry/performance');

    try {
      let response = await client.post<IPerformanceData>(url, request);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get system performance filter data');
    }
  }

  public static async getSystemLogs(accessToken: string): Promise<IGetProjectLogsResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/system/telemetry/log');

    try {
      let response = await client.get<IGetProjectLogsResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get system logs');
    }
  }

  public static async filterSystemLogs(accessToken: string, request: IFilterRequest): Promise<ISystemFilterResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/system/telemetry/log');

    try {
      let response = await client.post<ISystemFilterResponse>(url, request);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t filter system logs');
    }
  }

  public static async getSystemSettings(accessToken: string): Promise<ISystemSettingInfoResponse[]> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/system/settings');

    try {
      let response = await client.get<ISystemSettingInfoResponse[]>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get system settings');
    }
  }

  public static async saveSystemSettings(accessToken: string, request: ISaveSystemSettingRequest): Promise<ISystemSettingInfoResponse[]> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/system/settings');

    try {
      let response = await client.post<ISystemSettingInfoResponse[]>(url, request);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t save system settings');
    }
  }

  public static async getUserGroups(accessToken: string): Promise<ISystemSecurityHomeResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/system/security/');

    try {
      let response = await client.get<ISystemSecurityHomeResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get user groups');
    }
  }

  public static async createUserGroup(accessToken: string, request: ICreateUserGroupRequest): Promise<IUserGroupInfo[]> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl(`/api/ui/page/system/security/group`);

    try {
      let response = await client.put<IUserGroupInfo[]>(url, request);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t create user group');
    }
  }


  public static async updateUserGroup(accessToken: string, request: IUpdateUserGroupRequest, groupId: number): Promise<IUserGroupInfo[]> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl(`/api/ui/page/system/security/group/${groupId}`);

    try {
      let response = await client.post<IUserGroupInfo[]>(url, request);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t update user group');
    }
  }

  public static async deleteUserGroup(accessToken: string, groupId: number): Promise<IUserGroupInfo[]> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl(`/api/ui/page/system/security/group/${groupId}`);

    try {
      let response = await client.delete<IUserGroupInfo[]>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t delete user groups');
    }
  }

  public static async getSecurityGroupDetails(accessToken: string, groupId: number): Promise<ISecurityGroupDetailsResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl(`/api/ui/page/system/security/manage/${groupId}`);

    try {
      let response = await client.get<ISecurityGroupDetailsResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get security group details');
    }
  }

  public static async addUserToGroup(accessToken: string, request: IAddUserToGroupRequest): Promise<ISecurityGroupDetailsResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl(`/api/ui/page/system/security/manage/users`);

    try {
      let response = await client.put<ISecurityGroupDetailsResponse>(url, request);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t add user to group');
    }
  }

  public static async deleteUserFromGroup(accessToken: string, groupId: number, userId: number): Promise<ISecurityGroupDetailsResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl(`/api/ui/page/system/security/manage/users/${groupId}/${userId}`);

    try {
      let response = await client.delete<ISecurityGroupDetailsResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t delete user from group');
    }
  }

  public static async addPermissionToGroup(accessToken: string, request: IAddPermissionToGroupRequest): Promise<ISecurityGroupDetailsResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl(`/api/ui/page/system/security/manage/permission`);

    try {
      let response = await client.put<ISecurityGroupDetailsResponse>(url, request);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t add permission to group');
    }
  }

  public static async deletePermissionFromGroup(accessToken: string, groupId: number, permissionCode: string): Promise<ISecurityGroupDetailsResponse> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl(`/api/ui/page/system/security/manage/permission/${groupId}/${permissionCode}`);

    try {
      let response = await client.delete<ISecurityGroupDetailsResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t delete permission from group');
    }
  }

  public static async changePassword(accessToken: string, request: any): Promise<void> {
    let client = Api.getAuthenticatedAxiosClient(accessToken);
    let url = Api.buildUrl('/api/ui/page/users/profile/change-password');

    try {
      await client.post<void>(url, request);
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t change password');
    }
  }

  private static buildCommunicationException(err: any, generalErrorMessage: string): Error {
    if (err.response) {
      if (err.response.status === 400) {
        let content = err.response.data.errors;
        throw new BadRequestError(content);
      }
      if (err.response.status === 401) {
        window.location.pathname = Routes.login;
      }
      if (err.response.status === 403) {
        window.location.pathname = Routes.noPermitted;
      }
      if (err.response.status === 404) {
        throw new NotFoundError(err);
      }
    }
    throw new ApiCommunicationError(generalErrorMessage, err);
  }

  private static buildUrl(relativePath: string): string {
    let apiBaseUrl = (window as any).EngineCompertmentSettings.apiUrl;

    return urljoin(apiBaseUrl, relativePath);
  }

  private static getAuthenticatedAxiosClient(accessToken: string): AxiosInstance {
    return Axios.create({
      headers: {
        Authorization: 'Bearer ' + accessToken,
        Accept: 'application/json'
      },
    });
  }
}