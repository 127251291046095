import * as React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './App.scss';
import Authorize from "./components/common/authorize/Authorize";
import Footer from "./components/common/layout-components/footer/Footer";
import Header from "./components/common/layout-components/header/Header";
import LoginPage from "./components/pages/login-page/LoginPage";
import NoPermittedPage from "./components/pages/no-permitted-page/NoPermittedPage";
import NotFoundPage from "./components/pages/not-found-page/NotFoundPage";
import ProfilePage from "./components/pages/profile-page/ProfilePage";
import CreateOrEditApiKeyPage
  from "./components/pages/projects/api-keys/create-or-edit-project-api-key-page/CreateOrEditApiKeyPage";
import EngineCompartmentApiKeyPage
  from "./components/pages/projects/api-keys/engine-compartment-api-key-page/EngineCompartmentApiKeyPage";
import ProjectApiKeysPage from "./components/pages/projects/api-keys/project-api-keys-home-page/ProjectApiKeysHomePage";
import ProjectKeysPage from "./components/pages/projects/api-keys/project-keys-page/ProjectKeysPage";
import LogsPage from "./components/pages/projects/logs-page/LogsPage";
import ManageProjectsTreePage from "./components/pages/projects/manage-projects-tree-page/ManageProjectsTreePage";
import MoveProjectsPage from "./components/pages/projects/move-projects-page/MoveProjectsPage";
import ProjectsInfoEditPage from "./components/pages/projects/project-info/project-info-edit-page/ProjectInfoEditPage";
import ProjectsInfoPage from "./components/pages/projects/project-info/project-info-page/ProjectsInfoPage";
import ProjectSecurityPage from "./components/pages/projects/project-security-page/ProjectSecurityPage";
import ProjectsPage from "./components/pages/projects/projects-page/ProjectsPage";
import PerformancePage from "./components/pages/projects/telemetry/performance-page/PerformancePage";
import TelemetryPage from "./components/pages/projects/telemetry/telemetry-page/TelemetryPage";
import SecurityPage from "./components/pages/system/security-page/SecurityPage";
import SetupSecurityGroupPage from "./components/pages/system/security-page/setup-security-group-page/SetupSecurityGroupPage";
import SystemHeartbeatsPage from "./components/pages/system/system-heartbeats-page/SystemHeartbeatsPage";
import SystemLogsPage from "./components/pages/system/system-logs-page/SystemLogsPage";
import SystemPerformancePage from "./components/pages/system/system-performance-page/SystemPerformancePage";
import SystemSettingsPage from "./components/pages/system/system-settings-page/SystemSettingsPage";
import CreateUserPage from "./components/pages/users/create-user-page/CreateUserPage";
import UpdateUserPage from "./components/pages/users/update-user-page/UpdateUserPage";
import UsersPage from "./components/pages/users/users-page/UsersPage";
import Routes from "./data/Routes";

const App: React.FC = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <div className="contentContainer">

          <Header/>

          <Switch>
            <Route exact path={Routes.login} render={() => <LoginPage/>}/>

            <Route exact path={Routes.home}>
              <Redirect to={Routes.projects}/>
            </Route>

            <Route exact path={Routes.projects}>
              <Authorize>
                <ProjectsPage/>
              </Authorize>
            </Route>

            <Route exact path={Routes.manageProjectsTree}>
              <Authorize>
                <ManageProjectsTreePage groupId={undefined} />
              </Authorize>
            </Route>

            <Route exact path={Routes.projectGroup} render={
              ({match}) => (
                <Authorize>
                  <ManageProjectsTreePage groupId={match.params.groupId}/>
                </Authorize>
              )
            }/>

            <Route exact path={Routes.moveProjectGroup} render={
              ({match}) => (
                <Authorize>
                  <MoveProjectsPage groupId={match.params.groupId} projectId={undefined}
                                    groupIdToRedirect={match.params.groupIdToRedirect}/>
                </Authorize>
              )
            }/>

            <Route exact path={Routes.moveProject} render={
              ({match}) => (
                <Authorize>
                  <MoveProjectsPage projectId={match.params.projectId} groupId={undefined}
                                    groupIdToRedirect={match.params.groupIdToRedirect}/>
                </Authorize>
              )
            }/>

            <Route exact path={Routes.projectInfo} render={
              ({match}) => (
                <Authorize>
                  <ProjectsInfoPage projectId={match.params.projectId}/>
                </Authorize>
              )
            }/>

            <Route exact path={Routes.editProjectInfo} render={
              ({match}) => (
                <Authorize>
                  <ProjectsInfoEditPage projectId={match.params.projectId}/>
                </Authorize>
              )
            }/>

            <Route exact path={Routes.apiKeysHome} render={
              ({match}) => (
                <Authorize>
                  <ProjectApiKeysPage projectId={match.params.projectId}/>
                </Authorize>
              )
            }/>

            <Route exact path={Routes.projectApiKeys} render={
              ({match}) => (
                <Authorize>
                  <ProjectKeysPage projectId={match.params.projectId}/>
                </Authorize>
              )
            }/>

            <Route exact path={Routes.createProjectApiKey} render={
              ({match}) => (
                <Authorize>
                  <CreateOrEditApiKeyPage projectId={match.params.projectId} apiKeyId={match.params.apiKeyId}/>
                </Authorize>
              )
            }/>

            <Route exact path={Routes.editProjectApiKey} render={
              ({match}) => (
                <Authorize>
                  <CreateOrEditApiKeyPage projectId={match.params.projectId} apiKeyId={match.params.apiKeyId}/>
                </Authorize>
              )
            }/>

            <Route exact path={Routes.engineCompartmentApiKey} render={
              ({match}) => (
                <Authorize>
                  <EngineCompartmentApiKeyPage projectId={match.params.projectId}/>
                </Authorize>
              )
            }/>


            <Route exact path={Routes.projectTelemetry} render={
              ({match}) => (
                <Authorize>
                  <TelemetryPage projectId={match.params.projectId}/>
                </Authorize>
              )
            }/>

            <Route exact path={Routes.projectHeartbeats} render={
              ({match}) => (
                <Authorize>
                  <TelemetryPage projectId={match.params.projectId}/>
                </Authorize>
              )
            }/>

            <Route exact path={Routes.projectPerformance} render={
              ({match}) => (
                <Authorize>
                  <PerformancePage projectId={match.params.projectId}/>
                </Authorize>
              )
            }/>

            <Route exact path={Routes.projectLogs} render={
              ({match}) => (
                <Authorize>
                  <LogsPage projectId={match.params.projectId}/>
                </Authorize>
              )
            }/>

            <Route exact path={Routes.projectSecurity} render={
              ({match}) => (
                <Authorize>
                  <ProjectSecurityPage projectId={match.params.projectId}/>
                </Authorize>
              )
            }/>

            <Route exact path={Routes.users}>
              <Authorize>
                <UsersPage/>
              </Authorize>
            </Route>

            <Route exact path={Routes.createUser}>
              <Authorize>
                <CreateUserPage/>
              </Authorize>
            </Route>

            <Route exact path={Routes.updateUser} render={
              ({match}) => (
                <Authorize>
                  <UpdateUserPage userId={match.params.userId}/>
                </Authorize>
              )
            }/>

            <Route exact path={Routes.systemHeartbeats}>
              <Authorize>
                <SystemHeartbeatsPage/>
              </Authorize>
            </Route>

            <Route exact path={Routes.systemPerformance}>
              <Authorize>
                <SystemPerformancePage/>
              </Authorize>
            </Route>

            <Route exact path={Routes.systemLogs}>
              <Authorize>
                <SystemLogsPage/>
              </Authorize>
            </Route>

            <Route exact path={Routes.systemSettings}>
              <Authorize>
                <SystemSettingsPage/>
              </Authorize>
            </Route>

            <Route exact path={Routes.systemSecurity}>
              <Authorize>
                <SecurityPage/>
              </Authorize>
            </Route>

            <Route exact path={Routes.setupSecurityGroup} render={
              ({match}) => (
                <Authorize>
                  <SetupSecurityGroupPage securityGroupId={match.params.securityGroupId}/>
                </Authorize>
              )
            }/>

            <Route exact path={Routes.profile}>
              <Authorize>
                <ProfilePage/>
              </Authorize>
            </Route>

            <Route exact path={Routes.noPermitted}>
              <Authorize>
                <NoPermittedPage/>
              </Authorize>
            </Route>

            <Route path="*">
              <NotFoundPage/>
            </Route>
          </Switch>
        </div>

        <footer className="footerPin">
          <Footer/>
        </footer>

      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
