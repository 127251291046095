import classNames from 'classnames';
import React, { Component } from 'react';
import ReactModal from 'react-modal';
import IconButton from "../../../../common/buttons/icon-button/IconButton";
import styles from './DeleteProjectModal.module.scss';
import { IDeleteProjectModalProps } from "./IDeleteProjectModalProps";
import { IDeleteProjectModalState } from "./IDeleteProjectModalState";

class DeleteProjectModal extends Component<IDeleteProjectModalProps, IDeleteProjectModalState> {

  private static removeDialogIndex = 100;

  constructor(props: IDeleteProjectModalProps) {
    super(props);

    this.state = {
      dialogId: 'delete_project_dialog_' + (DeleteProjectModal.removeDialogIndex++)
    };

    this.onConfirmClicked = this.onConfirmClicked.bind(this);
  }

  render() {
    return (
      <ReactModal
        appElement={document.getElementById('root') as HTMLElement}
        isOpen={this.props.showDialog}
        id={this.state.dialogId}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        className={classNames([styles.dialog, "shadow p-4"])}
      >
        <h2>Delete project</h2>

        <p>
          Are you sure you want to delete  <b className={"text-break"}> {this.props.projectNameToDelete} </b>
          with all it's <b>API keys</b>, <b>telemetry data</b> and <b>other settings</b> entirely? This action can not be reverted.
        </p>
        <div className="d-flex justify-content-end">
          <IconButton onClick={this.onConfirmClicked} variant="danger" title="Delete"
                      styles={{marginRight: '1em'}}/>
          <IconButton onClick={this.props.onCancel} variant="outline-primary" title="Cancel"/>
        </div>
      </ReactModal>
    );
  }

  onConfirmClicked() {
    if (this.props.onConfirm !== undefined && this.props.onConfirm !== null) {
      this.props.onConfirm(this.props.projectIdToDelete ?? 0);
    }
  }
}

export default DeleteProjectModal;