import classNames from "classnames";
import moment from "moment";
import React, { Component } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import ReactJson from "react-json-view";
import IconButton from "../../buttons/icon-button/IconButton";
import DateTimePickerComponent from "../../date-time-picker-component/DateTimePickerComponent";
import { VerticalSpaceSize } from "../../layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../layout-components/vertical-space/VerticalSpace";
import ValidationMessages from "../../validation-messages/ValidationMessages";
import { ILogsComponentProps } from "./ILogsComponentProps";
import styles from './LogsComponent.module.scss';

export default class LogsComponent extends Component<ILogsComponentProps> {

  constructor(props: Readonly<ILogsComponentProps>) {
    super(props);

    this.setFromDateClick = this.setFromDateClick.bind(this);
    this.setToDateClick = this.setToDateClick.bind(this);
    this.onLevelChangeClick = this.onLevelChangeClick.bind(this);
    this.onLoggerChangeClick = this.onLoggerChangeClick.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
  }

  render() {
    return (
      <>
        <Row>
          <Col md={"4"} sm={"12"} className={"my-2"}>
            <Form.Group controlId="fromDate">
              <Form.Label className={styles.label}>From</Form.Label><br/>
              <DateTimePickerComponent datePickerId={"fromDate"}
                                       selected={this.props.from}
                                       onChange={(date: Date) => this.setFromDateClick(date)}/>

              <ValidationMessages fieldName="From" errors={this.props.validationErrors ?? {}}/>
            </Form.Group>
          </Col>

          <Col md={"4"} sm={"12"} className={"my-2"}>
            <Form.Label className={styles.label}>To</Form.Label><br/>
            <DateTimePickerComponent datePickerId={"toDate"}
                                     selected={this.props.to}
                                     onChange={(date: Date) => this.setToDateClick(date)}/>
            <ValidationMessages fieldName="To" errors={this.props.validationErrors ?? {}}/>
          </Col>
        </Row>

        <Row>
          <Col md={"4"} className={"my-2"}>
            <Form.Group controlId="knownLevels">
              {
                this.props.knownLevels.length > 0 &&
                <>
                    <Form.Label className={styles.label}>Levels</Form.Label><br/>
                </>
              }
              {
                this.props.knownLevels.map((level, index) => {
                  return (
                    <div key={index}>
                      <Form.Check id={"level-" + index}
                                  type="checkbox"
                                  label={level.value}
                                  name={level.value}
                                  checked={level.isSelected}
                                  onChange={() => this.onLevelChangeClick(index)}
                      />
                    </div>
                  );
                })
              }
            </Form.Group>
          </Col>

          <Col md={"6"} className={"my-2"}>
            <Form.Group controlId="knownLoggers">
              {
                this.props.knownLoggers.length > 0 &&
                <>
                    <Form.Label className={styles.label}>Loggers</Form.Label><br/>
                </>
              }

              <div className={classNames(["overflow-auto", styles.maxHeight200px])}>
                {
                  this.props.knownLoggers.map((logger, index) => {
                    return (
                      <div key={index}>
                        <Form.Check id={"logger-" + index}
                                    type="checkbox"
                                    label={logger.value}
                                    name={logger.value}
                                    className={"mx-1"}
                                    checked={logger.isSelected}
                                    onChange={() => this.onLoggerChangeClick(index)}
                        />
                      </div>
                    );
                  })
                }
              </div>
            </Form.Group>
          </Col>
        </Row>

        <IconButton onClick={this.onSearchClick} variant="outline-primary" title="Search"/>

        {
          this.props.logs !== null && this.props.logs.length === 0 &&
          <div>
              <VerticalSpace size={VerticalSpaceSize.small}/>
              <Row>
                  <Col md={{span: "6", offset: "3"}}>
                      <Alert variant="info" className={"text-center"}>
                          No logs found.
                      </Alert>
                  </Col>
              </Row>
          </div>
        }

        <VerticalSpace size={VerticalSpaceSize.small}/>

        {
          this.props.logs && this.props.logs.length > 0 &&
          <>
              <div className="d-none d-lg-block">
                  <Row>
                      <Col lg={2} className={styles.maxWidth9Rem}><b>Date</b></Col>
                      <Col lg={1}><b>Level</b></Col>
                      <Col lg={3}><b>Logger</b></Col>
                      <Col lg={6}><b>Message/Properties json</b></Col>
                  </Row>
                  <hr className="my-2" style={{opacity: 1}}/>
              </div>

            {
              this.props.logs.map((log) => {
                return (
                  <Row key={log.id} className={"text-break"}>
                    <Col lg={2} className={styles.maxWidth9Rem}>
                      <span className="d-inline-block d-lg-none"><b>Date:&nbsp;</b></span>
                      {moment(log.timestamp).format('MM/DD/YYYY')} {moment(log.timestamp).format('h:mm:ss.SSS A')}
                    </Col>
                    <Col lg={1}>
                      <span className="d-inline-block d-lg-none"><b>Level:&nbsp;</b></span>{log.level}
                    </Col>
                    <Col lg={3} className={"text-break"}>
                      <span className="d-inline-block d-lg-none"><b>Logger:&nbsp;</b></span>{log.logger}
                    </Col>
                    <Col lg={6}>
                      <span className="d-inline-block d-lg-none"><b>Message:&nbsp;</b></span>{log.message}<br/>
                      <span className="d-inline-block d-lg-none"><b>Properties json:&nbsp;</b></span>

                      {
                        log.propertiesJson !== null &&
                        <ReactJson src={JSON.parse(log.propertiesJson)}
                                   name={false}
                                   iconStyle={"circle"}
                                   enableClipboard={false}
                                   displayDataTypes={false}
                                   displayObjectSize={false}
                        />
                      }

                      <span className="d-inline-block d-lg-none">{log.stackTrace &&
                      <b>Stack trace:&nbsp;</b>}</span>
                      <div style={{whiteSpace: 'break-spaces'}} dangerouslySetInnerHTML={{__html: log.stackTrace}}/>
                    </Col>
                    <hr className={"my-2"}/>
                  </Row>
                );
              })
            }
          </>
        }
      </>
    );
  }

  private setFromDateClick(date: Date) {
    if (this.props.onFromDateClick !== undefined) {
      this.props.onFromDateClick(date);
    }
  }

  private setToDateClick(date: Date) {
    if (this.props.onToDateClick !== undefined) {
      this.props.onToDateClick(date);
    }
  }

  private onLevelChangeClick(index: number): void {
    if (this.props.onLevelChangeClick !== undefined) {
      this.props.onLevelChangeClick(index);
    }
  }

  private onLoggerChangeClick(index: number) {
    if (this.props.onLoggerChangeClick !== undefined) {
      this.props.onLoggerChangeClick(index);
    }
  }

  private onSearchClick() {
    if (this.props.onSearchClick !== undefined) {
      this.props.onSearchClick();
    }
  }
}
