import React, { Component } from 'react';
import IWithEngineCompartmentAuthorizeProps, { IAuthProps } from "./IWithEngineCompartmentAuthorizeProps";
import AuthHelper from '../../../tools/auth/AuthHelper';

interface IWithHotelDiaryAuthorizeState {
  auth: IAuthProps
}

export default function withEngineCompartmentAuthorize<TProps extends IWithEngineCompartmentAuthorizeProps>(WrappedComponent: React.ComponentType<TProps>) {

  return class extends Component<Omit<TProps & IWithEngineCompartmentAuthorizeProps, keyof IWithEngineCompartmentAuthorizeProps>, IWithHotelDiaryAuthorizeState> {
    constructor(props: Omit<TProps & IWithEngineCompartmentAuthorizeProps, keyof IWithEngineCompartmentAuthorizeProps>) {
      super(props);

      const authProps: IAuthProps = this.loadAuthProps();

      this.state = {
        auth: authProps
      };

      this.onAuthChange = this.onAuthChange.bind(this);
    }

    private loadAuthProps(): IAuthProps {
      const isAuthenticated = AuthHelper.isTokenSet();

      const authProps: IAuthProps = {
        isAuthenticated: isAuthenticated
      };

      return authProps;
    }

    render() {
      return <WrappedComponent {...this.props as TProps} auth={this.state.auth}/>;
    }

    componentDidMount() {
      AuthHelper.onChange.on(this.onAuthChange);
      this.onAuthChange();
    }

    componentWillUnmount() {
      AuthHelper.onChange.off(this.onAuthChange);
    }

    private onAuthChange() {
      let state = {...this.state};

      state.auth = this.loadAuthProps();

      this.setState(state);
    }
  };
}
