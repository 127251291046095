import React, { Component } from 'react';
import { Alert, Col, Row } from "react-bootstrap";
import { VerticalSpaceSize } from "../../layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../layout-components/vertical-space/VerticalSpace";
import styles from './NoChartData.module.scss';

export default class NoChartData extends Component<{}> {
  render() {
    return (
      <div className={styles.noChartData}>
        <VerticalSpace size={VerticalSpaceSize.small}/>

        <Row>
          <Col md={{span: "6", offset: "3"}}>
            <Alert variant="info">
              No chart data
            </Alert>
          </Col>
        </Row>
      </div>
    );
  }
}
