import React, { Component } from "react";
import { Alert, Col, Nav, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Routes from "../../../../../data/Routes";
import Api from "../../../../../tools/api/api";
import IActivateApiKeyIdRequest
  from "../../../../../tools/api/api-interfaces/projects/api-keys/IActivateApiKeyIdRequest";
import IDeactivateApiKeyIdRequest
  from "../../../../../tools/api/api-interfaces/projects/api-keys/IDeactivateApiKeyIdRequest";
import AuthHelper from "../../../../../tools/auth/AuthHelper";
import PermissionHelper from "../../../../../tools/permissions/PermissionHelper";
import ValidationErrors from "../../../../../tools/validation-helper/ValidationErrors";
import Validations from "../../../../../tools/validation-helper/Validations";
import withEngineCompartmentAuthorize from "../../../../common/authorize/withEngineCompartmentAuthorize";
import ApiKeysSubMenu from "../../../../common/layout-components/api-keys-sub-menu/ApiKeysSubMenu";
import ContainerPageSize from "../../../../common/layout-components/container-page-size/ContainerPageSize";
import { LoadingBarHeight } from "../../../../common/layout-components/loading-bar/ILoadingBarProps";
import LoadingBar from "../../../../common/layout-components/loading-bar/LoadingBar";
import ProjectSubMenu from "../../../../common/layout-components/project-sub-menu/ProjectSubMenu";
import SubMenu from "../../../../common/layout-components/sub-menu/SubMenu";
import { VerticalSpaceSize } from "../../../../common/layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../../common/layout-components/vertical-space/VerticalSpace";
import ValidationSummary from "../../../../common/validation-summary/ValidationSummary";
import ApiKeyLine from "./api-key-line/ApiKeyLine";
import DeleteApiKeyModal from "./delete-api-key-modal/DeleteApiKeyModal";
import IProjectKeysPageProps from "./IProjectKeysPageProps";
import IProjectKeysPageState from "./IProjectKeysPageState";

class ProjectKeysPage extends Component<IProjectKeysPageProps, IProjectKeysPageState> {
  constructor(props: IProjectKeysPageProps) {
    super(props);

    this.state = {
      projectId: null,
      apiKeysForProject: null,
      validationErrors: null,
      isLoading: true,
      isDeleteDialogOpen: false,
      apiKeyIdToDelete: undefined,
      accessKeyToDelete: undefined
    };

    this.onConfirmDeleteApiKeyClicked = this.onConfirmDeleteApiKeyClicked.bind(this);
    this.onCancelDeleteApiKeyClicked = this.onCancelDeleteApiKeyClicked.bind(this);
  }

  render() {
    return (
      <>
        <ProjectSubMenu projectId={this.state.projectId ?? 0}/>

        <ApiKeysSubMenu projectId={this.state.projectId ?? 0}/>

        {
          PermissionHelper.doesUserHavePermissionGloballyOrInProject(PermissionHelper.getAllPermissions().PRJ_API_MNG, this.state.projectId ?? 0)
            ?
            <SubMenu>
              <LinkContainer to={Routes.buildCreateProjectApiKeyUrl(this.state.projectId ?? 0)}>
                <Nav.Link>Add key</Nav.Link>
              </LinkContainer>
            </SubMenu>
            : null
        }

        <VerticalSpace size={VerticalSpaceSize.small}/>

        <ContainerPageSize>
          <VerticalSpace size={VerticalSpaceSize.small}/>
          <DeleteApiKeyModal
            showDialog={this.state.isDeleteDialogOpen}
            apiKeyIdToDelete={this.state.apiKeyIdToDelete}
            accessKeyToDelete={this.state.accessKeyToDelete}
            onConfirm={this.onConfirmDeleteApiKeyClicked}
            onCancel={this.onCancelDeleteApiKeyClicked}
          />
          {
            this.state.isLoading ? <LoadingBar size={LoadingBarHeight.normal}/> :
              <>
                {
                  this.state.validationErrors != null
                    ?
                    <Row>
                      <Col md={{span: 6, offset: 3}}>
                        <ValidationSummary errors={this.state.validationErrors ?? {}}/>
                      </Col>
                    </Row>
                    :
                    <>
                      <h1 className={"text-break"}>{this.state.apiKeysForProject?.projectFullName}</h1>

                      {
                        this.state.apiKeysForProject?.apiKeys !== null && this.state.apiKeysForProject?.apiKeys.length === 0 &&
                        <div>
                            <VerticalSpace size={VerticalSpaceSize.small}/>
                            <Row>
                                <Col md={{span: "6", offset: "3"}}>
                                    <Alert variant="info" className={"text-center"}>
                                        No api keys found.
                                    </Alert>
                                </Col>
                            </Row>
                        </div>
                      }

                      {
                        this.state.apiKeysForProject?.apiKeys && this.state.apiKeysForProject?.apiKeys.length > 0 &&
                        <div>
                            <div>
                                The list below has all keys that can be used by third parties to connect to project API.
                            </div>

                            <VerticalSpace size={VerticalSpaceSize.small}/>

                            <Row>
                                <Col md={"12"}>
                                    <Alert variant="info" className={"text-center"}>
                                        Please notice that due to caching, any editing to API key may become available
                                        in a few minutes after saving until cache expires and new values become
                                        propagated.
                                    </Alert>
                                </Col>
                            </Row>

                            <div className="d-none d-lg-block">
                                <Row>
                                  {
                                    PermissionHelper.doesUserHavePermissionGloballyOrInProject(PermissionHelper.getAllPermissions().PRJ_API_MNG, this.state.projectId ?? 0)
                                      ?
                                      <Col lg={2}> </Col>
                                      : null
                                  }
                                    <Col lg={2}><b>Access key</b></Col>
                                    <Col lg={3}><b>Secret</b></Col>
                                    <Col lg={2}><b>Roles</b></Col>
                                    <Col lg={3}><b>Notes</b></Col>
                                </Row>
                                <hr className="my-1" style={{opacity: 1}}/>
                            </div>
                          {
                            this.state.apiKeysForProject?.apiKeys.map((apiKey) => {
                              return (
                                <ApiKeyLine key={apiKey.id} classNames={"my-3 text-break"}
                                            projectId={this.state.projectId ?? 0}
                                            apiKeyId={apiKey.id} access={apiKey.accessKey} secret={apiKey.secret}
                                            roles={apiKey.roles} notes={apiKey.notes} isActive={apiKey.isActive}
                                            onActivateApiKeyClick={(apiKeyId) => this.onActivateApiKeyClick(apiKeyId)}
                                            onDeactivateApiKeyClick={(apiKeyId) => this.onDeactivateApiKeyClick(apiKeyId)}
                                            onDeleteApiKeyClick={(apiKeyId) => this.onDeleteApiKeyClick(apiKeyId)}
                                />
                              );
                            })
                          }
                        </div>
                      }
                    </>
                }
              </>
          }
        </ContainerPageSize>
      </>
    );
  }

  async componentDidMount() {
    let projectId = parseInt(this.props.projectId as string);
    this.setState({projectId: projectId});

    let token = AuthHelper.getAccessToken() ?? '';

    try {
      let response = await Api.getApiKeysForConnectingToProject(token, projectId);

      this.setState({
        apiKeysForProject: response,
        isLoading: false
      });
    } catch (err) {
      this.setErrors(
        Validations.buildApiCommunicationErrors('Can\'t get api keys for connecting to project from the server', err)
      );
    }
  }

  private async onActivateApiKeyClick(apiKeyId: number) {
    let token = AuthHelper.getAccessToken() ?? '';
    let request: IActivateApiKeyIdRequest = {apiKeyId: apiKeyId};
    try {
      await Api.activateApiKey(token, request);

      let response = await Api.getApiKeysForConnectingToProject(token, this.state.projectId ?? 0);
      this.setState({apiKeysForProject: response});
    } catch (err) {
      this.setErrors(
        Validations.buildApiCommunicationErrors('Can\'t activate api key on the server', err)
      );
    }
  }

  private async onDeactivateApiKeyClick(apiKeyId: number) {
    let token = AuthHelper.getAccessToken() ?? '';
    let request: IDeactivateApiKeyIdRequest = {apiKeyId: apiKeyId};
    try {
      await Api.deactivateApiKey(token, request);

      let response = await Api.getApiKeysForConnectingToProject(token, this.state.projectId ?? 0);
      this.setState({apiKeysForProject: response});
    } catch (err) {
      this.setErrors(
        Validations.buildApiCommunicationErrors('Can\'t deactivate api key on the server', err)
      );
    }
  }

  private async onDeleteApiKeyClick(apiKeyId: number) {
    let state = {...this.state};
    state.apiKeyIdToDelete = apiKeyId;
    let matchingApiKeys = state.apiKeysForProject?.apiKeys.filter(x => x.id === apiKeyId);
    if (matchingApiKeys !== undefined && matchingApiKeys !== null && matchingApiKeys.length > 0) {
      let apiKey = matchingApiKeys[0];
      state.accessKeyToDelete = apiKey.accessKey;
      state.isDeleteDialogOpen = true;
      this.setState(state);
    }
  }

  private async onConfirmDeleteApiKeyClicked(apiKeyId: number) {
    let token = AuthHelper.getAccessToken() ?? '';

    try {
      await Api.deleteApiKey(token, apiKeyId);

      let response = await Api.getApiKeysForConnectingToProject(token, this.state.projectId ?? 0);
      this.setState({apiKeysForProject: response});
    } catch (err) {
      this.setErrors(Validations.buildApiCommunicationErrors('Can\'t delete api key on the server', err));
      this.closeRemoveLineDialog();
      return;
    }

    this.closeRemoveLineDialog();
  }

  private onCancelDeleteApiKeyClicked() {
    this.closeRemoveLineDialog();
  }

  private closeRemoveLineDialog() {
    let state = {...this.state};
    state.apiKeyIdToDelete = undefined;
    state.accessKeyToDelete = undefined;
    state.isDeleteDialogOpen = false;
    this.setState(state);
  }

  private setErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }
}

export default withEngineCompartmentAuthorize(ProjectKeysPage);