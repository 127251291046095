import React, { useState } from "react";
import { DashSquare, PlusSquare, Square } from "react-bootstrap-icons";
import Projects from "../projects/Projects";
import { IGroupItemProps } from "./IGroupItemProps";

const GroupItem = ({groupName, projects, children}: IGroupItemProps) => {
  const [isOpen, toggleItemOpen] = useState<boolean | null>(null);

  return (
    <div>
      <div className="text-break" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
        {children.length === 0 && projects.length === 0
          ?
          (<div className="p-1">
            <Square size={25}/>&nbsp;&nbsp;{groupName}
          </div>)
          :
          (<div className="p-1" style={{cursor: "pointer"}} onClick={() => toggleItemOpen(!isOpen)}>
            {isOpen ? <DashSquare size={25}/> : <PlusSquare size={25}/>}&nbsp;&nbsp;{groupName}
          </div>)
        }
      </div>

      <div style={{paddingLeft: "25px"}}>{isOpen && children}</div>
      {
        isOpen && (
          <div style={{paddingLeft: 60}}>
            <Projects projects={projects}/>
          </div>
        )
      }
    </div>
  );
};

export default GroupItem;