import React, { ChangeEvent, Component } from "react";
import { Alert, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Routes from "../../../../../data/Routes";
import Api from "../../../../../tools/api/api";
import ISaveApiKeyRequest from "../../../../../tools/api/api-interfaces/projects/api-keys/ISaveApiKeyRequest";
import AuthHelper from "../../../../../tools/auth/AuthHelper";
import ValidationErrors from "../../../../../tools/validation-helper/ValidationErrors";
import Validations from "../../../../../tools/validation-helper/Validations";
import withEngineCompartmentAuthorize from "../../../../common/authorize/withEngineCompartmentAuthorize";
import IconButton from "../../../../common/buttons/icon-button/IconButton";
import ApiKeysSubMenu from "../../../../common/layout-components/api-keys-sub-menu/ApiKeysSubMenu";
import ContainerPageSize from "../../../../common/layout-components/container-page-size/ContainerPageSize";
import { LoadingBarHeight } from "../../../../common/layout-components/loading-bar/ILoadingBarProps";
import LoadingBar from "../../../../common/layout-components/loading-bar/LoadingBar";
import ProjectSubMenu from "../../../../common/layout-components/project-sub-menu/ProjectSubMenu";
import { VerticalSpaceSize } from "../../../../common/layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../../common/layout-components/vertical-space/VerticalSpace";
import ValidationMessages from "../../../../common/validation-messages/ValidationMessages";
import ValidationSummary from "../../../../common/validation-summary/ValidationSummary";
import ICreateOrEditProjectApiKeyPageProps from "./ICreateOrEditProjectApiKeyPageProps";
import ICreateOrEditProjectApiKeyPageState from "./ICreateOrEditProjectApiKeyPageState";
import styles from './CreateOrEditApiKeyPage.module.scss';

class CreateOrEditApiKeyPage extends Component<ICreateOrEditProjectApiKeyPageProps, ICreateOrEditProjectApiKeyPageState> {
  constructor(props: ICreateOrEditProjectApiKeyPageProps) {
    super(props);

    this.state = {
      projectId: null,
      apiKeyId: null,
      projectFullName: "",
      accessKey: "",
      secret: "",
      roles: [],
      notes: "",
      redirect: null,
      validationErrors: {},
      isLoading: true,
      excludeKeys: ["AccessKey", "Secret", "Notes", "Roles"]
    };

    this.onAccessKeyChange = this.onAccessKeyChange.bind(this);
    this.onSecretChange = this.onSecretChange.bind(this);
    this.onNotesChange = this.onNotesChange.bind(this);
    this.onGenerateSecret = this.onGenerateSecret.bind(this);
    this.onAddRole = this.onAddRole.bind(this);
    this.onRemoveRole = this.onRemoveRole.bind(this);
    this.onSaveApiKey = this.onSaveApiKey.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  render() {
    if (this.state.redirect !== null) {
      return <Redirect to={this.state.redirect}/>;
    }

    return (
      <>
        <ProjectSubMenu projectId={this.state.projectId ?? 0}/>

        <ApiKeysSubMenu projectId={this.state.projectId ?? 0}/>

        <VerticalSpace size={VerticalSpaceSize.small}/>

        <ContainerPageSize>
          <VerticalSpace size={VerticalSpaceSize.small}/>
          {
            this.state.isLoading ? <LoadingBar size={LoadingBarHeight.normal}/> :
              <>
                <h1 className={"text-break"}>{this.state.projectFullName}</h1>
                <VerticalSpace size={VerticalSpaceSize.small}/>

                <Row>
                  <Col md={{span: 6, offset: 3}}>
                    <ValidationSummary errors={this.state.validationErrors} excludeKeys={this.state.excludeKeys}/>
                  </Col>
                </Row>
                <Form>
                  <Row>
                    <Col md={"6"}>
                      <Form.Label className={styles.label}>Access key</Form.Label>
                      <Form.Control
                        type="text"
                        autoFocus={true}
                        placeholder="Enter access key"
                        value={this.state.accessKey}
                        onChange={this.onAccessKeyChange}
                      />
                      <ValidationMessages fieldName="AccessKey" errors={this.state.validationErrors}/>
                    </Col>
                  </Row>
                  <VerticalSpace size={VerticalSpaceSize.small}/>
                  <Row>
                    <Col md={6}>
                      <Form.Label className={styles.label}>Secret</Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <IconButton onClick={this.onGenerateSecret} iconType={"repeat"}
                                      variant={"outline-primary"} toolTipTitle={"Generate random secret"}/>
                        </InputGroup.Prepend>
                        <Form.Control
                          type="text"
                          placeholder="Press button to generate secret"
                          value={this.state.secret}
                          onChange={this.onSecretChange}
                        />
                      </InputGroup>

                      <ValidationMessages fieldName="Secret" errors={this.state.validationErrors}/>
                    </Col>
                  </Row>
                  <VerticalSpace size={VerticalSpaceSize.small}/>
                  <Row>
                    <Col md={6}>
                      <Form.Label className={styles.label}>Notes</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter notes"
                        as={"textarea"}
                        value={this.state.notes}
                        onChange={this.onNotesChange}
                      />
                      <ValidationMessages fieldName="Notes" errors={this.state.validationErrors}/>
                    </Col>
                  </Row>
                  <VerticalSpace size={VerticalSpaceSize.small}/>
                  <Row>
                    <Col md={4}>
                      <Form.Label className={styles.label}>Roles</Form.Label>

                      <div>
                        <IconButton onClick={this.onAddRole} title={"Add new role"} variant={"outline-primary"}/>
                      </div>

                      {
                        this.state.roles.map((role, index) => {
                          return (
                            <div key={index} className={"my-3"}>
                              <InputGroup>
                                <InputGroup.Prepend>
                                  <IconButton onClick={() => this.onRemoveRole(index)}
                                              iconType={"remove"} variant={"outline-primary"}/>
                                </InputGroup.Prepend>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter role"
                                  value={role}
                                  onChange={(e: ChangeEvent<HTMLInputElement>) => this.onRoleChange(e, index)}
                                />
                                </InputGroup>
                              <ValidationMessages fieldName={"Roles_"+index} errors={this.state.validationErrors}/>
                            </div>
                          );
                        })
                      }

                      <ValidationMessages fieldName="PasswordConfirmation"
                                          errors={this.state.validationErrors ?? null}/>

                    </Col>
                  </Row>
                  <VerticalSpace size={VerticalSpaceSize.normal}/>
                  <IconButton onClick={this.onSaveApiKey} title="Save" variant={"outline-primary"}
                              styles={{marginRight: "1em"}}/>
                  <IconButton onClick={this.onCancel} title="Cancel" variant={"outline-primary"}/>
                </Form>
              </>
          }
        </ContainerPageSize>
      </>
    );
  }

  async componentDidMount() {
    let projectId = parseInt(this.props.projectId as string);
    this.setState({projectId: projectId});

    let apiKeyId: number | null; if (this.props.apiKeyId) {apiKeyId = +this.props.apiKeyId} else {apiKeyId = null}
    let token = AuthHelper.getAccessToken() ?? '';

    if (this.props.apiKeyId !== undefined) {
      try {
        let response = await Api.getApiKeyDetailsForSaving(token, +this.props.apiKeyId);

        let state = {...this.state};
        state.apiKeyId = apiKeyId;
        state.projectFullName = response.projectFullName;
        state.accessKey = response.accessKey;
        state.secret = response.secret;
        state.notes = response.notes ? response.notes : "";
        state.roles = response.roles;
        state.isLoading = false;

        if (response.roles.length > 0) {
          response.roles.forEach((x, index) => {
            state.excludeKeys.push(`Roles_${index}`)
          })
        }

        this.setState(state);
      } catch (err) {
        this.setErrors(
          Validations.buildApiCommunicationErrors('Can\'t get api key details for saving', err)
        );
      }
    } else {
      try {
        let response = await Api.getApiKeyDetailsForCreation(token, projectId);
        let state = {...this.state};
        state.projectId = projectId;
        state.apiKeyId = apiKeyId;
        state.projectFullName = response.projectFullName;
        state.roles.push("");
        state.excludeKeys.push(`Roles_${0}`);
        state.isLoading = false;
        this.setState(state);
      } catch (err) {
        this.setErrors(
          Validations.buildApiCommunicationErrors('Can\'t get api key details for creation', err)
        );
      }
    }
  }

  private onAccessKeyChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setAllValidationErrors({});

    this.setState({accessKey: e.target.value});
  }

  private onSecretChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setAllValidationErrors({});

    this.setState({secret: e.target.value});
  }

  private onNotesChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setAllValidationErrors({});

    this.setState({notes: e.target.value});
  }

  private async onGenerateSecret() {
    let token = AuthHelper.getAccessToken() ?? '';

    try {
      let response = await Api.generateSecret(token);

      this.setState({
        secret: response.secret
      });
    } catch (err) {
      this.setErrors(
        Validations.buildApiCommunicationErrors('Can\'t generate secret on the server', err)
      );
    }
  }

  private onAddRole() {
    let state = {...this.state};
    state.roles.push("");
    state.excludeKeys.push(`Roles_${(this.state.roles.length - 1)}`);
    this.setState(state);
  }

  private onRemoveRole(index: number) {
    let state = {...this.state};
    state.roles.splice(index, 1);

    let excludeKeys = state.excludeKeys;
    let indexOf = excludeKeys.indexOf(`Roles_${index}`);
    excludeKeys.splice(indexOf, 1);

    this.setState({roles: state.roles, excludeKeys: excludeKeys, validationErrors: {}});
  }

  private onRoleChange(e: ChangeEvent<HTMLInputElement>, index: number) {
    let role: string = e.target.value;
    let roles = [...this.state.roles];
    roles[index] = role;
    this.setState({roles, validationErrors: {}});
  }

  private async onSaveApiKey() {
    this.setState({isLoading: true});
    let token = AuthHelper.getAccessToken() ?? '';

    let request: ISaveApiKeyRequest = {
      projectId: this.state.projectId ?? 0,
      apiKeyId: this.state.apiKeyId,
      accessKey: this.state.accessKey,
      secret: this.state.secret,
      notes: this.state.notes,
      roles: this.state.roles
    };

    try {
      await Api.createOrUpdateApiKey(token, request);

      let state = {...this.state};
      state.redirect = Routes.buildProjectApiKeysUrl(this.state.projectId ?? 0);
      this.setState(state);
    } catch (err) {
      this.setErrors(Validations.buildApiCommunicationErrors('Can\'t create or update api key', err));
    }
  }

  private onCancel() {
    let state = {...this.state};
    state.redirect = Routes.buildProjectApiKeysUrl(this.state.projectId ?? 0);
    this.setState(state);
  }

  private setAllValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    this.setState(state);
  }

  private setErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }
}

export default withEngineCompartmentAuthorize(CreateOrEditApiKeyPage);