import React, { Component } from 'react';
import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { IHeaderProps } from "./IHeaderProps";
import withEngineCompartmentAuthorize from "../../authorize/withEngineCompartmentAuthorize";
import Routes from "../../../../data/Routes";
import AuthHelper from "../../../../tools/auth/AuthHelper";
import styles from "./Header.module.scss";
import PermissionHelper from "../../../../tools/permissions/PermissionHelper";

class Header extends Component<IHeaderProps> {
  render() {
    return (
      <div className={styles.topMenu}>
        <Navbar collapseOnSelect expand="lg" className={styles.headerContainer} variant="dark"
                style={{backgroundImage: `url('/img/blue-stripe-pattern.png')`}}>
          <LinkContainer to={Routes.projects}>
            <Navbar.Brand>
              <div>
              <span className={styles.kiwanisIconLogo}>
                <img
                  src={'/img/kiwanis-k.png'}
                  width="auto"
                  height="30"
                  alt="Kiwanis Logo"
                />
              </span>
                <span className={styles.kiwanisLogo}>
                <img
                  src={'/img/kiwanis-logo.png'}
                  width="auto"
                  height="30"
                  alt="Kiwanis Logo"
                />
              </span>
              </div>
            </Navbar.Brand>
          </LinkContainer>
          {this.props.auth.isAuthenticated
            ?
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            : null
          }
          {this.props.auth.isAuthenticated
            ?
            <>
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav activeKey={Routes.home}>
                  <LinkContainer to={Routes.projects}>
                    <Nav.Link><span className="navMainItemLink">Projects</span></Nav.Link>
                  </LinkContainer>

                  {PermissionHelper.doesUserHavePermissionGlobally(PermissionHelper.getAllPermissions().SYS_USR_MNG)
                    ?
                    <LinkContainer to={Routes.users}>
                      <Nav.Link><span className="navMainItemLink">Users</span></Nav.Link>
                    </LinkContainer>
                    : null
                  }

                  <LinkContainer to={Routes.systemHeartbeats}>
                    <Nav.Link><span className="navMainItemLink">System</span></Nav.Link>
                  </LinkContainer>

                </Nav>
              </Navbar.Collapse>
              <Navbar.Collapse className="justify-content-end">
                <Nav activeKey={Routes.home}>
                  <LinkContainer to={Routes.profile}>
                    <Nav.Link><span className="navMainItemLink">Me</span></Nav.Link>
                  </LinkContainer>

                  <LinkContainer onClick={this.signOut} to={Routes.login}>
                    <Nav.Link><span className="navMainItemLink">Sign out</span></Nav.Link>
                  </LinkContainer>
                </Nav>
              </Navbar.Collapse>
            </>
            : null
          }
        </Navbar>
      </div>
    );
  }

  private signOut() {
    AuthHelper.removeSession();
  }
}

export default withEngineCompartmentAuthorize(Header);
