import React from 'react';
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Routes from "../../../data/Routes";
import IconButton from "../../common/buttons/icon-button/IconButton";
import ContainerPageSize from "../../common/layout-components/container-page-size/ContainerPageSize";
import { VerticalSpaceSize } from "../../common/layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../common/layout-components/vertical-space/VerticalSpace";

class NotFoundPage extends React.Component {
  render() {
    return (
      <>
        <VerticalSpace size={VerticalSpaceSize.small}/>
        <ContainerPageSize>
          <div className="text-center">
            <Row>
              <Col md={{span: 6, offset: 3}} className={"marginTopAndBottom17vh"}>
                <h1>404 - Page Not Found!</h1>
                <br/>
                <Link to={Routes.projects}>
                  <IconButton onClick={() => function onBackToSite() {}} variant="outline-primary" title="Back to site"/>
                </Link>
              </Col>
            </Row>
          </div>
        </ContainerPageSize>
      </>
    );
  }
}

export default NotFoundPage;