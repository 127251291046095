import React, { Component } from 'react';
import { Col, Nav, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Api from "../../../../../tools/api/api";
import ICreateLineRequest from "../../../../../tools/api/api-interfaces/projects/project-info/ICreateLineRequest";
import IMoveLineRequest from "../../../../../tools/api/api-interfaces/projects/project-info/IMoveLineRequest";
import { IProjectInfoLine } from "../../../../../tools/api/api-interfaces/projects/project-info/IProjectInfoLine";
import IUpdateLineRequest from "../../../../../tools/api/api-interfaces/projects/project-info/IUpdateLineRequest";
import AuthHelper from "../../../../../tools/auth/AuthHelper";
import ValidationErrors from "../../../../../tools/validation-helper/ValidationErrors";
import Validations from "../../../../../tools/validation-helper/Validations";
import withEngineCompartmentAuthorize from "../../../../common/authorize/withEngineCompartmentAuthorize";
import ContainerPageSize from "../../../../common/layout-components/container-page-size/ContainerPageSize";
import { LoadingBarHeight } from "../../../../common/layout-components/loading-bar/ILoadingBarProps";
import LoadingBar from "../../../../common/layout-components/loading-bar/LoadingBar";
import ProjectSubMenu from "../../../../common/layout-components/project-sub-menu/ProjectSubMenu";
import SubMenu from "../../../../common/layout-components/sub-menu/SubMenu";
import { VerticalSpaceSize } from "../../../../common/layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../../common/layout-components/vertical-space/VerticalSpace";
import ValidationSummary from "../../../../common/validation-summary/ValidationSummary";
import DeleteConfirmationModal from "./delete-confirmation-modal/DeleteConfirmationModal";
import IProjectInfoEditPageProps from "./IProjectInfoEditPageProps";
import IProjectInfoEditPageState from "./IProjectInfoEditPageState";
import LineToEdit from "./line-to-edit/LineToEdit";

class ProjectInfoEditPage extends Component<IProjectInfoEditPageProps, IProjectInfoEditPageState> {

  constructor(props: IProjectInfoEditPageProps) {
    super(props);

    this.state = {
      currentProjectId: null,
      fullName: "",
      info: [],
      infoToRevert: null,
      isRemoveDialogOpen: false,
      lineIdToRemove: undefined,
      lineIndexToRemove: undefined,
      lineNameToRemove: undefined,
      isLoading: true,
      validationErrors: {}
    };

    this.onAddText = this.onAddText.bind(this);
    this.onAddUrl = this.onAddUrl.bind(this);
    this.onAddMultilineText = this.onAddMultilineText.bind(this);

    this.onConfirmRemoveLineClicked = this.onConfirmRemoveLineClicked.bind(this);
    this.onCancelRemoveLineClicked = this.onCancelRemoveLineClicked.bind(this);
  }

  render() {
    return (
      <>
        <ProjectSubMenu projectId={this.state.currentProjectId ?? 0}/>

        <SubMenu>
          <LinkContainer to={"#"} onClick={this.onAddText}>
            <Nav.Link>Add text</Nav.Link>
          </LinkContainer>

          <LinkContainer to={"#"} onClick={this.onAddUrl}>
            <Nav.Link>Add URL</Nav.Link>
          </LinkContainer>

          <LinkContainer to={"#"} onClick={this.onAddMultilineText}>
            <Nav.Link>Add multiline text</Nav.Link>
          </LinkContainer>
        </SubMenu>
        <VerticalSpace size={VerticalSpaceSize.small}/>

        <ContainerPageSize>
          <DeleteConfirmationModal
            showDialog={this.state.isRemoveDialogOpen}
            lineIdToRemove={this.state.lineIdToRemove}
            lineNameToRemove={this.state.lineNameToRemove}
            onConfirm={this.onConfirmRemoveLineClicked}
            onCancel={this.onCancelRemoveLineClicked}
          />
          <VerticalSpace size={VerticalSpaceSize.small}/>
          <Row>
            <Col md={{span: "8", offset: "2"}}>
              <ValidationSummary errors={this.state.validationErrors} excludeKeys={["Name", "Type",]}/>
            </Col>
          </Row>

          {
            this.state.isLoading ? <LoadingBar size={LoadingBarHeight.normal}/> :
              <>
                {
                  <>
                    <h1 className="text-break">{this.state.fullName}</h1>
                    <VerticalSpace size={VerticalSpaceSize.small}/>

                    {
                      this.state.info && this.state.info.length > 0 &&
                      <div className="d-none d-lg-block">
                          <Row>
                              <Col md={2}> </Col>
                              <Col md={3}><b>Name</b></Col>
                              <Col md={7}><b>Value</b></Col>
                          </Row>
                          <hr className="my-1" style={{opacity: 1}}/>
                      </div>
                    }

                    <div>
                      {
                        this.state.info?.map((info, index) => {
                          return (
                            <LineToEdit key={info.id} classNames={"my-3"}
                                        id={info.id} name={info.name} value={info.value} type={info.type}
                                        errors={info.errors}
                                        isNewLine={info.isNewLine}
                                        isMultiLineText={info.isMultiLineText}
                                        isEditMode={info.isEditMode}
                                        onLineUpClick={(lineId) => this.onLineUpClick(lineId)}
                                        onLineDownClick={(lineId) => this.onLineDownClick(lineId)}
                                        onLineEditClick={() => this.onLineEditClick(index, info.type)}
                                        onLineDeleteClick={(lineId) => this.onLineDeleteClick(lineId, index)}
                                        onLineSaveClick={(lineId) => this.onLineSaveClick(lineId)}
                                        onLineRevertClick={(lineId) => this.onLineRevertClick(lineId, index)}
                                        onLineNameChange={(name: string) => this.onLineNameChange(index, name)}
                                        onLineValueChange={(value: string) => this.onLineValueChange(index, value)}
                            />
                          );
                        })
                      }
                    </div>
                  </>
                }
              </>
          }
        </ContainerPageSize>
      </>
    );
  }

  async componentDidMount() {
    let projectId = parseInt(this.props.projectId as string);
    this.setState({currentProjectId: projectId});

    await this.getProjectInfo(projectId);
  }

  private async getProjectInfo(projectId: number) {
    let token = AuthHelper.getAccessToken() ?? '';

    try {
      let response = await Api.getProjectInfo(token, projectId);

      this.setState({
        info: response.info,
        infoToRevert: response.info,
        fullName: response.fullName,
        isLoading: false
      });
    } catch (err) {
      this.setErrors(
        Validations.buildApiCommunicationErrors('Can\'t get project info from the server', err)
      );
    }
  }

  private onAddText() {
    let state = {...this.state};
    state.info.push({
      id: -Math.floor(Math.random() * 10000),
      name: "",
      value: "",
      type: "LINE",
      isNewLine: true,
      isMultiLineText: false,
      isEditMode: false,
      errors: {}
    });

    this.setState(state);
  }

  private onAddUrl() {
    let state = {...this.state};
    state.info.push({
      id: -Math.floor(Math.random() * 10000),
      name: "",
      value: "",
      type: "URL",
      isNewLine: true,
      isMultiLineText: false,
      isEditMode: false,
      errors: {}
    });

    this.setState(state);
  }

  private onAddMultilineText() {
    let state = {...this.state};
    state.info.push({
      id: -Math.floor(Math.random() * 10000),
      name: "",
      value: "",
      type: "MULTI_LINE",
      isNewLine: true,
      isMultiLineText: true,
      isEditMode: false,
      errors: {}
    });

    this.setState(state);
  }

  private async onLineUpClick(lineId: number) {
    let token = AuthHelper.getAccessToken() ?? '';
    let request: IMoveLineRequest = {id: lineId};

    try {
      let response = await Api.moveLineUp(token, request);

      let updatedLines = this.updateLinesFromServer(response.info, lineId);

      this.setState({info: updatedLines});
    } catch (err) {
      this.setErrors(Validations.buildApiCommunicationErrors('Can\'t move up project info line', err));
    }
  }

  private async onLineDownClick(lineId: number) {
    let token = AuthHelper.getAccessToken() ?? '';
    let request: IMoveLineRequest = {id: lineId};

    try {
      let response = await Api.moveLineDown(token, request);

      let updatedLines = this.updateLinesFromServer(response.info, lineId);

      this.setState({info: updatedLines});
    } catch (err) {
      this.setErrors(Validations.buildApiCommunicationErrors('Can\'t move down project info line', err));
    }
  }

  private async onLineEditClick(index: number, type: string) {
    let info = this.state.info;
    info[index] = {...info[index], isEditMode: true, isMultiLineText: type === "MULTI_LINE"};
    this.setState({info});
  }

  private async onLineRevertClick(lineId: number, index: number) {
    let state = {...this.state};
    let info = [...this.state.info];

    let matchingLines = state.infoToRevert?.filter(x => x.id === lineId);
    if (matchingLines !== undefined && matchingLines !== null && matchingLines.length > 0) {
      let line = matchingLines[0];

      info[index] = {...info[index], isEditMode: false, name: line.name, value: line.value};
      this.setState({info: info});
    }
  }

  private onLineDeleteClick(lineId: number, index: number) {
    let state = {...this.state};
    let lineForDelete = state.info?.filter(x => x.id === lineId);
    if (lineForDelete !== undefined && lineForDelete !== null && lineForDelete.length > 0) {
      if (lineId > 0) {
        let line = lineForDelete[0];
        state.lineIdToRemove = lineId;
        state.lineIndexToRemove = index;
        state.lineNameToRemove = line.name;
        state.isRemoveDialogOpen = true;
        this.setState(state);
      } else {
        if (lineForDelete[0].isNewLine) {
          let indexToDelete = index;
          if (indexToDelete !== undefined) {
            let info = [...this.state.info];
            info.splice(indexToDelete, 1);
            this.setState({info: info});
          }
        }
      }
    }
  }

  private async onConfirmRemoveLineClicked(lineId: number) {
    let lineForDelete = this.state.info?.filter(x => x.id === lineId);
    if (lineForDelete !== undefined && lineForDelete !== null && lineForDelete.length > 0) {
      if (!lineForDelete[0].isNewLine) {
        let token = AuthHelper.getAccessToken() ?? '';

        try {
          let response = await Api.deleteLine(token, lineId);

          let updatedLines = this.updateLinesFromServer(response.info, lineId);

          this.setState({info: updatedLines});
        } catch (err) {
          this.setErrors(Validations.buildApiCommunicationErrors('Can\'t delete project info line', err));

          this.closeRemoveLineDialog();
          return;
        }
      }
    }
    this.closeRemoveLineDialog();
  }

  private async onLineSaveClick(lineId: number) {
    let state = {...this.state};
    let matchingLines = state.info?.filter(x => x.id === lineId);
    if (matchingLines !== undefined && matchingLines !== null && matchingLines.length > 0) {
      let line = matchingLines[0];
      let token = AuthHelper.getAccessToken() ?? '';

      if (line.isEditMode) {
        let request: IUpdateLineRequest = {
          id: lineId,
          name: line.name,
          type: line.type,
          value: line.value
        };

        try {
          let response = await Api.updateLine(token, request);

          const updatedLines = this.updateLinesFromServer(response.info, lineId, true);

          this.setState({info: updatedLines, infoToRevert: response.info});
        } catch (err) {
          this.setErrors(Validations.buildApiCommunicationErrors('Can\'t edit project info line', err));
          this.setLineErrors(lineId);
        }
      } else {
        let request: ICreateLineRequest = {
          projectId: this.state.currentProjectId ?? 0,
          type: line.type,
          name: line.name,
          value: line.value
        };

        try {
          let response = await Api.createLine(token, request);

          const updatedLines = this.updateLinesFromServer(response.info, lineId);

          this.setState({info: updatedLines, infoToRevert: response.info});
        } catch (err) {
          this.setErrors(Validations.buildApiCommunicationErrors('Can\'t create project info line', err));
          this.setLineErrors(lineId);
        }
      }
    }
  }

  private setLineErrors(lineId: number) {
    const infos = [...this.state.info];

    infos.map(x => {
      if (x.id === lineId) {
        return x.errors = this.state.validationErrors;
      } else {
        return x;
      }
    });

    this.setState({info: infos});
  }

  private updateLinesFromServer(response: IProjectInfoLine[], lineId: number, disableEditMode?: boolean) {
    let state = {...this.state};
    let newProjectInfoLines: IProjectInfoLine[] = [];

    response.forEach(line => {
      let matchingLine = state.info?.filter(x => x.id === line.id);
      let isEditMode = matchingLine[0]?.isEditMode !== undefined;

      if (matchingLine && !isEditMode) {
        newProjectInfoLines.push(line);
      }

      if (matchingLine && isEditMode) {
        if (disableEditMode && matchingLine[0].id === lineId) {
          matchingLine[0].isEditMode = false;
        }

        newProjectInfoLines.push(matchingLine[0]);
      }

      if (!matchingLine) {
        newProjectInfoLines.push(line);
      }
    });

    state.info.forEach(line => {
      if (line.isNewLine) {
        newProjectInfoLines.push(line);
      }
    });

    if (lineId < 0) {
      return newProjectInfoLines.filter(x => x.id !== lineId);
    }

    return newProjectInfoLines;
  }

  private onLineNameChange(index: number, name: string) {
    let info = [...this.state.info];
    info[index] = {...info[index], name: name, errors: {}};
    this.setState({info});
  }

  private onLineValueChange(index: number, value: string) {
    let info = [...this.state.info];
    info[index] = {...info[index], value: value};
    this.setState({info});
  }

  private onCancelRemoveLineClicked() {
    this.closeRemoveLineDialog();
  }

  private closeRemoveLineDialog() {
    let state = {...this.state};
    state.lineIdToRemove = undefined;
    state.lineNameToRemove = undefined;
    state.isRemoveDialogOpen = false;
    this.setState(state);
  }

  private setErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }
}

export default withEngineCompartmentAuthorize(ProjectInfoEditPage);