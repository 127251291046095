import React, { Component } from "react";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { IProjectGroupItemProps } from "./IProjectGroupItemProps";

class ProjectGroupItem extends Component<IProjectGroupItemProps> {
  render() {
    return (
      <div>
        <div className="text-break" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
          {this.props.children.length === 0
            ?
            <LinkContainer to={"#"} onClick={() => this.props.onMoveGroupClick(this.props.groupId)}>
              <Nav.Link>{this.props.groupName}</Nav.Link>
            </LinkContainer>
            :
            <LinkContainer to={"#"} onClick={() => this.props.onMoveGroupClick(this.props.groupId)}>
              <Nav.Link>{this.props.groupName}</Nav.Link>
            </LinkContainer>

          }
        </div>

        <div style={{paddingLeft: "25px"}}>{this.props.children}</div>
      </div>
    );
  }
}

export default ProjectGroupItem;