import React, { ChangeEvent, Component } from "react";
import { Alert, Col, Form, InputGroup, Nav, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Redirect } from "react-router-dom";
import Routes from "../../../../data/Routes";
import Api from "../../../../tools/api/api";
import { ICreateUserGroupRequest } from "../../../../tools/api/api-interfaces/system/security/ICreateUserGroupRequest";
import { IUpdateUserGroupRequest } from "../../../../tools/api/api-interfaces/system/security/IUpdateUserGroupRequest";
import { IUserGroupInfo } from "../../../../tools/api/api-interfaces/system/security/IUserGroupInfo";
import AuthHelper from "../../../../tools/auth/AuthHelper";
import ValidationErrors from "../../../../tools/validation-helper/ValidationErrors";
import Validations from "../../../../tools/validation-helper/Validations";
import withEngineCompartmentAuthorize from "../../../common/authorize/withEngineCompartmentAuthorize";
import IconButton from "../../../common/buttons/icon-button/IconButton";
import ContainerPageSize from "../../../common/layout-components/container-page-size/ContainerPageSize";
import { LoadingBarHeight } from "../../../common/layout-components/loading-bar/ILoadingBarProps";
import LoadingBar from "../../../common/layout-components/loading-bar/LoadingBar";
import SubMenu from "../../../common/layout-components/sub-menu/SubMenu";
import SystemSubMenu from "../../../common/layout-components/system-sub-menu/SystemSubMenu";
import { VerticalSpaceSize } from "../../../common/layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../common/layout-components/vertical-space/VerticalSpace";
import ValidationMessages from "../../../common/validation-messages/ValidationMessages";
import ValidationSummary from "../../../common/validation-summary/ValidationSummary";
import DeleteSecurityGroupModal from "./delete-security-group-modal/DeleteSecurityGroupModal";
import { ISecurityPageProps } from "./ISecurityPageProps";
import { ISecurityPageState } from "./ISecurityPageState";
import styles from "./SecurityPage.module.scss";

class SecurityPage extends Component<ISecurityPageProps, ISecurityPageState> {

  constructor(props: ISecurityPageProps) {
    super(props);

    this.state = {
      userGroups: [],
      userGroupsToRevert: [],
      isDeleteDialogOpen: false,
      groupIdToDelete: undefined,
      groupIndexToDelete: undefined,
      groupNameToDelete: undefined,
      validationErrors: {},
      isLoading: true,
      redirect: null
    };

    this.onAddGroupClick = this.onAddGroupClick.bind(this);
    this.onGroupSaveClick = this.onGroupSaveClick.bind(this);
    this.onConfirmDeleteUserGroupClick = this.onConfirmDeleteUserGroupClick.bind(this);
    this.onCancelDeleteSecurityGroupClick = this.onCancelDeleteSecurityGroupClick.bind(this);
    this.onGroupEditClick = this.onGroupEditClick.bind(this);
    this.onGroupNameChange = this.onGroupNameChange.bind(this);
    this.onGroupRevertClick = this.onGroupRevertClick.bind(this);
    this.onSetupGroupClick = this.onSetupGroupClick.bind(this);
  }

  render() {
    const Title1 = "Group name";

    if (this.state.redirect !== null) {
      return <Redirect to={this.state.redirect}/>;
    }

    return (
      <>
        {
          this.state.isLoading ? <LoadingBar size={LoadingBarHeight.normal}/> :
            <>
              <SystemSubMenu/>

              <SubMenu>
                <LinkContainer to={"#"} onClick={this.onAddGroupClick}>
                  <Nav.Link>Create group</Nav.Link>
                </LinkContainer>
              </SubMenu>
              <VerticalSpace size={VerticalSpaceSize.small}/>

              <ContainerPageSize>
                <DeleteSecurityGroupModal
                  showDialog={this.state.isDeleteDialogOpen}
                  groupIdToDelete={this.state.groupIdToDelete}
                  groupNameToDelete={this.state.groupNameToDelete}
                  onConfirm={(groupId: number) => this.onConfirmDeleteUserGroupClick(groupId)}
                  onCancel={this.onCancelDeleteSecurityGroupClick}
                />

                <>
                  <VerticalSpace size={VerticalSpaceSize.small}/>
                  <h1>Engine compartment groups</h1>
                  <VerticalSpace size={VerticalSpaceSize.small}/>

                  <Row>
                    <Col md={{span: 6, offset: 3}}>
                      <ValidationSummary errors={this.state.validationErrors ?? {}} excludeKeys={["NewName", "Name"]}/>
                    </Col>
                  </Row>

                  {
                    this.state.userGroups && this.state.userGroups.length === 0 &&
                    <div>
                        <Row>
                            <Col md={{span: "6", offset: "3"}}>
                                <Alert variant="info" className={"text-center"}>
                                    No user groups.
                                </Alert>
                            </Col>
                        </Row>
                    </div>
                  }

                  <div>
                    <VerticalSpace size={VerticalSpaceSize.small}/>

                    {
                      this.state.userGroups && this.state.userGroups.length > 0 &&
                      <>
                          <div className="d-none d-lg-block">
                              <Row>
                                  <Col lg={3} style={{maxWidth: "11rem"}}> </Col>
                                  <Col lg={9}><b>{Title1}</b></Col>
                              </Row>
                              <hr className="my-2" style={{opacity: 1}}/>
                          </div>

                        {
                          this.state.userGroups.map((group, index) => {
                            return (
                              <div key={index}>
                                {
                                  group.isNewGroup || group.isEditMode
                                    ?
                                    <Row className="align-items-center">
                                      <Col lg={3} style={{maxWidth: "11rem"}} className="d-flex">
                                        <Form.Label> </Form.Label>
                                        <div className="mb-lg-0 mb-2">
                                          <IconButton onClick={() => this.onGroupSaveClick(group.id)}
                                                      iconType={"submit"} toolTipTitle={"Save"}
                                                      variant="outline-primary" styles={{margin: "0.15em"}}/>

                                          {
                                            group.isEditMode
                                              ?
                                              <IconButton onClick={() => this.onGroupRevertClick(group.id, index)}
                                                          iconType={"revert"} toolTipTitle={"Revert"}
                                                          variant="outline-primary" styles={{margin: "0.15em"}}/>
                                              :
                                              <IconButton onClick={() => this.onDeleteUserGroupClick(group.id, index)}
                                                          iconType={"revert"} toolTipTitle={"Revert"}
                                                          variant={"outline-primary"} styles={{margin: "0.15em"}}/>
                                          }

                                        </div>
                                      </Col>

                                      <Col lg={6} className="mt-2 mb-3">
                                        <div className="d-block d-lg-none">
                                          <Form.Label className={styles.label}>Group name</Form.Label>
                                        </div>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            autoFocus={true}
                                            placeholder="Enter group name"
                                            maxLength={200}
                                            value={group.name}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                              this.onGroupNameChange(e, index)}
                                          />
                                        </InputGroup>

                                        <ValidationMessages fieldName="Name" errors={group.errors}/>
                                        <ValidationMessages fieldName="NewName" errors={group.errors}/>
                                      </Col>
                                    </Row>
                                    :
                                    <Row className="align-items-center">
                                      <Col lg={3} style={{maxWidth: "11rem"}}>
                                        <div className="mb-lg-0 my-1">
                                          <IconButton onClick={() => this.onGroupEditClick(index)}
                                                      iconType={"edit"}
                                                      variant={"outline-primary"}
                                                      styles={{margin: "0.15em"}}
                                                      disabled={group.name === "Super administrators"}
                                                      toolTipTitle={"Rename"}
                                          />


                                          <IconButton onClick={() => this.onSetupGroupClick(group.id)}
                                                      iconType={"setup"}
                                                      variant={"outline-primary"}
                                                      styles={{margin: "0.15em"}}
                                                      toolTipTitle={"Details"}
                                          />

                                          <IconButton onClick={() => this.onDeleteUserGroupClick(group.id, index)}
                                                      iconType={"remove"}
                                                      variant={"outline-primary"}
                                                      styles={{margin: "0.15em"}}
                                                      disabled={group.name === "Super administrators"}
                                                      toolTipTitle={"Delete"}
                                          />

                                        </div>
                                      </Col>

                                      <Col lg={9}>
                                        <span className="d-inline-block d-lg-none text-break my-2"><b>Group name: </b>
                                          <span>
                                            {group.name}
                                          </span> <br/>
                                        </span>
                                        <span className="d-none d-lg-block text-break">
                                          <span>
                                            {group.name}
                                          </span>
                                        </span>
                                      </Col>
                                    </Row>
                                }
                                <hr className={"my-2"}/>
                              </div>
                            );
                          })
                        }
                      </>
                    }
                  </div>
                </>

              </ContainerPageSize>
            </>
        }
      </>
    );
  }

  async componentDidMount() {
    let token = AuthHelper.getAccessToken() ?? '';

    try {
      const response = await Api.getUserGroups(token);

      let state = {...this.state};
      state.isLoading = false;
      state.userGroups = response.groups;
      state.userGroupsToRevert = response.groups;
      this.setState(state);
    } catch (e) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get user groups from the server', e)
      );
    }
  }

  private onAddGroupClick() {
    let userGroups = [...this.state.userGroups];
    userGroups.push({
      id: -Math.floor(Math.random() * 10000),
      name: "",
      isNewGroup: true,
      isEditMode: false,
      errors: {}
    });

    this.setState({userGroups: userGroups});
  }

  private onGroupEditClick(index: number) {
    let userGroups = this.state.userGroups;
    if (userGroups) {
      userGroups[index] = {...userGroups[index], isEditMode: true};
      this.setState({userGroups: userGroups});
    }
  }

  private onGroupNameChange(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    let userGroups = [...this.state.userGroups];
    userGroups[index] = {...userGroups[index], name: event.target.value, errors: {}};
    this.setState({userGroups: userGroups});
  }

  private onGroupRevertClick(groupId: number, index: number) {
    let state = {...this.state};
    let userGroups = [...this.state.userGroups];

    let matchingGroups = state.userGroupsToRevert.filter(x => x.id === groupId);
    if (matchingGroups !== undefined && matchingGroups !== null && matchingGroups.length > 0) {
      let group = matchingGroups[0];

      userGroups[index] = {
        ...userGroups[index],
        isEditMode: false,
        name: group.name,
        errors: {}
      };

      this.setState({userGroups: userGroups});
    }
  }

  private async onGroupSaveClick(groupId: number) {
    let state = {...this.state};
    let matchingGroups = state.userGroups.filter(x => x.id === groupId);
    if (matchingGroups !== undefined && matchingGroups !== null && matchingGroups.length > 0) {
      let group = matchingGroups[0];
      let token = AuthHelper.getAccessToken() ?? '';

      if (group.isEditMode) {
        let request: IUpdateUserGroupRequest = {
          newName: group.name
        };

        try {
          let response = await Api.updateUserGroup(token, request, groupId);

          const updatedUserGroups = this.updateUserGroupsFromServer(response, groupId, true);
          this.setState({userGroups: updatedUserGroups, userGroupsToRevert: response});
        } catch (err) {
          this.setValidationErrors(
            Validations.buildApiCommunicationErrors('Can\'t update user group on the server', err)
          );
          this.setUserGroupErrors(groupId);
        }
      } else {
        let request: ICreateUserGroupRequest = {
          name: group.name
        };

        try {
          let response = await Api.createUserGroup(token, request);

          const updatedUserGroups = this.updateUserGroupsFromServer(response, groupId);

          this.setState({userGroups: updatedUserGroups, userGroupsToRevert: response});
        } catch (err) {
          this.setValidationErrors(
            Validations.buildApiCommunicationErrors('Can\'t create user group on the server', err)
          );
          this.setUserGroupErrors(groupId);
        }
      }
    }
  }

  private setUserGroupErrors(groupId: number) {
    const userGroups = [...this.state.userGroups];

    userGroups.map(x => {
      if (x.id === groupId) {
        return x.errors = this.state.validationErrors;
      } else {
        return x;
      }
    });

    this.setState({userGroups: userGroups});
  }

  private onSetupGroupClick(groupId: number) {
    this.setState({redirect: Routes.buildSetupSecurityGroupUrl(groupId)});
  }

  private onDeleteUserGroupClick(groupId: number, index: number) {
    let state = {...this.state};
    let userGroup = state.userGroups?.filter(x => x.id === groupId);
    if (userGroup !== undefined && userGroup !== null && userGroup.length > 0) {
      if (groupId > 0) {
        let group = userGroup[0];
        state.groupIdToDelete = groupId;
        state.groupNameToDelete = group.name;
        state.groupIndexToDelete = index;
        state.isDeleteDialogOpen = true;
        this.setState(state);
      } else {
        if (userGroup[0].isNewGroup) {
          let indexToDelete = index;
          if (indexToDelete !== undefined) {
            let userGroups = [...this.state.userGroups];
            userGroups.splice(indexToDelete, 1);
            this.setState({userGroups: userGroups});
          }
        }
      }
    }
  }

  private async onConfirmDeleteUserGroupClick(groupId: number) {
    let userGroup = this.state.userGroups.filter(x => x.id === groupId);
    if (userGroup !== undefined && userGroup !== null && userGroup.length > 0) {
      if (!userGroup[0].isNewGroup) {
        let token = AuthHelper.getAccessToken() ?? '';

        try {
          let response = await Api.deleteUserGroup(token, groupId);
          let updatedUserGroups = this.updateUserGroupsFromServer(response, groupId);

          this.setState({userGroups: updatedUserGroups, userGroupsToRevert: response});
        } catch (err) {
          this.setValidationErrors(Validations.buildApiCommunicationErrors('Can\'t delete user group', err));

          this.closeDeleteUserGroupDialog();
          return;
        }
      }
    }
    this.closeDeleteUserGroupDialog();
  }

  private onCancelDeleteSecurityGroupClick() {
    this.closeDeleteUserGroupDialog();
  }

  private closeDeleteUserGroupDialog() {
    let state = {...this.state};
    state.groupIdToDelete = undefined;
    state.groupNameToDelete = undefined;
    state.isDeleteDialogOpen = false;
    this.setState(state);
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }

  private updateUserGroupsFromServer(response: IUserGroupInfo[], groupId: number, disableEditMode?: boolean) {
    let state = {...this.state};
    let newUserGroups: IUserGroupInfo[] = [];

    response.forEach(group => {
      let matchingGroups = state.userGroups?.filter(x => x.id === group.id);
      let isEditMode = matchingGroups[0]?.isEditMode !== undefined;

      if (matchingGroups && !isEditMode) {
        newUserGroups.push(group);
      }

      if (matchingGroups && isEditMode) {
        if (disableEditMode && matchingGroups[0].id === groupId) {
          matchingGroups[0].isEditMode = false;
        }

        newUserGroups.push(matchingGroups[0]);
      }

      if (!matchingGroups) {
        newUserGroups.push(group);
      }
    });

    state.userGroups.forEach(group => {
      if (group.isNewGroup) {
        newUserGroups.push(group);
      }
    });

    if (groupId < 0) {
      return newUserGroups.filter(x => x.id !== groupId);
    }

    return newUserGroups;
  }
}

export default withEngineCompartmentAuthorize(SecurityPage);