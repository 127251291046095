import React, { ChangeEvent, Component } from "react";
import { Alert, Col, Form, InputGroup, Row } from "react-bootstrap";
import Api from "../../../../tools/api/api";
import { ISaveSystemSettingRequest } from "../../../../tools/api/api-interfaces/system/ISaveSystemSettingRequest";
import { ISystemSettingInfoResponse } from "../../../../tools/api/api-interfaces/system/ISystemSettingInfoResponse";
import AuthHelper from "../../../../tools/auth/AuthHelper";
import ValidationErrors from "../../../../tools/validation-helper/ValidationErrors";
import Validations from "../../../../tools/validation-helper/Validations";
import withEngineCompartmentAuthorize from "../../../common/authorize/withEngineCompartmentAuthorize";
import IconButton from "../../../common/buttons/icon-button/IconButton";
import ContainerPageSize from "../../../common/layout-components/container-page-size/ContainerPageSize";
import { LoadingBarHeight } from "../../../common/layout-components/loading-bar/ILoadingBarProps";
import LoadingBar from "../../../common/layout-components/loading-bar/LoadingBar";
import SystemSubMenu from "../../../common/layout-components/system-sub-menu/SystemSubMenu";
import { VerticalSpaceSize } from "../../../common/layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../common/layout-components/vertical-space/VerticalSpace";
import ValidationMessages from "../../../common/validation-messages/ValidationMessages";
import ValidationSummary from "../../../common/validation-summary/ValidationSummary";
import { ISystemSettingsPageProps } from "./ISystemSettingsPageProps";
import { ISystemSettingsPageState } from "./ISystemSettingsPageState";
import styles from "./SystemSettingsPage.module.scss";

class SystemSettingsPage extends Component<ISystemSettingsPageProps, ISystemSettingsPageState> {

  constructor(props: ISystemSettingsPageProps) {
    super(props);

    this.state = {
      systemSettings: [],
      systemSettingsToRevert: [],
      validationErrors: {},
      isLoading: true
    };

    this.onSettingsEdit = this.onSettingsEdit.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onSettingsSave = this.onSettingsSave.bind(this);
    this.onSettingsRevert = this.onSettingsRevert.bind(this);
  }

  render() {
    const Title1 = "Code";
    const Title2 = "Value";
    const Title3 = "Description";

    return (
      <>
        {
          this.state.isLoading ? <LoadingBar size={LoadingBarHeight.normal}/> :
            <>
              <SystemSubMenu/>

              <VerticalSpace size={VerticalSpaceSize.small}/>

              <ContainerPageSize>
                <>
                  <VerticalSpace size={VerticalSpaceSize.small}/>

                  <h1>Engine compartment settings</h1>

                  <Row>
                    <Col md={{span: 6, offset: 3}}>
                      <ValidationSummary errors={this.state.validationErrors ?? {}} excludeKeys={["NumberValue", "StringValue"]}/>
                    </Col>
                  </Row>

                  {
                    this.state.systemSettings && this.state.systemSettings.length === 0 &&
                    <div>
                        <VerticalSpace size={VerticalSpaceSize.small}/>
                        <Row>
                            <Col md={{span: "6", offset: "3"}}>
                                <Alert variant="info" className={"text-center"}>
                                    No system settings.
                                </Alert>
                            </Col>
                        </Row>
                    </div>
                  }

                  <div>
                    <VerticalSpace size={VerticalSpaceSize.small}/>

                    {
                      this.state.systemSettings && this.state.systemSettings.length > 0 &&
                      <>
                          <div className="d-none d-lg-block">
                              <Row>
                                  <Col lg={2} style={{maxWidth: "8rem"}}> </Col>
                                  <Col lg={3}><b>{Title1}</b></Col>
                                  <Col lg={3}><b>{Title2}</b></Col>
                                  <Col lg={4}><b>{Title3}</b></Col>
                              </Row>
                              <hr className="my-2" style={{opacity: 1}}/>
                          </div>

                        {
                          this.state.systemSettings.map((setting, index) => {
                            return (
                              <div key={index}>
                                {
                                  setting.isEditMode
                                    ?
                                    <Row className="align-items-center">
                                      <Col lg={2} style={{maxWidth: "8rem"}} className="d-flex">
                                        <Form.Label> </Form.Label>
                                        <div>
                                          <IconButton onClick={() => this.onSettingsSave(setting.code)}
                                                      iconType={"submit"} variant="outline-primary"
                                                      styles={{margin: "0.15em"}}/>

                                          <IconButton onClick={() => this.onSettingsRevert(setting.code, index)}
                                                      iconType={"revert"} variant="outline-primary"
                                                      styles={{margin: "0.15em"}}/>
                                        </div>
                                      </Col>

                                      <Col lg={3}>
                                        <span className="d-inline-block d-lg-none"><b>{Title1}: </b>
                                          <span>{setting.code}</span> <br/>
                                        </span>
                                        <span className="d-none d-lg-block text-break">
                                          <span>{setting.code}</span>
                                        </span>
                                      </Col>

                                      <Col lg={3} className="mt-2 mb-3">
                                        <div className="d-block d-lg-none">
                                          <Form.Label className={styles.label}>Value</Form.Label>
                                        </div>
                                        <InputGroup>
                                        <Form.Control type={setting.valueType === "Number" ? "number" : "text"}
                                          placeholder="Enter value"  autoFocus={true}
                                          value={setting.valueType === "Number" ? setting.numberValue : setting.stringValue}
                                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                            this.onValueChange(e, setting.valueType, index)}
                                        />
                                        </InputGroup>
                                        <ValidationMessages fieldName="NumberValue" errors={setting.errors}/>
                                        <ValidationMessages fieldName="StringValue" errors={setting.errors}/>
                                      </Col>

                                      <Col lg={4}>
                                        <span className="d-inline-block d-lg-none text-break"><b>{Title3}: </b>
                                            <span>
                                              {setting.description}
                                            </span> <br/>
                                        </span>
                                        <span className="d-none d-lg-block text-break">
                                            <span>
                                            {setting.description}
                                            </span>
                                        </span>
                                      </Col>
                                    </Row>
                                    :
                                    <Row className="align-items-center">
                                      <Col lg={2} style={{maxWidth: "8rem"}}>
                                        <div className="mb-lg-0 mb-2">
                                          <IconButton onClick={() => this.onSettingsEdit(index)} iconType={"edit"}
                                                      variant={"outline-primary"} styles={{margin: "0.15em"}}/>
                                        </div>
                                      </Col>

                                      <Col lg={3} className="text-break">
                                        <span className="d-inline-block d-lg-none"><b>{Title1}: </b>
                                            <span>
                                              {setting.code}
                                            </span> <br/>
                                        </span>
                                        <span className="d-none d-lg-block text-break">
                                            <span>
                                              {setting.code}
                                            </span>
                                        </span>
                                      </Col>

                                      <Col lg={3} className="text-break">
                                        <span className="d-inline-block d-lg-none"><b>{Title2}: </b>
                                            <span>
                                              {setting.valueType === "String" ? setting.stringValue : setting.numberValue}
                                            </span> <br/>
                                        </span>
                                        <span className="d-none d-lg-block text-break">
                                            <span>
                                              {setting.valueType === "String" ? setting.stringValue : setting.numberValue}
                                            </span>
                                        </span>
                                      </Col>

                                      <Col lg={4}>
                                        <span className="d-inline-block d-lg-none text-break"><b>{Title3}: </b>
                                            <span>
                                              {setting.description}
                                            </span> <br/>
                                        </span>
                                        <span className="d-none d-lg-block text-break">
                                            <span>
                                            {setting.description}
                                            </span>
                                        </span>
                                      </Col>
                                    </Row>
                                }
                                <hr className={"my-2"}/>
                              </div>
                            );
                          })
                        }
                      </>
                    }
                  </div>
                </>
              </ContainerPageSize>
            </>
        }
      </>
    );
  }

  async componentDidMount() {
    let token = AuthHelper.getAccessToken() ?? '';

    try {
      const response = await Api.getSystemSettings(token);

      let state = {...this.state};
      state.systemSettings = response;
      state.systemSettingsToRevert = response;
      state.isLoading = false;
      this.setState(state);
    } catch (e) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get system settings from the server', e)
      );
    }
  }

  private onSettingsEdit(index: number) {
    let systemSettings = this.state.systemSettings;
    if (systemSettings) {
      systemSettings[index] = {...systemSettings[index], isEditMode: true};
      this.setState({systemSettings: systemSettings});
    }
  }

  private onValueChange(event: React.ChangeEvent<HTMLInputElement>, valueType: string, index: number) {
    let systemSettings = [...this.state.systemSettings];

    if (valueType === "Number") {
      systemSettings[index] = {
        ...systemSettings[index],
        numberValue: parseInt(event.target.value),
        errors: {}
      };
    } else {
      systemSettings[index] = {
        ...systemSettings[index],
        stringValue: event.target.value,
        errors: {}
      };
    }

    this.setState({systemSettings: systemSettings});
  }

  private async onSettingsSave(code: string) {
    this.setState({isLoading: true});

    let state = {...this.state};
    let matchingSettings = state.systemSettings?.filter(x => x.code === code);
    if (matchingSettings !== undefined && matchingSettings !== null && matchingSettings.length > 0) {
      let setting = matchingSettings[0];

      let token = AuthHelper.getAccessToken() ?? '';
      let request: ISaveSystemSettingRequest;

      if (setting.valueType === "Number") {
        request = {
          code: setting.code,
          numberValue: setting.numberValue,
          stringValue: null
        };
      } else {
        request = {
          code: setting.code,
          stringValue: setting.stringValue
        };
      }

      try {
        const response = await Api.saveSystemSettings(token, request);

        const updatedSettings = this.updateLinesFromServer(response, code, true);

        let state = {...this.state};
        state.systemSettings = updatedSettings;
        state.systemSettingsToRevert = response
        state.isLoading = false;
        this.setState(state);
      } catch (e) {
        this.setValidationErrors(
          Validations.buildApiCommunicationErrors('Can\'t save system settings on the server', e)
        );
        this.setSettingErrors(code);
      }
    }
  }

  private updateLinesFromServer(response: ISystemSettingInfoResponse[], code: string, disableEditMode?: boolean) {
    let state = {...this.state};
    let newSystemSettings: ISystemSettingInfoResponse[] = [];

    response.forEach(setting => {
      let matchingSetting = state.systemSettings?.filter(x => x.code === setting.code);
      let isEditMode = matchingSetting[0]?.isEditMode !== undefined;

      if (matchingSetting && !isEditMode) {
        newSystemSettings.push(setting);
      }

      if (matchingSetting && isEditMode) {
        if (disableEditMode && matchingSetting[0].code === code) {
          matchingSetting[0].isEditMode = false;
        }

        newSystemSettings.push(matchingSetting[0]);
      }

      if (!matchingSetting) {
        newSystemSettings.push(setting);
      }
    });

    return newSystemSettings;
  }

  private onSettingsRevert(code: string, index: number) {
    let state = {...this.state};
    let systemSettings = [...this.state.systemSettings];

    let matchingSettings = state.systemSettingsToRevert.filter(x => x.code === code);
    if (matchingSettings !== undefined && matchingSettings !== null && matchingSettings.length > 0) {
      let setting = matchingSettings[0];

      systemSettings[index] = {
        ...systemSettings[index],
        isEditMode: false,
        stringValue: setting.stringValue,
        numberValue: setting.numberValue,
        errors: {}
      };
      this.setState({systemSettings: systemSettings});
    }
  }

  private setSettingErrors(code: string) {
    const systemSettings = [...this.state.systemSettings];

    systemSettings.map(x => {
      if (x.code === code) {
        return x.errors = this.state.validationErrors;
      } else {
        return x;
      }
    });

    this.setState({systemSettings: systemSettings});
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }
}

export default withEngineCompartmentAuthorize(SystemSettingsPage);