import React, { Component } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Routes from "../../../../data/Routes";
import Api from "../../../../tools/api/api";
import ICreateUserRequest from "../../../../tools/api/api-interfaces/users/ICreateUserRequest";
import AuthHelper from "../../../../tools/auth/AuthHelper";
import ValidationErrors from "../../../../tools/validation-helper/ValidationErrors";
import Validations from "../../../../tools/validation-helper/Validations";
import withEngineCompartmentAuthorize from "../../../common/authorize/withEngineCompartmentAuthorize";
import IconButton from "../../../common/buttons/icon-button/IconButton";
import ContainerPageSize from "../../../common/layout-components/container-page-size/ContainerPageSize";
import { VerticalSpaceSize } from "../../../common/layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../common/layout-components/vertical-space/VerticalSpace";
import ValidationMessages from "../../../common/validation-messages/ValidationMessages";
import ValidationSummary from "../../../common/validation-summary/ValidationSummary";
import { ICreateUserPageProps } from "./ICreateUserPageProps";
import { ICreateUserPageState } from "./ICreateUserPageState";
import styles from './CreateUserPage.module.scss';

class CreateUserPage extends Component<ICreateUserPageProps, ICreateUserPageState> {
  constructor(props: ICreateUserPageProps) {
    super(props);

    this.state = {
      email: "",
      name: "",
      password: "",
      passwordConfirmation: "",
      redirect: null,
      isLoading: false,
      validationErrors: {}
    };

    this.onEmailChange = this.onEmailChange.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onPasswordConfirmationChange = this.onPasswordConfirmationChange.bind(this);
    this.onCreateUser = this.onCreateUser.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  render() {
    if (this.state.redirect !== null) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <>
        <VerticalSpace size={VerticalSpaceSize.small}/>
        <ContainerPageSize>
          <VerticalSpace size={VerticalSpaceSize.small}/>
          <>
            <h1>Create user</h1>
            <Row>
              <Col md={{span: "8", offset: "2"}}>
                <ValidationSummary errors={this.state.validationErrors}
                                   excludeKeys={["Email", "Name", "Password", "PasswordConfirmation"]}/>

              </Col>
            </Row>
            <Form>
              <Row>
                <Col md={6} lg={5}>
                  <Form.Label className={styles.label}>Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter email"
                    maxLength={1000}
                    value={this.state.email}
                    onChange={this.onEmailChange}
                  />
                  <ValidationMessages fieldName="Email" errors={this.state.validationErrors}/>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col md={6} lg={5}>
                  <Form.Label className={styles.label}>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    required
                    maxLength={100}
                    value={this.state.name}
                    onChange={this.onNameChange}
                  />
                  <ValidationMessages fieldName="Name" errors={this.state.validationErrors}/>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col md={6} lg={5}>
                  <Form.Label className={styles.label}>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter password"
                    autoComplete="new-password"
                    value={this.state.password}
                    onChange={this.onPasswordChange}
                  />
                  <ValidationMessages fieldName="Password" errors={this.state.validationErrors}/>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col md={6} lg={5}>
                  <Form.Label className={styles.label}>Password confirmation</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter password confirmation"
                    value={this.state.passwordConfirmation}
                    onChange={this.onPasswordConfirmationChange}
                  />
                  <ValidationMessages fieldName="PasswordConfirmation" errors={this.state.validationErrors}/>
                </Col>
              </Row>
              <VerticalSpace size={VerticalSpaceSize.normal}/>
              <IconButton onClick={this.onCreateUser} title="Save" variant={"outline-primary"} styles={{marginRight: "1em"}}/>
              <IconButton onClick={this.onCancel} title="Cancel" variant={"outline-primary"}/>
            </Form>
          </>
        </ContainerPageSize>
      </>
    );

  }

  async onCreateUser() {
    let token = AuthHelper.getAccessToken() ?? '';
    try {
      let request: ICreateUserRequest = {
        email: this.state.email,
        name: this.state.name,
        password: this.state.password,
        passwordConfirmation: this.state.passwordConfirmation
      };

      await Api.createUser(token, request);

      let state = { ...this.state };
      state.redirect = Routes.users;
      this.setState(state);
    } catch (err) {
      this.setError(
        Validations.buildApiCommunicationErrors('Can\'t create user on the server', err)
      );
    }
  }

  private onCancel() {
    let state = {...this.state};
    state.redirect = Routes.users;
    this.setState(state);
  }

  private setError(errors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = errors;
    this.setState(state);
  }

  private onEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setAllValidationErrors({});

    this.setState({email: e.target.value});
  }

  private onNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setAllValidationErrors({});

    this.setState({name: e.target.value});
  }

  private onPasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setAllValidationErrors({});

    this.setState({password: e.target.value});
  }

  private onPasswordConfirmationChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setAllValidationErrors({});

    this.setState({passwordConfirmation: e.target.value});
  }

  private setAllValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    this.setState(state);
  }
}

export default withEngineCompartmentAuthorize(CreateUserPage);