import React, { Component } from "react";
import { Alert, Col, Form, FormControl, InputGroup, Nav, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import Routes from "../../../../data/Routes";
import Api from "../../../../tools/api/api";
import ICreateProjectGroupRequest from "../../../../tools/api/api-interfaces/projects/manage/ICreateGroupRequest";
import ICreateProjectRequest from "../../../../tools/api/api-interfaces/projects/manage/ICreateProjectRequest";
import { IEditGroupRequest } from "../../../../tools/api/api-interfaces/projects/manage/IEditGroupRequest";
import IProject from "../../../../tools/api/api-interfaces/projects/manage/IProject";
import IProjectGroup from "../../../../tools/api/api-interfaces/projects/manage/IProjectGroup";
import { IUpdateProjectRequest } from "../../../../tools/api/api-interfaces/projects/manage/IUpdateProjectRequest";
import AuthHelper from "../../../../tools/auth/AuthHelper";
import ValidationErrors from "../../../../tools/validation-helper/ValidationErrors";
import Validations from "../../../../tools/validation-helper/Validations";
import withEngineCompartmentAuthorize from "../../../common/authorize/withEngineCompartmentAuthorize";
import IconButton from "../../../common/buttons/icon-button/IconButton";
import ContainerPageSize from "../../../common/layout-components/container-page-size/ContainerPageSize";
import { LoadingBarHeight } from "../../../common/layout-components/loading-bar/ILoadingBarProps";
import LoadingBar from "../../../common/layout-components/loading-bar/LoadingBar";
import SubMenu from "../../../common/layout-components/sub-menu/SubMenu";
import { VerticalSpaceSize } from "../../../common/layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../common/layout-components/vertical-space/VerticalSpace";
import ValidationMessages from "../../../common/validation-messages/ValidationMessages";
import ValidationSummary from "../../../common/validation-summary/ValidationSummary";
import DeleteProjectGroupModal from "./delete-project-group-modal/DeleteProjectGroupModal";
import DeleteProjectModal from "./delete-project-modal/DeleteProjectModal";
import { IManageProjectsTreePageProps } from "./IManageProjectsTreePageProps";
import { IManageProjectsTreePageState } from "./IManageProjectsTreePageState";
import styles from "./ManageProjectsTreePage.module.scss";

class ManageProjectsTreePage extends Component<IManageProjectsTreePageProps, IManageProjectsTreePageState> {
  constructor(props: IManageProjectsTreePageProps) {
    super(props);

    this.state = {
      groupId: null,
      parentPath: null,

      groups: [{
        groupId: 0,
        groupName: "",
        isNewProjectGroup: false,
        isProjectGroupEditMode: false,
        projectGroupErrors: {}
      }],

      groupsToRevert: null,

      projects: [{
        projectId: 0,
        projectName: "",
        projectApiName: "",
        isNewProject: false,
        isProjectEditMode: false,
        projectErrors: {}
      }],

      projectsToRevert: null,

      isDeleteProjectGroupDialogOpen: false,
      groupIdToDelete: undefined,
      groupIndexToDelete: undefined,
      groupFullNameToDelete: undefined,
      parentGroupName: undefined,

      isDeleteProjectDialogOpen: false,
      projectIdToDelete: undefined,
      projectIndexToDelete: undefined,
      projectFullNameToDelete: undefined,

      isLoading: true,
      validationErrors: {},
    };

    this.onAddProjectGroup = this.onAddProjectGroup.bind(this);
    this.onAddProject = this.onAddProject.bind(this);

    this.onSaveProjectGroup = this.onSaveProjectGroup.bind(this);
    this.onSaveProject = this.onSaveProject.bind(this);

    this.onConfirmDeleteProjectGroupClick = this.onConfirmDeleteProjectGroupClick.bind(this);
    this.onCancelDeleteProjectGroupClick = this.onCancelDeleteProjectGroupClick.bind(this);

    this.onConfirmDeleteProjectClick = this.onConfirmDeleteProjectClick.bind(this);
    this.onCancelDeleteProjectClick = this.onCancelDeleteProjectClick.bind(this);
  }

  render() {
    return (
      <>
        {
          this.state.isLoading ? <LoadingBar size={LoadingBarHeight.normal}/> :
            <>
              <>
                <SubMenu>
                  <LinkContainer to={"#"} onClick={this.onAddProjectGroup}>
                    <Nav.Link><span className="navItemLink">Add project group</span></Nav.Link>
                  </LinkContainer>
                  <LinkContainer to={"#"} onClick={this.onAddProject}>
                    <Nav.Link><span className="navItemLink">Add project</span></Nav.Link>
                  </LinkContainer>
                </SubMenu>

                <VerticalSpace size={VerticalSpaceSize.small}/>

                <ContainerPageSize>
                  <DeleteProjectGroupModal
                    showDialog={this.state.isDeleteProjectGroupDialogOpen}
                    groupIdToDelete={this.state.groupIdToDelete}
                    groupNameToDelete={this.state.groupFullNameToDelete}
                    groupParentName={this.state.parentGroupName}
                    onConfirm={this.onConfirmDeleteProjectGroupClick}
                    onCancel={this.onCancelDeleteProjectGroupClick}
                  />

                  <DeleteProjectModal
                    showDialog={this.state.isDeleteProjectDialogOpen}
                    projectIdToDelete={this.state.projectIdToDelete}
                    projectNameToDelete={this.state.projectFullNameToDelete}
                    onConfirm={this.onConfirmDeleteProjectClick}
                    onCancel={this.onCancelDeleteProjectClick}
                  />

                  <VerticalSpace size={VerticalSpaceSize.small}/>

                  <div className="d-flex flex-wrap">
                    <LinkContainer to={Routes.manageProjectsTree} className={"p-1"}
                                   onClick={() => this.getGroupsWithProjectsOneLevel()}>
                      <Nav.Link><span style={{paddingRight: "1rem"}}>Root</span></Nav.Link>
                    </LinkContainer>

                    {
                      this.state.parentPath && this.state.parentPath?.length > 0 &&
                      (
                        this.state.parentPath.map((x) => {
                          return (
                            <div key={x.groupId} className="d-flex align-items-center">
                              <div style={{paddingRight: "1rem"}}> &frasl; </div>

                              <LinkContainer to={Routes.buildProjectGroupUrl(x.groupId)} className={"p-1"}
                                             onClick={() => this.getGroupsWithProjects(x.groupId)}>
                                <Nav.Link>
                                  <span className={"text-break"} style={{paddingRight: "1rem"}}>{x.groupName}</span>
                                </Nav.Link>
                              </LinkContainer>
                            </div>
                          );
                        })
                      )
                    }
                  </div>

                  <VerticalSpace size={VerticalSpaceSize.small}/>

                  <Row>
                    <Col md={{span: "8", offset: "2"}}>
                      <ValidationSummary errors={this.state.validationErrors}
                                         excludeKeys={["Name", "ApiName", "NewName", "NewApiName", "ParentGroupId"]}/>
                    </Col>
                  </Row>

                  {
                    this.state.groups === null && this.state.projects === null
                      ?
                      <>
                        <div>
                          <VerticalSpace size={VerticalSpaceSize.small}/>
                          <Row>
                            <Col md={{span: "6", offset: "3"}}>
                              <Alert variant="info" className={"text-center"}>
                                No projects found.
                              </Alert>
                            </Col>
                          </Row>
                        </div>
                      </>
                      :
                      <>
                        {
                          this.state.groups.map((group, index) => {
                            return (
                              <div key={group.groupId}>
                                {
                                  group.isNewProjectGroup || group.isProjectGroupEditMode
                                    ?
                                    <Row>
                                      <Col md={3} style={{maxWidth: "11rem"}} className="mb-md-0 mb-2">
                                        <div className="d-none d-md-block">
                                          <Form.Label>&nbsp;</Form.Label>
                                        </div>

                                        <IconButton onClick={() => this.onSaveProjectGroup(group.groupId)}
                                                    iconType={"submit"} variant={"outline-primary"}
                                                    styles={{margin: "0.15em"}} toolTipTitle={"Save"}
                                        />

                                        {
                                          group.isProjectGroupEditMode
                                            ?
                                            <IconButton onClick={() => this.onRevertProjectGroup(group.groupId, index)}
                                                        iconType={"revert"} variant={"outline-primary"}
                                                        styles={{margin: "0.15em"}} toolTipTitle={"Revert"}/>
                                            :
                                            <IconButton onClick={() => this.onDeleteProjectGroup(group.groupId, index)}
                                                        iconType={"revert"} variant={"outline-primary"}
                                                        styles={{margin: "0.15em"}} toolTipTitle={"Revert"}
                                            />
                                        }
                                      </Col>
                                      <Col md={5}>
                                        <Form.Label className={styles.label}>Group name</Form.Label>
                                        <InputGroup>
                                          <FormControl
                                            type="text"
                                            name="groupName"
                                            autoFocus={true}
                                            placeholder="Please enter group name"
                                            value={group.groupName}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onGroupNameChange(e, index)}
                                            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Enter' && this.onSaveProjectGroup(group.groupId)}
                                          />
                                        </InputGroup>
                                        <ValidationMessages fieldName="Name" errors={group.projectGroupErrors}/>
                                        <ValidationMessages fieldName="NewName" errors={group.projectGroupErrors}/>
                                        <ValidationMessages fieldName="ParentGroupId" errors={group.projectGroupErrors}/>
                                      </Col>
                                    </Row>
                                    :
                                    <Row>
                                      <Col md={3} style={{maxWidth: "11rem"}}>
                                        <IconButton onClick={() => this.onEditProjectGroup(index)}
                                                    iconType={"edit"} variant={"outline-primary"}
                                                    styles={{margin: "0.15em"}} toolTipTitle={"Rename"}
                                        />

                                        <Link to={Routes.buildProjectGroupMoveUrl(group.groupId, this.state.groupId ?? 0)}>
                                          <IconButton onClick={() => function onMoveProjectGroup() {
                                          }}
                                                      iconType={"move"} variant={"outline-primary"}
                                                      styles={{margin: "0.15em"}} toolTipTitle={"Move"}
                                          />
                                        </Link>

                                        <IconButton onClick={() => this.onDeleteProjectGroup(group.groupId, index)}
                                                    iconType={"remove"} variant={"outline-primary"}
                                                    styles={{margin: "0.15em"}} toolTipTitle={"Delete"}
                                        />
                                      </Col>
                                      <Col md={9} className={"text-break"}>
                                        <LinkContainer className="p-1" to={Routes.buildProjectGroupUrl(group.groupId)}
                                                       onClick={() => this.onGetGroup(group.groupId)}>
                                          <Nav.Link>{group.groupName}</Nav.Link>
                                        </LinkContainer>
                                        <ValidationMessages fieldName="groupId" errors={group.projectGroupErrors}/>
                                      </Col>
                                    </Row>
                                }
                                <hr className="d-block d-md-none"/>
                              </div>
                            );
                          })
                        }

                        {
                          this.state.projects.map((project, index) => {
                            return (
                              <div key={project.projectId} className={"text-break"}>

                                {
                                  project.isNewProject || project.isProjectEditMode
                                    ?
                                    <Row>
                                      <Col md={3} style={{maxWidth: "11rem"}} className="mb-md-0 mb-2">
                                        <div className="d-none d-md-block">
                                          <Form.Label>&nbsp;</Form.Label>
                                        </div>
                                        <IconButton onClick={() => this.onSaveProject(project.projectId)}
                                                    iconType={"submit"} variant={"outline-primary"}
                                                    styles={{margin: "0.15em"}} toolTipTitle={"Save"}/>
                                        {
                                          project.isProjectEditMode
                                            ?
                                            <IconButton onClick={() => this.onRevertProject(project.projectId, index)}
                                                        iconType={"revert"} variant={"outline-primary"}
                                                        styles={{margin: "0.15em"}} toolTipTitle={"Revert"}

                                            />
                                            :
                                            <IconButton onClick={() => this.onDeleteProject(project.projectId, index)}
                                                        iconType={"revert"} variant={"outline-primary"}
                                                        styles={{margin: "0.15em"}} toolTipTitle={"Revert"}
                                            />
                                        }

                                      </Col>
                                      <Col md={5} className="mb-md-0 mb-2">
                                        <Form.Label className={styles.label}>Project name</Form.Label>
                                        <InputGroup>
                                          <FormControl
                                            type="text"
                                            name="projectName"
                                            autoFocus={true}
                                            placeholder="Please enter project name"
                                            value={project.projectName}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onProjectNameChange(e, index)}
                                          />
                                        </InputGroup>
                                        <ValidationMessages fieldName="Name" errors={project.projectErrors}/>
                                        <ValidationMessages fieldName="NewName" errors={project.projectErrors}/>
                                      </Col>

                                      <Col md={4}>
                                        <Form.Label className={styles.label}>Project API name</Form.Label>
                                        <InputGroup>
                                          <FormControl
                                            type="text"
                                            name="projectApiName"
                                            placeholder="Please enter project API name"
                                            value={project.projectApiName}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onProjectApiNameChange(e, index)}
                                            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Enter' && this.onSaveProject(project.projectId)}
                                          />
                                        </InputGroup>
                                        <ValidationMessages fieldName="ApiName" errors={project.projectErrors}/>
                                        <ValidationMessages fieldName="NewApiName" errors={project.projectErrors}/>
                                      </Col>
                                    </Row>
                                    :
                                    <Row>
                                      <Col md={3} style={{maxWidth: "11rem"}} className="mb-md-0 mb-2">
                                        <IconButton onClick={() => this.onEditProject(project.projectId, index)}
                                                    iconType={"edit"} variant={"outline-primary"}
                                                    styles={{margin: "0.15em"}} toolTipTitle={"Rename"}/>

                                        <Link to={Routes.buildProjectMoveUrl(project.projectId, this.state.groupId ?? 0)}>
                                          <IconButton onClick={() => function onMoveProject() {
                                          }}
                                                      iconType={"move"} variant={"outline-primary"}
                                                      styles={{margin: "0.15em"}} toolTipTitle={"Move"}
                                          />
                                        </Link>

                                        <IconButton onClick={() => this.onDeleteProject(project.projectId, index)}
                                                    iconType={"remove"} variant={"outline-primary"}
                                                    styles={{margin: "0.15em"}} toolTipTitle={"Delete"}
                                        />
                                      </Col>
                                      <Col md={9}>
                                        <div className={"p-1"}>{project.projectName}</div>
                                      </Col>
                                    </Row>
                                }
                                <hr className="d-block d-md-none"/>
                              </div>
                            );
                          })
                        }
                      </>
                  }
                </ContainerPageSize>
              </>
            </>
        }
      </>
    );
  }

  async componentDidUpdate(prevProps: Readonly<IManageProjectsTreePageProps>) {
    if (this.props.groupId !== prevProps.groupId) {
      let groupId = parseInt(this.props.groupId as string);
      this.setState({groupId: !groupId ? null : groupId});
    }
  }

  async componentDidMount() {
    let groupId = parseInt(this.props.groupId as string);
    this.setState({groupId: !groupId ? null : groupId});
    await this.getGroupsWithProjects(groupId);
  }

  private async getGroupsWithProjects(groupId: number) {
    if (groupId) {
      await this.onGetGroup(groupId);
    } else {
      await this.getGroupsWithProjectsOneLevel();
    }
  }

  private async getGroupsWithProjectsOneLevel() {
    let token = AuthHelper.getAccessToken() ?? '';

    try {
      const response = await Api.getGroupsWithProjectsOneLevel(token);

      this.setState({
        groupId: null,
        groups: response.groups,
        groupsToRevert: response.groups,
        projects: response.projects,
        projectsToRevert: response.projects,
        parentPath: null,
        isLoading: false
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get groups with projects one level from the server', err)
      );
    }
  }

  private async onGetGroup(groupId: number) {
    let token = AuthHelper.getAccessToken() ?? '';

    try {
      let response = await Api.getGroupOneLevel(token, groupId);

      this.setState({
        parentPath: response.parentPath,
        groups: response.groupsAndProjects.groups,
        groupsToRevert: response.groupsAndProjects.groups,
        projects: response.groupsAndProjects.projects,
        projectsToRevert: response.groupsAndProjects.projects,
        isLoading: false
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors(`Can't get groups with projects from the server by id: ${groupId}`, err)
      );
    }
  }

  private onAddProjectGroup() {
    let groups = [...this.state.groups];
    groups.push({
      groupId: -Math.floor(Math.random() * 10000),
      groupName: "",
      isNewProjectGroup: true,
      isProjectGroupEditMode: false,
      projectGroupErrors: {}
    });

    this.setState({groups: groups});
  }

  private onAddProject() {
    let projects = [...this.state.projects];
    projects.push({
      projectId: -Math.floor(Math.random() * 10000),
      projectName: "",
      projectApiName: "",
      isNewProject: true,
      isProjectEditMode: false,
      projectErrors: {}
    });

    this.setState({projects: projects});
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }

  private onEditProjectGroup(index: number) {
    let groups = [...this.state.groups];
    groups[index] = {...groups[index], isProjectGroupEditMode: true};
    this.setState({groups: groups});
  }

  private onRevertProjectGroup(groupId: number, index: number) {
    let state = {...this.state};
    let groups = [...this.state.groups];

    let matchingGroups = state.groupsToRevert?.filter(x => x.groupId === groupId);
    if (matchingGroups !== undefined && matchingGroups !== null && matchingGroups.length > 0) {
      let line = matchingGroups[0];

      groups[index] = {...groups[index], isProjectGroupEditMode: false, groupName: line.groupName, projectGroupErrors: {}};
      this.setState({groups: groups});
    }
  }

  private onGroupNameChange(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    let groups = [...this.state.groups];
    groups[index] = {...groups[index], groupName: e.target.value, projectGroupErrors: {}};
    this.setState({groups: groups});
  }

  private async onSaveProjectGroup(groupId: number) {
    let state = {...this.state};
    let matchingGroups = state.groups.filter(x => x.groupId === groupId);
    if (matchingGroups !== undefined && matchingGroups !== null && matchingGroups.length > 0) {
      let group = matchingGroups[0];
      let token = AuthHelper.getAccessToken() ?? '';

      if (group.isProjectGroupEditMode) {
        let request: IEditGroupRequest = {newName: group.groupName};

        try {
          let response = await Api.editProjectGroup(token, groupId, request);

          const updatedGroups = this.updateProjectGroupsFromServer(response.groups, groupId, true);
          const updatedProjects = this.updateProjectsFromServer(response.projects, 0);

          this.setState({
            groups: updatedGroups,
            groupsToRevert: response.groups,
            projects: updatedProjects,
            projectsToRevert: response.projects
          });
        } catch (err) {
          this.setValidationErrors(Validations.buildApiCommunicationErrors(`Can't edit group with id: ${groupId}`, err));
          this.setGroupErrors(groupId);
        }
      } else {
        let request: ICreateProjectGroupRequest = {
          parentGroupId: this.state.groupId,
          name: group.groupName
        };

        try {
          let response = await Api.createProjectGroup(token, request);

          const updatedGroups = this.updateProjectGroupsFromServer(response.groups, groupId);

          this.setState({groups: updatedGroups, groupsToRevert: response.groups});
        } catch (err) {
          this.setValidationErrors(Validations.buildApiCommunicationErrors(`Can't create project group`, err));
          this.setGroupErrors(groupId);
        }
      }
    }
  }

  private setGroupErrors(groupId: number) {
    const groups = [...this.state.groups];

    groups.map(x => {
      if (x.groupId === groupId) {
        return x.projectGroupErrors = this.state.validationErrors;
      } else {
        return x;
      }
    });

    this.setState({groups: groups});
  }

  private updateProjectGroupsFromServer(response: IProjectGroup[], groupId: number, disableEditMode?: boolean) {
    let state = {...this.state};
    let newProjectGroups: IProjectGroup[] = [];

    response.forEach(group => {
      let matchingGroup = state.groups.filter(x => x.groupId === group.groupId);
      let isProjectGroupEditMode = matchingGroup[0]?.isProjectGroupEditMode !== undefined;

      if (matchingGroup && !isProjectGroupEditMode) {
        newProjectGroups.push(group);
      }

      if (matchingGroup && isProjectGroupEditMode) {
        if (disableEditMode && matchingGroup[0].groupId === groupId) {
          matchingGroup[0].isProjectGroupEditMode = false;
        }

        newProjectGroups.push(matchingGroup[0]);
      }

      if (!matchingGroup) {
        newProjectGroups.push(group);
      }
    });

    state.groups.forEach(group => {
      if (group.isNewProjectGroup) {
        newProjectGroups.push(group);
      }
    });

    if (groupId < 0) {
      return newProjectGroups.filter(x => x.groupId !== groupId);
    }

    return newProjectGroups;
  }

  private async onDeleteProjectGroup(groupId: number, index: number) {
    let token = AuthHelper.getAccessToken() ?? '';
    let state = {...this.state};

    try {
      if (groupId > 0) {
        const response = await Api.getProjectGroupDeleteInfo(token, groupId);
        state.groupFullNameToDelete = response.groupFullName;
        state.parentGroupName = response.parentGroupName;
        state.groupIdToDelete = groupId;
        state.groupIndexToDelete = index;
        state.isDeleteProjectGroupDialogOpen = true;
        this.setState(state);
      } else {
        let groupForDelete = this.state.groups.filter(x => x.groupId === groupId);
        if (groupForDelete !== undefined && groupForDelete !== null && groupForDelete.length > 0) {
          if (groupForDelete[0].isNewProjectGroup) {
            let indexToDelete = index;
            if (indexToDelete !== undefined) {
              let groups = [...this.state.groups];
              groups.splice(indexToDelete, 1);
              this.setState({groups: groups});
            }
          }
        }
      }
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get project group delete info from the server', err)
      );
    }
  }

  private async onConfirmDeleteProjectGroupClick(groupId: number) {
    let groupForDelete = this.state.groups.filter(x => x.groupId === groupId);
    if (groupForDelete !== undefined && groupForDelete !== null && groupForDelete.length > 0) {
      if (!groupForDelete[0].isNewProjectGroup) {
        let token = AuthHelper.getAccessToken() ?? '';

        try {
          let response = await Api.deleteProjectGroup(token, groupId);

          let updatedGroups = this.updateProjectGroupsFromServer(response.groups, groupId);

          this.setState({groups: updatedGroups, groupsToRevert: response.groups});
        } catch (err) {
          this.setValidationErrors(Validations.buildApiCommunicationErrors('Can\'t delete project group', err));

          this.closeDeleteProjectGroupDialog();
          return;
        }
      }
    }
    this.closeDeleteProjectGroupDialog();
  }

  private onCancelDeleteProjectGroupClick() {
    this.closeDeleteProjectGroupDialog();
  }

  private closeDeleteProjectGroupDialog() {
    let state = {...this.state};
    state.groupIdToDelete = undefined;
    state.groupFullNameToDelete = undefined;
    state.parentGroupName = undefined;
    state.isDeleteProjectGroupDialogOpen = false;
    this.setState(state);
  }

  private async onEditProject(projectId: number, index: number) {
    let token = AuthHelper.getAccessToken() ?? '';

    try {
      let response = await Api.getInfoForProjectUpdate(token, projectId);

      let projects = [...this.state.projects];
      projects[index] = {
        ...projects[index],
        isProjectEditMode: true,
        projectName: response.projectName,
        projectApiName: response.projectApiName
      };

      this.setState({projects: projects});
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get info for edit project from the server', err)
      );
    }
  }

  private onProjectNameChange(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    let projects = [...this.state.projects];
    projects[index] = {...projects[index], projectName: e.target.value, projectErrors: {}};
    this.setState({projects: projects});
  }

  private onProjectApiNameChange(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    let projects = [...this.state.projects];
    projects[index] = {...projects[index], projectApiName: e.target.value, projectErrors: {}};
    this.setState({projects: projects});
  }

  private onRevertProject(projectId: number, index: number) {
    let state = {...this.state};
    let projects = [...this.state.projects];

    let matchingProjects = state.projectsToRevert?.filter(x => x.projectId === projectId);
    if (matchingProjects !== undefined && matchingProjects !== null && matchingProjects.length > 0) {
      let line = matchingProjects[0];

      projects[index] = {...projects[index], isProjectEditMode: false, projectName: line.projectName};
      this.setState({projects: projects});
    }
  }

  private async onDeleteProject(projectId: number, index: number) {
    let token = AuthHelper.getAccessToken() ?? '';
    let state = {...this.state};

    try {
      if (projectId > 0) {
        const response = await Api.getProjectDeleteInfo(token, projectId);
        state.projectFullNameToDelete = response.projectFullName;
        state.projectIdToDelete = projectId;
        state.projectIndexToDelete = index;
        state.isDeleteProjectDialogOpen = true;
        this.setState(state);
      } else {
        let projectForDelete = this.state.projects.filter(x => x.projectId === projectId);
        if (projectForDelete !== undefined && projectForDelete !== null && projectForDelete.length > 0) {
          if (projectForDelete[0].isNewProject) {
            let indexToDelete = index;
            if (indexToDelete !== undefined) {
              let projects = [...this.state.projects];
              projects.splice(indexToDelete, 1);
              this.setState({projects: projects});
            }
          }
        }
      }
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get project delete info from the server', err)
      );
    }
  }

  private async onConfirmDeleteProjectClick(projectId: number) {
    let projectForDelete = this.state.projects.filter(x => x.projectId === projectId);
    if (projectForDelete !== undefined && projectForDelete !== null && projectForDelete.length > 0) {
      if (!projectForDelete[0].isNewProject) {
        let token = AuthHelper.getAccessToken() ?? '';
        try {
          let response = await Api.deleteProject(token, projectId);
          let updatedProjects = this.updateProjectsFromServer(response.projects, projectId);

          this.setState({projects: updatedProjects, projectsToRevert: response.projects});
        } catch (err) {
          this.setValidationErrors(Validations.buildApiCommunicationErrors('Can\'t delete project', err));

          this.closeDeleteProjectDialog();
          return;
        }
      }
    }
    this.closeDeleteProjectDialog();
  }

  private onCancelDeleteProjectClick() {
    this.closeDeleteProjectDialog();
  }

  private closeDeleteProjectDialog() {
    let state = {...this.state};
    state.projectIdToDelete = undefined;
    state.projectFullNameToDelete = undefined;
    state.isDeleteProjectDialogOpen = false;
    this.setState(state);
  }

  private async onSaveProject(projectId: number) {
    let state = {...this.state};
    let matchingProjects = state.projects.filter(x => x.projectId === projectId);
    if (matchingProjects !== undefined && matchingProjects !== null && matchingProjects.length > 0) {
      let project = matchingProjects[0];
      let token = AuthHelper.getAccessToken() ?? '';

      if (project.isProjectEditMode) {
        let request: IUpdateProjectRequest = {
          newName: project.projectName,
          newApiName: project.projectApiName
        };

        try {
          let response = await Api.updateProject(token, projectId, request);

          const updatedProjects = this.updateProjectsFromServer(response.projects, projectId, true);
          const updatedGroups = this.updateProjectGroupsFromServer(response.groups, 0);

          this.setState({
            groups: updatedGroups,
            groupsToRevert: response.groups,
            projects: updatedProjects,
            projectsToRevert: response.projects
          });
        } catch (err) {
          this.setValidationErrors(Validations.buildApiCommunicationErrors(`Can't edit project with id: ${projectId}`, err));
          this.setProjectErrors(projectId);
        }
      } else {
        let request: ICreateProjectRequest = {
          groupId: this.state.groupId,
          name: project.projectName,
          apiName: project.projectApiName
        };

        try {
          let response = await Api.createProject(token, request);

          const updatedProjects = this.updateProjectsFromServer(response.projects, projectId);

          this.setState({projects: updatedProjects, projectsToRevert: response.projects});
        } catch (err) {
          this.setValidationErrors(Validations.buildApiCommunicationErrors(`Can't create project`, err));
          this.setProjectErrors(projectId);
        }
      }
    }
  }

  private setProjectErrors(projectId: number) {
    const projects = [...this.state.projects];

    projects.map(x => {
      if (x.projectId === projectId) {
        return x.projectErrors = this.state.validationErrors;
      } else {
        return x;
      }
    });

    this.setState({projects: projects});
  }

  private updateProjectsFromServer(response: IProject[], projectId: number, disableEditMode?: boolean) {
    let state = {...this.state};
    let newProject: IProject[] = [];

    response.forEach(project => {
      let matchingProjects = state.projects.filter(x => x.projectId === project.projectId);
      let isProjectEditMode = matchingProjects[0]?.isProjectEditMode !== undefined;

      if (matchingProjects && !isProjectEditMode) {
        newProject.push(project);
      }

      if (matchingProjects && isProjectEditMode) {
        if (disableEditMode && matchingProjects[0].projectId === projectId) {
          matchingProjects[0].isProjectEditMode = false;
        }

        newProject.push(matchingProjects[0]);
      }

      if (!matchingProjects) {
        newProject.push(project);
      }
    });

    state.projects.forEach(project => {
      if (project.isNewProject) {
        newProject.push(project);
      }
    });

    if (projectId < 0) {
      return newProject.filter(x => x.projectId !== projectId);
    }

    return newProject;
  }
}

export default withEngineCompartmentAuthorize(ManageProjectsTreePage);