import React, { ChangeEvent, Component } from 'react';
import { Alert, Col, Form, Nav, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Api from "../../../../tools/api/api";
import { IAddUserGroupRequest } from "../../../../tools/api/api-interfaces/projects/security/IAddUserGroupRequest";
import { IPermissionAvailabilityInfo } from "../../../../tools/api/api-interfaces/projects/security/IPermissionAvailabilityInfo";
import { IProjectSecurityGroupWithPermissionsInfo } from "../../../../tools/api/api-interfaces/projects/security/IProjectSecurityGroupWithPermissionsInfo";
import AuthHelper from "../../../../tools/auth/AuthHelper";
import ValidationErrors from "../../../../tools/validation-helper/ValidationErrors";
import Validations from "../../../../tools/validation-helper/Validations";
import withEngineCompartmentAuthorize from "../../../common/authorize/withEngineCompartmentAuthorize";
import IconButton from "../../../common/buttons/icon-button/IconButton";
import ContainerPageSize from "../../../common/layout-components/container-page-size/ContainerPageSize";
import { LoadingBarHeight } from "../../../common/layout-components/loading-bar/ILoadingBarProps";
import LoadingBar from "../../../common/layout-components/loading-bar/LoadingBar";
import ProjectSubMenu from "../../../common/layout-components/project-sub-menu/ProjectSubMenu";
import SubMenu from "../../../common/layout-components/sub-menu/SubMenu";
import { VerticalSpaceSize } from "../../../common/layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../common/layout-components/vertical-space/VerticalSpace";
import ValidationMessages from "../../../common/validation-messages/ValidationMessages";
import ValidationSummary from "../../../common/validation-summary/ValidationSummary";
import DeleteGroupWithPermissionsModal from "./delete-group-with-permissions-modal/DeleteGroupWithPermissionsModal";
import { IProjectSecurityPageProps } from "./IProjectSecurityPageProps";
import { IProjectSecurityPageState } from "./IProjectSecurityPageState";

const DefaultGroupId: number = 0;

class ProjectSecurityPage extends Component<IProjectSecurityPageProps, IProjectSecurityPageState> {

  constructor(props: IProjectSecurityPageProps) {
    super(props);

    this.state = {
      projectId: null,
      projectFullName: "",
      projectGroups: [],
      projectGroupsForRevert: null,
      availableGroups: [],
      allPermissions: [],

      isDeleteDialogOpen: false,
      groupIdToDelete: undefined,
      groupIndexToDelete: undefined,
      groupNameToDelete: undefined,

      isLoading: true,
      validationErrors: {}
    };

    this.onAddPermissionsClick = this.onAddPermissionsClick.bind(this);
    this.onPermissionChange = this.onPermissionChange.bind(this);
    this.onChangeProjectGroup = this.onChangeProjectGroup.bind(this);
    this.onGroupEditClick = this.onGroupEditClick.bind(this);
    this.onRevertGroupClick = this.onRevertGroupClick.bind(this);
    this.onSaveGroupClick = this.onSaveGroupClick.bind(this);
    this.onDeleteGroupClick = this.onDeleteGroupClick.bind(this);

    this.onConfirmDeleteGroupClicked = this.onConfirmDeleteGroupClicked.bind(this);
    this.onCancelDeleteGroupClicked = this.onCancelDeleteGroupClicked.bind(this);
  }

  render() {
    const Title1 = "Groups";
    const Title2 = "Permissions";

    return (
      <>
        <ProjectSubMenu projectId={this.state.projectId ?? 0}/>

        <SubMenu>
          <LinkContainer to={"#"} onClick={this.onAddPermissionsClick}>
            <Nav.Link>Add permissions</Nav.Link>
          </LinkContainer>
        </SubMenu>

        <VerticalSpace size={VerticalSpaceSize.small}/>

        <ContainerPageSize>
          <DeleteGroupWithPermissionsModal
            showDialog={this.state.isDeleteDialogOpen}
            groupIdToDelete={this.state.groupIdToDelete}
            groupNameToDelete={this.state.groupNameToDelete}
            onConfirm={this.onConfirmDeleteGroupClicked}
            onCancel={this.onCancelDeleteGroupClicked}
          />

          <VerticalSpace size={VerticalSpaceSize.small}/>

          {
            this.state.isLoading ? <LoadingBar size={LoadingBarHeight.normal}/> :
              <>
                {
                  <>
                    <h1 className="text-break">{this.state.projectFullName}</h1>


                    <Row>
                      <Col md={{span: 6, offset: 3}}>
                        <ValidationSummary errors={this.state.validationErrors}
                                           excludeKeys={["GroupId", "PermissionCodes"]}/>
                      </Col>
                    </Row>

                    {
                      this.state.projectGroups && this.state.projectGroups.length === 0 &&
                      <div>
                          <VerticalSpace size={VerticalSpaceSize.small}/>
                          <Row>
                              <Col md={{span: "6", offset: "3"}}>
                                  <Alert variant="info" className={"text-center"}>
                                      No data found.
                                  </Alert>
                              </Col>
                          </Row>
                      </div>
                    }

                    <VerticalSpace size={VerticalSpaceSize.small}/>

                    {
                      this.state.projectGroups && this.state.projectGroups.length > 0 &&
                      <>
                          <Row>
                              <Col md={"12"}>
                                  <Alert variant="info" className={"text-center"}>
                                      Please notice that saving group with no permissions is equivalent to deleting
                                      group.
                                      If you save security group with no permission, the group will be removed from the
                                      project.
                                  </Alert>
                              </Col>
                          </Row>

                          <div className="d-none d-lg-block">
                              <Row>
                                  <Col lg={2} style={{maxWidth: "8rem"}}> </Col>
                                  <Col lg={4}><b>{Title1}</b></Col>
                                  <Col lg={6}><b>{Title2}</b></Col>
                              </Row>
                              <hr className="my-2" style={{opacity: 1}}/>
                          </div>

                        {
                          this.state.projectGroups.map((group, groupIndex) => {
                            return (
                              <div key={groupIndex}>
                                {
                                  group.isEditMode || group.isNewGroupLine
                                    ?
                                    <Row className="align-items-start">
                                      <Col lg={2} style={{maxWidth: "8rem"}}>
                                        <div className="mb-lg-0 mb-2">
                                          <IconButton onClick={() => this.onSaveGroupClick(
                                            group.selectedGroupId, group.groupId, groupIndex)}
                                                      iconType={"submit"} variant="outline-primary"
                                                      styles={{margin: "0.15em"}} toolTipTitle={"Save"}
                                          />
                                          {
                                            group.isNewGroupLine
                                              ?
                                              <IconButton
                                                onClick={() => this.onDeleteGroupClick(group.groupId, groupIndex)}
                                                iconType={"revert"} toolTipTitle={"Revert"}
                                                variant={"outline-primary"} styles={{margin: "0.15em"}}/>

                                              :
                                              <IconButton
                                                onClick={() => this.onRevertGroupClick(group.groupId, groupIndex)}
                                                iconType={"revert"} toolTipTitle={"Revert"}
                                                variant="outline-primary" styles={{margin: "0.15em"}}/>
                                          }
                                        </div>
                                      </Col>

                                      {
                                        group.isNewGroupLine
                                          ?
                                          <Col lg={4} className={"mb-3"}>
                                            <Form.Group controlId="projectGroup">
                                              <Form.Label className={"d-lg-none d-block"}><b>Group</b></Form.Label>
                                              <Form.Control as="select" value={group.selectedGroupId}
                                                            autoFocus={true}
                                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                              this.onChangeProjectGroup(e, groupIndex)}>
                                                <option value={DefaultGroupId}>-- Please select --</option>
                                                {
                                                  this.state.availableGroups.map((g, key) => {
                                                    return <option key={key} value={g.id}>{g.name}</option>;
                                                  })
                                                }
                                              </Form.Control>
                                              <ValidationMessages fieldName="GroupId" errors={group.errors}/>
                                            </Form.Group>
                                          </Col>
                                          :
                                          <Col lg={4} className="text-break">
                                          <span className="d-inline-block d-lg-none"><b>Group: </b>
                                            <span>{group.groupName}</span> <br/>
                                          </span>
                                            <span className="d-none d-lg-block text-break">
                                            <span>{group.groupName}</span>
                                          </span>
                                          </Col>
                                      }

                                      <Col lg={6}>
                                        <Form.Group controlId="groupPermissions">
                                          <b className={"d-lg-none d-block"}>{Title2}: </b>
                                          {
                                            group.groupPermissions.map((permission, p) => {
                                              return (
                                                <div key={p}>
                                                  <Form.Check id={`permission_${groupIndex}_${p}`}
                                                              type="checkbox"
                                                              label={permission.name}
                                                              name={permission.name}
                                                              checked={permission.isSelected}
                                                              onChange={() => this.onPermissionChange(permission.code, group.selectedGroupId, groupIndex)}
                                                  />
                                                </div>
                                              );
                                            })
                                          }
                                          <ValidationMessages fieldName="PermissionCodes" errors={group.errors}/>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                    :
                                    <>
                                      <Row>
                                        <Col lg={2} style={{maxWidth: "8rem"}}>
                                          <div className="mb-lg-0 mb-2">
                                            <IconButton onClick={() => this.onGroupEditClick(groupIndex)}
                                                        iconType={"edit"}
                                                        variant={"outline-primary"}
                                                        styles={{margin: "0.15em"}}
                                                        toolTipTitle={"Edit"}
                                            />

                                            <IconButton
                                              onClick={() => this.onDeleteGroupClick(group.groupId, groupIndex)}
                                              iconType={"remove"} variant={"outline-primary"}
                                              styles={{margin: "0.15em"}} toolTipTitle={"Delete"}/>
                                          </div>
                                        </Col>

                                        <Col lg={4} className="text-break">
                                          <span className="d-inline-block d-lg-none"><b>Group: </b>
                                            <span>{group.groupName}</span> <br/>
                                          </span>
                                          <span className="d-none d-lg-block text-break">
                                            <span>{group.groupName}</span>
                                          </span>
                                        </Col>

                                        <Col lg={6} className="text-break">
                                          <div className="d-inline-block d-lg-none"><b>{Title2}: </b>
                                            {
                                              group.groupPermissions.map((item, gp) => {
                                                return (<div key={gp}>{item.isSelected ? item.name : null}</div>);
                                              })
                                            }
                                          </div>
                                          <div className="d-none d-lg-block text-break">
                                            {
                                              group.groupPermissions.map((item, pc) => {
                                                return (<div key={pc}>{item.isSelected ? item.name : null}</div>);
                                              })
                                            }
                                          </div>
                                        </Col>
                                      </Row>
                                    </>
                                }
                                <hr className={"my-2"}/>
                              </div>
                            );
                          })
                        }
                      </>
                    }
                  </>
                }
              </>
          }
        </ContainerPageSize>
      </>
    );
  }

  async componentDidMount() {
    let projectId = parseInt(this.props.projectId as string);

    if (projectId) {
      this.setState({projectId: projectId});
    }

    try {
      let token = AuthHelper.getAccessToken() ?? '';

      let response = await Api.getProjectSecurity(token, projectId);

      this.setState({
        projectFullName: response.projectFullName,
        projectGroups: this.mapProjectGroups(response.data.projectGroups),
        projectGroupsForRevert: this.mapProjectGroups(response.data.projectGroups),
        availableGroups: response.data.availableGroups,
        allPermissions: response.allPermissions,
        isLoading: false
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get project security from the server', err)
      );
    }
  }

  private mapProjectGroups(groups: IProjectSecurityGroupWithPermissionsInfo[]) {
    let newGroups: IProjectSecurityGroupWithPermissionsInfo[] = [];
    groups.forEach(x => {
      const groupPermissionsCopy = x.groupPermissions.map(object => ({...object}));

      newGroups.push({
        groupName: x.groupName,
        groupId: x.groupId,
        selectedGroupId: x.groupId,
        groupPermissions: groupPermissionsCopy,
        isNewGroupLine: x.isNewGroupLine ?? false,
        isEditMode: x.isEditMode ?? false,
        errors: {}
      });
    });

    return newGroups;
  }

  private onAddPermissionsClick() {
    let state = {...this.state};
    state.projectGroups.push({
      groupName: "",
      groupId: DefaultGroupId,
      selectedGroupId: DefaultGroupId,
      groupPermissions: this.getGroupPermissions(),
      isNewGroupLine: true,
      isEditMode: true,
      errors: {}
    });

    this.setState(state);
  }

  private getGroupPermissions() {
    let groupPermissions: IPermissionAvailabilityInfo[] = [];

    this.state.allPermissions.forEach(x => {
      groupPermissions.push({
        code: x.code,
        name: x.name,
        isSelected: false
      });
    });

    return groupPermissions;
  }

  private onGroupEditClick(index: number) {
    let groups = [...this.state.projectGroups];
    const groupPermissionsCopy = groups[index].groupPermissions.map(object => ({...object}));

    groups[index] = {...groups[index], isEditMode: true, groupPermissions: groupPermissionsCopy};
    this.setState({projectGroups: groups});
  }

  private onChangeProjectGroup(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    let groupId = +event.target.value;

    let state = {...this.state};
    let projectGroups = [...this.state.projectGroups];
    let matchingGroups = state.projectGroups.filter(x => x.groupId === groupId);
    if (matchingGroups !== undefined && matchingGroups !== null && matchingGroups.length > 0) {
      let group = matchingGroups[0];

      projectGroups[index] = {
        ...projectGroups[index],
        selectedGroupId: group.groupId,
        groupId: group.groupId,
        errors: {}
      };
      this.setState({projectGroups: projectGroups});
    } else {
      projectGroups[index] = {
        ...projectGroups[index],
        selectedGroupId: groupId,
        errors: {}
      };
      this.setState({projectGroups: projectGroups});
    }
  }

  private onRevertGroupClick(groupId: number, index: number) {
    let state = {...this.state};
    let groups = [...this.state.projectGroups];

    let matchingGroup = state.projectGroupsForRevert?.filter(x => x.groupId === groupId);
    if (matchingGroup !== undefined && matchingGroup !== null && matchingGroup.length > 0) {
      let group = matchingGroup[0];

      groups[index] = {
        ...groups[index],
        isEditMode: false,
        groupPermissions: group.groupPermissions
      };
      this.setState({projectGroups: groups});
    }
  }

  private onDeleteGroupClick(groupId: number, index: number) {
    let state = {...this.state};
    let projectGroup = state.projectGroups.filter(x => x.groupId === groupId);
    if (projectGroup !== undefined && projectGroup !== null && projectGroup.length > 0) {
      if (groupId !== DefaultGroupId) {
        let group = projectGroup[0];
        state.groupIdToDelete = groupId;
        state.groupIndexToDelete = index;
        state.groupNameToDelete = group.groupName;
        state.isDeleteDialogOpen = true;
        this.setState(state);
      } else {
        if (projectGroup[0].isNewGroupLine) {
          let indexToDelete = index;
          if (indexToDelete !== undefined) {
            let projectGroups = [...this.state.projectGroups];
            projectGroups.splice(indexToDelete, 1);
            this.setState({projectGroups: projectGroups});
          }
        }
      }
    }
  }

  private async onConfirmDeleteGroupClicked(groupId: number) {
    let projectGroup = this.state.projectGroups.filter(x => x.groupId === groupId);
    if (projectGroup !== undefined && projectGroup !== null && projectGroup.length > 0) {
      if (!projectGroup[0].isNewGroupLine) {
        this.setState({isLoading: true});

        let token = AuthHelper.getAccessToken() ?? '';
        let request: IAddUserGroupRequest = {groupId: groupId, permissionCodes: []};

        try {
          let response = await Api.addOrUpdateUserGroup(token, this.state.projectId ?? 0, request);

          let updatedGroups = this.updateGroupsFromServer(
            this.mapProjectGroups(response.projectGroups), groupId, true);

          this.setState({
            availableGroups: response.availableGroups,
            projectGroups: updatedGroups,
            isLoading: false
          });
        } catch (err) {
          this.setValidationErrors(
            Validations.buildApiCommunicationErrors('Can\'t delete project security group on the server', err)
          );

          this.closeDeleteGroupDialog();
          return;
        }
      }
    }
    this.closeDeleteGroupDialog();
  }

  private onCancelDeleteGroupClicked() {
    this.closeDeleteGroupDialog();
  }

  private closeDeleteGroupDialog() {
    let state = {...this.state};
    state.groupIdToDelete = undefined;
    state.groupNameToDelete = undefined;
    state.isDeleteDialogOpen = false;
    this.setState(state);
  }

  private async onSaveGroupClick(selectedGroupId: number, groupId: number, groupIndex: number) {
    if (selectedGroupId === DefaultGroupId) {
      let groups = [...this.state.projectGroups];
      let matchingGroup = groups.filter(x => x.groupId === groupId);

      if (matchingGroup !== undefined && matchingGroup !== null && matchingGroup.length > 0) {
        groups[groupIndex] = {...groups[groupIndex], errors: {'GroupId': ['Please select group']}};
        this.setState({projectGroups: groups});
      }
      return;
    }

    this.setState({isLoading: true});

    let state = {...this.state};
    let matchingGroup = state.projectGroups.filter(x => x.selectedGroupId === selectedGroupId);
    if (matchingGroup !== undefined && matchingGroup !== null && matchingGroup.length > 0) {
      let group = matchingGroup[0];
      let token = AuthHelper.getAccessToken() ?? '';
      let permissionCodes = this.convertToPermissionCodes(group.groupPermissions);

      let request: IAddUserGroupRequest = {groupId: selectedGroupId, permissionCodes: permissionCodes};

      try {
        let response = await Api.addOrUpdateUserGroup(token, this.state.projectId ?? 0, request);
        const updatedGroups = this.updateGroupsFromServer(
          this.mapProjectGroups(response.projectGroups), selectedGroupId, true);

        this.setState({
          availableGroups: response.availableGroups,
          projectGroups: updatedGroups,
          projectGroupsForRevert: this.mapProjectGroups(response.projectGroups),
          isLoading: false
        });
      } catch (err) {
        this.setValidationErrors(Validations.buildApiCommunicationErrors('Can\'t create or update user group', err));
        this.setGroupErrors(selectedGroupId);
      }
    }
  }

  private convertToPermissionCodes(groupPermissions: IPermissionAvailabilityInfo[]) {
    let permissionCodes: string[] = [];
    groupPermissions.forEach(x => {
      if (x.isSelected) permissionCodes.push(x.code);
    });
    return permissionCodes;
  }

  private setGroupErrors(groupId: number) {
    const groups = [...this.state.projectGroups];

    groups.map(x => {
      if (x.groupId === groupId) {
        return x.errors = this.state.validationErrors;
      } else {
        return x;
      }
    });

    this.setState({projectGroups: groups});
  }

  private onPermissionChange(code: string, selectedGroupId: number, groupIndex: number) {
    if (selectedGroupId > 0) {
      let projectGroups = [...this.state.projectGroups];
      let matchingGroup = projectGroups.filter(x => x.selectedGroupId === selectedGroupId);
      if (matchingGroup !== undefined && matchingGroup !== null && matchingGroup.length > 0) {
        let group = matchingGroup[0];

        let permission = group.groupPermissions.find(x => x.code === code);
        if (permission) {
          permission.isSelected = !(permission.isSelected);
        }

        projectGroups[groupIndex] = {...projectGroups[groupIndex], groupPermissions: group.groupPermissions};
        this.setState({projectGroups: projectGroups});
      }
    } else {
      let groups = [...this.state.projectGroups];
      let matchingGroup = groups.filter(x => x.selectedGroupId === selectedGroupId);

      if (matchingGroup !== undefined && matchingGroup !== null && matchingGroup.length > 0) {
        groups[groupIndex] = {...groups[groupIndex], errors: {'GroupId': ['Please select group']}};
        this.setState({projectGroups: groups});
      }
    }
  }

  private updateGroupsFromServer(response: IProjectSecurityGroupWithPermissionsInfo[], groupId: number, disableEditMode?: boolean) {
    let state = {...this.state};
    let newProjectGroups: IProjectSecurityGroupWithPermissionsInfo[] = [];

    response.forEach(group => {
      let matchingGroup = state.projectGroups.filter(x => x.selectedGroupId === group.selectedGroupId);

      if (matchingGroup.length > 0) {
        let item = matchingGroup[0];

        if (matchingGroup && !item?.isEditMode) {
          newProjectGroups.push(group);
        }

        if (matchingGroup && item.isEditMode && !item?.isNewGroupLine) {
          if (disableEditMode && item.groupId === groupId) {
            matchingGroup[0].isEditMode = false;
          }

          newProjectGroups.push(matchingGroup[0]);
        }

        if (matchingGroup && item.isEditMode && item.isNewGroupLine) {
          if (disableEditMode) {
            matchingGroup[0].isEditMode = false;
            matchingGroup[0].isNewGroupLine = false;
            matchingGroup[0].groupName = group.groupName;
            matchingGroup[0].groupId = group.groupId;
            matchingGroup[0].groupPermissions = group.groupPermissions;
          }

          newProjectGroups.push(matchingGroup[0]);
        }
      }

      if (!matchingGroup) {
        newProjectGroups.push(group);
      }
    });

    state.projectGroups.forEach(group => {
      if (group.isNewGroupLine) {
        newProjectGroups.push(group);
      }
    });

    return newProjectGroups;
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }
}

export default withEngineCompartmentAuthorize(ProjectSecurityPage);