import React, { Component } from 'react';
import { VideoTag } from "react-video-tag";
import withEngineCompartmentAuthorize from "../../authorize/withEngineCompartmentAuthorize";
import styles from "./Footer.module.scss";
import { IFooterProps } from "./IFooterProps";

class Footer extends Component<IFooterProps> {
  render() {
    return (
      <div className={styles.footerContainer}>
        <div className={styles.videoContainer}>
          <VideoTag className={styles.video}
                    src={'/video/gears_video.mp4'}
                    type='video/mp4'
                    muted={true}
                    autoPlay={true}
                    playsInline={true}
                    loop={true}
          />
        </div>
      </div>

    );
  }
}

export default withEngineCompartmentAuthorize(Footer);
