import moment from "moment";
import React, { Component } from 'react';
import { Col, Row } from "react-bootstrap";
import Api from "../../../../tools/api/api";
import { IEventSourceWithCounterNamesInfo } from "../../../../tools/api/api-interfaces/common/IEventSourceWithCounterNamesInfo";
import { ISourceFilter } from "../../../../tools/api/api-interfaces/common/ISourceFilter";
import { IFilterDataRequest } from "../../../../tools/api/api-interfaces/projects/telemetry/performance/IFilterDataRequest";
import AuthHelper from "../../../../tools/auth/AuthHelper";
import ValidationErrors from "../../../../tools/validation-helper/ValidationErrors";
import Validations from "../../../../tools/validation-helper/Validations";
import withEngineCompartmentAuthorize from "../../../common/authorize/withEngineCompartmentAuthorize";
import ContainerPageSize from "../../../common/layout-components/container-page-size/ContainerPageSize";
import { LoadingBarHeight } from "../../../common/layout-components/loading-bar/ILoadingBarProps";
import LoadingBar from "../../../common/layout-components/loading-bar/LoadingBar";
import SystemSubMenu from "../../../common/layout-components/system-sub-menu/SystemSubMenu";
import { VerticalSpaceSize } from "../../../common/layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../common/layout-components/vertical-space/VerticalSpace";
import PerformanceComponent from "../../../common/telemetry-components/performance-component/PerformanceComponent";
import ValidationSummary from "../../../common/validation-summary/ValidationSummary";
import { ISystemPerformancePageProps } from "./ISystemPerformancePageProps";
import { ISystemPerformancePageState } from "./ISystemPerformancePageState";

class SystemPerformancePage extends Component<ISystemPerformancePageProps, ISystemPerformancePageState> {

  constructor(props: ISystemPerformancePageProps) {
    super(props);

    this.state = {
      from: moment().toDate(),
      to: moment().toDate(),
      allSourcesAndCounters: [],
      counters: [],
      chart: null,
      selectedSources: [],
      isLoading: true,
      validationErrors: {}
    };
  }

  render() {
    return (
      <>
        <SystemSubMenu/>

        <VerticalSpace size={VerticalSpaceSize.small}/>

        <ContainerPageSize>
          <VerticalSpace size={VerticalSpaceSize.small}/>
          {
            this.state.isLoading ? <LoadingBar size={LoadingBarHeight.normal}/> :
              <>
                {
                  <>
                    <h1 className="text-break">Engine compartment performance counters</h1>

                    <Row>
                      <Col md={{span: 6, offset: 3}}>
                        <ValidationSummary errors={this.state.validationErrors} excludeKeys={["From", "To",]}/>
                      </Col>
                    </Row>

                    <PerformanceComponent from={this.state.from}
                                          to={this.state.to}
                                          allSourcesAndCounters={this.state.allSourcesAndCounters}
                                          counters={this.state.counters}
                                          chart={this.state.chart}
                                          onCounterChangeClick={(counterName, sourceName) =>
                                            this.onCounterChange(counterName, sourceName)}
                                          onFromDateClick={(date: Date) => this.setFromDate(date)}
                                          onToDateClick={(date: Date) => this.setToDate(date)}
                                          onShowClick={(sortingOrder: string) => this.onShowClick(sortingOrder)}
                                          validationErrors={this.state.validationErrors}
                    />
                  </>
                }
              </>
          }
        </ContainerPageSize>
      </>
    );
  }

  async componentDidMount() {

    let token = AuthHelper.getAccessToken() ?? '';

    try {
      const response = await Api.getSystemPerformanceData(token);

      let state = {...this.state};
      state.from = moment(response.fromDate).toDate();
      state.to = moment(response.toDate).toDate();
      state.allSourcesAndCounters = response.data.allSourcesAndCounters;
      state.counters = response.data.counters;
      state.chart = response.data.chart;
      state.selectedSources = this.mapSelectedSources(response.data.allSourcesAndCounters);
      state.isLoading = false;
      this.setState(state);
    } catch (e) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get system performance data from the server', e)
      );
    }
  }

  private async onShowClick(sortingOrder: string) {
    this.setState({isLoading: true});

    let token = AuthHelper.getAccessToken() ?? '';

    if (!this.state.from || !Date.parse(this.state.from.toISOString() ?? "")) {
      this.addValidationError('From', 'Please select from date');
      return;
    }

    if (!this.state.to || !Date.parse(this.state.to.toISOString() ?? "")) {
      this.addValidationError('To', 'Please select to date');
      return;
    }

    let request: IFilterDataRequest = {
      from: moment(this.state.from).format("YYYY-MM-DDTHH:mm:ss"),
      to: moment(this.state.to).format("YYYY-MM-DDTHH:mm:ss"),
      sortingOrder: sortingOrder,
      selectedSources: this.state.selectedSources
    };

    try {
      let response = await Api.filterSystemPerformanceData(token, request);

      this.setState({
        allSourcesAndCounters: response.allSourcesAndCounters,
        counters: response.counters,
        chart: response.chart,
        selectedSources: this.mapSelectedSources(response.allSourcesAndCounters),
        isLoading: false
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get system performance data with other dates', err)
      );
    }
  }

  private setFromDate(date: Date) {
    let state = {...this.state};
    state.from = date;
    state.validationErrors = {};
    this.setState(state);
  }

  private setToDate(date: Date) {
    let state = {...this.state};
    state.to = date;
    state.validationErrors = {};
    this.setState(state);
  }

  private onCounterChange(counterName: string, sourceName: string) {
    let allSourcesAndCounters = this.state.allSourcesAndCounters;
    allSourcesAndCounters.forEach(s => {
      if (s.sourceName === sourceName) {
        s.counters.forEach(c => {
          if (c.name === counterName) {
            c.isSelected = !c.isSelected;
          }
        });
      }
    });

    this.setState({
      allSourcesAndCounters: allSourcesAndCounters,
      selectedSources: this.mapSelectedSources(allSourcesAndCounters)
    });
  }

  private mapSelectedSources(allSourcesAndCounters: IEventSourceWithCounterNamesInfo[]) {
    let selectedSources: ISourceFilter[] = [];
    allSourcesAndCounters.forEach(x => {
      if (x.counters.filter(c => c.isSelected).map(x => x.name).length > 0) {
        selectedSources.push({
          sourceName: x.sourceName,
          selectedCounters: x.counters.filter(c => c.isSelected).map(x => x.name)
        });
      }
    });
    return selectedSources;
  }

  private addValidationError(key: string, message: string) {
    let state = {...this.state};
    state.validationErrors = {};
    Validations.addError(state.validationErrors, key, message);
    this.setState(state);
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }
}

export default withEngineCompartmentAuthorize(SystemPerformancePage);