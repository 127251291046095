import moment from "moment";
import React, { Component } from "react";
import { Col, Form, Row } from "react-bootstrap";
import styles from "../../../pages/projects/telemetry/telemetry-page/TelemetryPage.module.scss";
import IconButton from "../../buttons/icon-button/IconButton";
import HeartbeatsChart from "../../chart-components/heartbeats-chart/HeartbeatsChart";
import DateTimePickerComponent from "../../date-time-picker-component/DateTimePickerComponent";
import { VerticalSpaceSize } from "../../layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../layout-components/vertical-space/VerticalSpace";
import ValidationMessages from "../../validation-messages/ValidationMessages";
import { IHeartbeatsComponentProps } from "./IHeartbeatsComponentProps";

export default class HeartbeatsComponent extends Component<IHeartbeatsComponentProps> {

  constructor(props: Readonly<IHeartbeatsComponentProps>) {
    super(props);

    this.setFromDate = this.setFromDate.bind(this);
    this.setToDate = this.setToDate.bind(this);
    this.onViewClick = this.onViewClick.bind(this);
  }

  render() {
    return (
      <>
        <Row>
          <Col md={"4"} sm={"12"} className={"my-2"}>
            <Form.Group controlId="fromDate">
              <Form.Label className={styles.label}>From</Form.Label><br/>
              <DateTimePickerComponent datePickerId={"fromDate"}
                                       selected={this.props.from}
                                       onChange={(date: Date) => this.setFromDate(date)}/>
              <ValidationMessages fieldName="From" errors={this.props.validationErrors ?? {}}/>
            </Form.Group>
          </Col>
          <Col md={"4"} sm={"12"} className={"my-2"}>
            <Form.Label className={styles.label}>To</Form.Label><br/>
            <DateTimePickerComponent datePickerId={"toDate"}
                                     selected={this.props.to}
                                     onChange={(date: Date) => this.setToDate(date)}/>
            <ValidationMessages fieldName="To" errors={this.props.validationErrors ?? {}}/>
          </Col>
        </Row>

        <VerticalSpace size={VerticalSpaceSize.small}/>
        <IconButton buttonType={"button"} onClick={this.onViewClick} variant="outline-primary" title="Search"/>
        <VerticalSpace size={VerticalSpaceSize.small}/>

        <Row>
          <Col md={"12"}>
            <HeartbeatsChart chartData={this.props.chart ?? {dates: [], lines: []}}/>
          </Col>
        </Row>

        <VerticalSpace size={VerticalSpaceSize.normal}/>

        {
          this.props.heartbeats && this.props.heartbeats.length > 0 &&
          <>
              <div className="d-none d-md-block">
                  <Row>
                      <Col md={2}><b>Timestamp</b></Col>
                      <Col md={2}><b>Duration</b></Col>
                      <Col md={7}><b>Error message</b></Col>
                  </Row>
                  <hr className="my-2" style={{opacity: 1}}/>
              </div>

              <div className="text-break">
                {
                  this.props.heartbeats.map((h, index) => {
                    return (
                      <div key={index}>
                        <div>
                          <Row>
                            <Col md={2}>
                              <span className="d-inline-block d-md-none"><b>Timestamp: </b>
                                <span>
                                  {moment(h.timestamp).format('MM/DD/YYYY')} {moment(h.timestamp).format('h:mm:ss.SSS A')}
                                </span> <br/>
                              </span>
                              <span className="d-none d-md-block">
                                <span>
                                  {moment(h.timestamp).format('MM/DD/YYYY')} {moment(h.timestamp).format('h:mm:ss.SSS A')}
                                </span>
                              </span>
                            </Col>
                            <Col md={2}>
                              <span className="d-inline-block d-md-none"><b>Durtaion: </b>
                                <span>{h.duration}</span> <br/>
                              </span>
                              <span className="d-none d-md-block">
                                <span>{h.duration}</span>
                              </span>
                            </Col>
                            <Col md={7}>
                              <span className="d-inline-block d-md-none"><b>Error message: </b>
                                <span>{h.errorMessage}</span> <br/>
                              </span>
                              <span className="d-none d-md-block">
                                <span>{h.errorMessage}</span>
                              </span>
                            </Col>
                          </Row>
                          <hr className="my-2"/>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
          </>
        }
      </>
    );
  }

  private setFromDate(date: Date) {
    if (this.props.onFromDateClick !== undefined) {
      this.props.onFromDateClick(date);
    }
  }

  private setToDate(date: Date) {
    if (this.props.onToDateClick !== undefined) {
      this.props.onToDateClick(date);
    }
  }

  private onViewClick() {
    if (this.props.onViewClick !== undefined) {
      this.props.onViewClick();
    }
  }
}
