import React, { Component } from "react";
import { Alert, Col, Nav, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Redirect } from "react-router-dom";
import Routes from "../../../../data/Routes";
import Api from "../../../../tools/api/api";
import { IMoveGroupRequest } from "../../../../tools/api/api-interfaces/projects/manage/IMoveGroupRequest";
import { IMoveProjectRequest } from "../../../../tools/api/api-interfaces/projects/manage/IMoveProjectRequest";
import AuthHelper from "../../../../tools/auth/AuthHelper";
import ValidationErrors from "../../../../tools/validation-helper/ValidationErrors";
import Validations from "../../../../tools/validation-helper/Validations";
import withEngineCompartmentAuthorize from "../../../common/authorize/withEngineCompartmentAuthorize";
import ContainerPageSize from "../../../common/layout-components/container-page-size/ContainerPageSize";
import { LoadingBarHeight } from "../../../common/layout-components/loading-bar/ILoadingBarProps";
import LoadingBar from "../../../common/layout-components/loading-bar/LoadingBar";
import { VerticalSpaceSize } from "../../../common/layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../common/layout-components/vertical-space/VerticalSpace";
import ValidationSummary from "../../../common/validation-summary/ValidationSummary";
import GroupsTree from "./groups-tree/GroupsTree";
import { IMoveProjectsPageProps } from "./IMoveProjectsPageProps";
import { IMoveProjectsPageState } from "./IMoveProjectsPageState";

class MoveProjectsPage extends Component<IMoveProjectsPageProps, IMoveProjectsPageState> {
  constructor(props: IMoveProjectsPageProps) {
    super(props);

    this.state = {
      groupIdToRedirect: undefined,
      groupId: undefined,
      projectId: undefined,
      groupFullName: "",
      projectFullName: "",
      groupsTree: null,
      redirect: null,
      isLoading: true,
      validationErrors: null
    };
  }

  render() {
    if (this.state.redirect !== null) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <>
        {
          this.state.isLoading ? <LoadingBar size={LoadingBarHeight.normal}/> :
            <>
              <>
                <VerticalSpace size={VerticalSpaceSize.small}/>
                <ContainerPageSize>
                  <VerticalSpace size={VerticalSpaceSize.small}/>

                  <h1 className={"text-break"}>{this.state.groupFullName || this.state.projectFullName}</h1>

                  <Row>
                    <Col md={{span: "8", offset: "2"}}>
                      <ValidationSummary errors={this.state.validationErrors ?? {}}/>
                    </Col>
                  </Row>
                  {
                    this.state.groupsTree === null
                      ?
                      <>
                        <div>
                          <VerticalSpace size={VerticalSpaceSize.small}/>
                          <Row>
                            <Col md={{span: "6", offset: "3"}}>
                              <Alert variant="info" className={"text-center"}>
                                No project groups found.
                              </Alert>
                            </Col>
                          </Row>
                        </div>
                      </>
                      :
                      <>
                        {
                          this.state.groupFullName &&
                          <div>
                              <VerticalSpace size={VerticalSpaceSize.small}/>
                              <Row>
                                  <Col md={{span: "10", offset: "1"}}>
                                      <Alert variant="info" className={"text-center text-break"}>
                                          Please select a group where <b>{this.state.groupFullName}</b> should be moved to
                                      </Alert>
                                  </Col>
                              </Row>
                          </div>
                        }

                        {
                          this.state.projectFullName &&
                          <div>
                              <VerticalSpace size={VerticalSpaceSize.small}/>
                              <Row>
                                  <Col md={{span: "10", offset: "1"}}>
                                      <Alert variant="info" className={"text-center text-break"}>
                                          Please select a group where project <b>{this.state.projectFullName}</b> should be moved to
                                      </Alert>
                                  </Col>
                              </Row>
                          </div>
                        }

                        <LinkContainer to={"#"} onClick={() => this.onMoveProjectGroupClick()}>
                          <Nav.Link>Root</Nav.Link>
                        </LinkContainer>

                        <GroupsTree groups={this.state.groupsTree}
                                    onMoveGroupClick={groupId => this.onMoveProjectGroupClick(groupId)}/>
                      </>
                  }
                </ContainerPageSize>
              </>
            </>
        }
      </>
    );

  }

  async componentDidMount() {
    const groupIdToRedirect = parseInt(this.props.groupIdToRedirect as string);
    const groupId = parseInt(this.props.groupId as string);
    const projectId = parseInt(this.props.projectId as string);

    if (groupIdToRedirect) {
      this.setState({groupIdToRedirect: groupIdToRedirect});
    }

    if (groupId) {
      this.setState({groupId: groupId});
      await this.getProjectGroupLocations(groupId);
    }

    if (projectId) {
      this.setState({projectId: projectId});
      await this.getProjectLocations(projectId);
    }
  }

  private async getProjectGroupLocations(groupId: number) {
    let token = AuthHelper.getAccessToken() ?? '';

    try {
      let response = await Api.getGroupLocations(token, groupId);

      this.setState({
        groupFullName: response.groupFullName,
        groupsTree: response.rootGroups,
        isLoading: false
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get group locations from the server', err)
      );
    }
  }

  private async getProjectLocations(projectId: number) {
    let token = AuthHelper.getAccessToken() ?? '';

    try {
      let response = await Api.getProjectLocations(token, projectId);

      this.setState({
        projectFullName: response.projectFullName,
        groupsTree: response.allGroups,
        isLoading: false
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get project locations from the server', err)
      );
    }
  }

  private async onMoveProjectGroupClick(targetGroupId?: number) {
    if (this.state.groupId) {
      await this.moveProjectGroup(targetGroupId);
    }

    if (this.state.projectId) {
      await this.moveProject(targetGroupId);
    }
  }

  private async moveProjectGroup(targetGroupId?: number) {
    let token = AuthHelper.getAccessToken() ?? '';
    let request: IMoveGroupRequest = {
      targetGroupId: targetGroupId ?? null
    };

    try {
      await Api.moveProjectGroup(token, this.state.groupId ?? 0, request);
      this.setState({
        redirect: this.state.groupIdToRedirect
          ? Routes.buildProjectGroupUrl(this.state.groupIdToRedirect)
          : Routes.manageProjectsTree
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t move project group on the server', err)
      );
    }
  }

  private async moveProject(targetGroupId?: number) {
    let token = AuthHelper.getAccessToken() ?? '';
    let request: IMoveProjectRequest = {
      targetGroupId: targetGroupId ?? null
    };

    try {
      await Api.moveProject(token, this.state.projectId ?? 0, request);
      this.setState({
        redirect: this.state.groupIdToRedirect
          ? Routes.buildProjectGroupUrl(this.state.groupIdToRedirect)
          : Routes.manageProjectsTree
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t move project on the server', err)
      );
    }
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }
}

export default withEngineCompartmentAuthorize(MoveProjectsPage);