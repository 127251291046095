import React, { Component } from "react";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Routes from "../../../../data/Routes";
import SubMenu from "../sub-menu/SubMenu";
import { IApiKeysSubMenuProps } from "./IApiKeysSubMenuProps";

export default class ApiKeysSubMenu extends Component<IApiKeysSubMenuProps> {
  render() {
    return (
      <>
        <SubMenu>
          <LinkContainer to={Routes.buildProjectApiKeysUrl(this.props.projectId)}>
            <Nav.Link><span className="navItemLink">Project keys</span></Nav.Link>
          </LinkContainer>

          <LinkContainer to={Routes.buildEngineCompartmentApiKeysUrl(this.props.projectId)}>
            <Nav.Link><span className="navItemLink">Engine Compartment keys</span></Nav.Link>
          </LinkContainer>
        </SubMenu>
      </>
    );
  }
}
