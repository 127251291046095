import React, { Component } from 'react';
import { Alert, Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import Api from "../../../../../tools/api/api";
import IActivateECApiKeyRequest
  from "../../../../../tools/api/api-interfaces/projects/api-keys/IActivateECApiKeyRequest";
import IDeactivateECApiKeyRequest
  from "../../../../../tools/api/api-interfaces/projects/api-keys/IDeactivateECApiKeyRequest";
import ISaveApiKeyECRequest from "../../../../../tools/api/api-interfaces/projects/api-keys/ISaveApiKeyECRequest";
import AuthHelper from "../../../../../tools/auth/AuthHelper";
import PermissionHelper from "../../../../../tools/permissions/PermissionHelper";
import ValidationErrors from "../../../../../tools/validation-helper/ValidationErrors";
import Validations from "../../../../../tools/validation-helper/Validations";
import withEngineCompartmentAuthorize from "../../../../common/authorize/withEngineCompartmentAuthorize";
import IconButton from "../../../../common/buttons/icon-button/IconButton";
import ApiKeysSubMenu from "../../../../common/layout-components/api-keys-sub-menu/ApiKeysSubMenu";
import ContainerPageSize from '../../../../common/layout-components/container-page-size/ContainerPageSize';
import { LoadingBarHeight } from "../../../../common/layout-components/loading-bar/ILoadingBarProps";
import LoadingBar from "../../../../common/layout-components/loading-bar/LoadingBar";
import ProjectSubMenu from "../../../../common/layout-components/project-sub-menu/ProjectSubMenu";
import { VerticalSpaceSize } from "../../../../common/layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../../common/layout-components/vertical-space/VerticalSpace";
import ValidationMessages from "../../../../common/validation-messages/ValidationMessages";
import ValidationSummary from "../../../../common/validation-summary/ValidationSummary";
import DeleteEngineCompartmentApiKeyModal
  from "./delete-engine-compartment-api-key-modal/DeleteEngineCompartmentApiKeyModal";
import styles from "./EngineCompartmentApiKeyPage.module.scss";
import IEngineCompartmentApiKeyPageProps from "./IEngineCompartmentApiKeyPageProps";
import IEngineCompartmentApiKeyPageState from "./IEngineCompartmentApiKeyPageState";

class EngineCompartmentApiKeyPage extends Component<IEngineCompartmentApiKeyPageProps, IEngineCompartmentApiKeyPageState> {

  constructor(props: IEngineCompartmentApiKeyPageProps) {
    super(props);

    this.state = {
      projectId: 0,
      projectFullName: "",
      apiKey: null,
      accessKey: "",
      secret: "",
      isActive: false,
      isEditMode: false,
      validationErrors: null,
      isLoading: true,
      isDeleteDialogOpen: false,
      projectIdToDelete: undefined,
      accessKeyToDelete: undefined
    };

    this.onAccessKeyEdit = this.onAccessKeyEdit.bind(this);
    this.onApiKeyRevert = this.onApiKeyRevert.bind(this);
    this.onAccessKeyChange = this.onAccessKeyChange.bind(this);
    this.onSecretChange = this.onSecretChange.bind(this);
    this.onApiKeySave = this.onApiKeySave.bind(this);
    this.onConfirmDeleteApiKeyClicked = this.onConfirmDeleteApiKeyClicked.bind(this);
    this.onCancelDeleteApiKeyClicked = this.onCancelDeleteApiKeyClicked.bind(this);
  }

  render() {
    return (
      <>
        <ProjectSubMenu projectId={this.state.projectId}/>

        <ApiKeysSubMenu projectId={this.state.projectId ?? 0}/>

        <VerticalSpace size={VerticalSpaceSize.small}/>

        <ContainerPageSize>
          <VerticalSpace size={VerticalSpaceSize.small}/>
          <DeleteEngineCompartmentApiKeyModal
            showDialog={this.state.isDeleteDialogOpen}
            projectIdToDelete={this.state.projectIdToDelete}
            accessKeyToDelete={this.state.accessKeyToDelete}
            onConfirm={this.onConfirmDeleteApiKeyClicked}
            onCancel={this.onCancelDeleteApiKeyClicked}
          />
          {
            this.state.isLoading ? <LoadingBar size={LoadingBarHeight.normal}/> :
              <>
                <h1 className={"text-break"}>{this.state.projectFullName}</h1>

                <Row>
                  <Col md={{span: 6, offset: 3}}>
                    <ValidationSummary errors={this.state.validationErrors ?? {}}
                                       excludeKeys={["AccessKey", "Secret"]}/>
                  </Col>
                </Row>

                <div>
                  <div>Key that the project uses to connect to Engine Compartment API is shown below.</div>
                  <VerticalSpace size={VerticalSpaceSize.small}/>
                  <Row>
                    <Col md={"12"}>
                      <Alert variant="info" className={"text-center"}>
                        Please notice that due to caching, any editing to API key may become available
                        in a few minutes after saving until cache expires and new values become
                        propagated.
                      </Alert>
                    </Col>
                  </Row>

                  <div>
                    {
                      this.state.apiKey === null || this.state.isEditMode
                        ?
                        <Row>
                          <Col lg={2}>
                            <Form.Label> </Form.Label>
                            <div className="mb-lg-0 mb-2">
                              <IconButton onClick={this.onApiKeySave} iconType={"submit"} variant={"outline-primary"}
                                          styles={{margin: "0.15em"}} toolTipTitle={"Save"}/>

                              {
                                this.state.apiKey !== null &&
                                <IconButton onClick={this.onApiKeyRevert} iconType={"revert"} toolTipTitle={"Revert"}
                                            variant={"outline-primary"} styles={{margin: "0.15em"}}/>
                              }

                            </div>
                          </Col>

                          <Col lg={3}>
                            <Form.Label className={styles.label}>Access key</Form.Label>
                            <InputGroup className="mb-3">
                              <FormControl
                                type="text"
                                name="accessKey"
                                autoFocus={true}
                                placeholder="Please enter access key"
                                value={this.state.accessKey}
                                onChange={this.onAccessKeyChange}
                              />
                            </InputGroup>
                            <ValidationMessages fieldName="AccessKey" errors={this.state.validationErrors ?? {}}/>
                          </Col>

                          <Col lg={7}>
                            <Form.Label className={styles.label}>Secret</Form.Label>
                            <InputGroup>
                              <InputGroup.Prepend>
                                <IconButton onClick={() => this.onGenerateSecret()} iconType={"repeat"}
                                            variant={"outline-primary"} toolTipTitle={"Generate random secret"}/>
                              </InputGroup.Prepend>
                              <Form.Control
                                type="text"
                                placeholder="Press button to generate secret"
                                value={this.state.secret}
                                onChange={this.onSecretChange}
                              />
                            </InputGroup>
                            <ValidationMessages fieldName="Secret" errors={this.state.validationErrors ?? {}}/>
                          </Col>
                        </Row>
                        :
                        <>
                          <div className="d-none d-lg-block">
                            <Row>
                              {
                                PermissionHelper.doesUserHavePermissionGloballyOrInProject(PermissionHelper.getAllPermissions().PRJ_API_MNG, this.state.projectId ?? 0)
                                  ?
                                  <Col lg={3} style={{maxWidth: "11rem"}}> </Col>
                                  : null
                              }
                              <Col lg={3}><b>Access key</b></Col>
                              <Col lg={6}><b>Secret</b></Col>
                            </Row>
                            <hr className="my-1 mb-3" style={{opacity: 1}}/>
                          </div>

                          <Row>
                            {
                              PermissionHelper.doesUserHavePermissionGloballyOrInProject(PermissionHelper.getAllPermissions().PRJ_API_MNG, this.state.projectId ?? 0)
                                ?
                                <Col lg={3} style={{maxWidth: "11rem"}}>
                                  <div className="mb-lg-0 mb-2">
                                    <IconButton onClick={this.onAccessKeyEdit} iconType={"edit"} toolTipTitle={"Rename"}
                                                variant={"outline-primary"} styles={{margin: "0.15em"}}/>

                                    {this.state.apiKey?.isActive
                                      ? <IconButton onClick={() => this.onDeactivateKey(this.state.projectId)}
                                                    iconType={"active"} variant={"outline-primary"}
                                                    styles={{margin: "0.15em"}} toolTipTitle={"Disable"}/>
                                      : <IconButton onClick={() => this.onActivateKey(this.state.projectId)}
                                                    iconType={"notActive"} variant={"outline-primary"}
                                                    styles={{margin: "0.15em"}} toolTipTitle={"Enable"}/>
                                    }
                                    <IconButton onClick={() => this.onDeleteApiKeyClick(this.state.projectId)}
                                                iconType={"remove"} variant={"outline-primary"}
                                                styles={{margin: "0.15em"}}
                                                toolTipTitle={"Delete"}/>
                                  </div>
                                </Col>
                                : null
                            }

                            <Col lg={3} className="text-break">
                              <span className="d-inline-block d-lg-none"><b>Access key: </b>
                                <span className={!this.state.apiKey.isActive ? styles.disableTextColor : ""}>{this.state.apiKey.accessKey}
                                </span> <br/>
                              </span>
                              <span className="d-none d-lg-block text-break">
                                <span className={!this.state.apiKey.isActive ? styles.disableTextColor : ""}>
                                  {this.state.apiKey.accessKey}
                                </span>
                              </span>
                              {this.state.apiKey.isActive ? <></> : <><b><i> (disabled)</i></b></>}
                            </Col>

                            <Col lg={6}>
                              <span className="d-inline-block d-lg-none text-break"><b>Secret: </b>
                                <span className={!this.state.apiKey.isActive ? styles.disableTextColor : ""} style={{whiteSpace: "pre-wrap"}}>
                                  {this.state.apiKey.secret}
                                </span> <br/>
                              </span>
                              <span className="d-none d-lg-block text-break">
                                <span className={!this.state.apiKey.isActive ? styles.disableTextColor : ""} style={{whiteSpace: "pre-wrap"}}>
                                  {this.state.apiKey.secret}
                                </span>
                              </span>
                            </Col>
                          </Row>
                        </>
                    }
                  </div>
                </div>
              </>
          }
        </ContainerPageSize>
      </>
    );
  }

  async componentDidMount() {
    let projectId = parseInt(this.props.projectId as string);
    this.setState({projectId: projectId});

    await this.getApiKeyForConnectingToEngineCompartment(projectId);
  }

  private async getApiKeyForConnectingToEngineCompartment(projectId: number) {
    let token = AuthHelper.getAccessToken() ?? '';

    try {
      const response = await Api.getApiKeyForConnectingToEngineCompartment(token, projectId);

      let state = {...this.state};
      state.projectFullName = response.projectFullName;
      state.apiKey = response.apiKey;
      state.accessKey = response.apiKey?.accessKey;
      state.secret = response.apiKey?.secret;
      state.isActive = response.apiKey?.isActive;
      state.isLoading = false;
      this.setState(state);
    } catch (e) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get api key for connecting to engine compartment from the server', e)
      );
    }
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }

  private onAccessKeyEdit() {
    let state = {...this.state};
    state.isEditMode = true;
    this.setState(state);
  }

  private onApiKeyRevert() {
    let state = {...this.state};
    state.isEditMode = false;
    state.accessKey = this.state.apiKey?.accessKey ?? "";
    state.secret = this.state.apiKey?.secret ?? "";
    this.setState(state);
  }

  private onAccessKeyChange(e: React.ChangeEvent<HTMLInputElement>) {
    let state = {...this.state};
    state.accessKey = e.target.value;
    state.validationErrors = {};
    this.setState(state);
  }

  private onSecretChange(e: React.ChangeEvent<HTMLInputElement>) {
    let state = {...this.state};
    state.secret = e.target.value;
    state.validationErrors = {};
    this.setState(state);
  }

  private async onGenerateSecret() {
    this.setValidationErrors({});

    let token = AuthHelper.getAccessToken() ?? '';

    try {
      let response = await Api.generateSecretForEngineCompartment(token);

      this.setState({
        secret: response.secret
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t generate secret for engine compartment on the server', err)
      );
    }
  }

  private async onApiKeySave() {
    let token = AuthHelper.getAccessToken() ?? '';
    let request: ISaveApiKeyECRequest = {
      projectId: this.state.projectId,
      accessKey: this.state.accessKey,
      secret: this.state.secret,
    };
    try {
      await Api.saveApiKeyForEngineCompartment(token, request);

      this.setState({
        apiKey: {accessKey: this.state.accessKey, secret: this.state.secret, isActive: true},
        isEditMode: false
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t deactivate engine compartment api key on the server', err)
      );
    }
  }

  private async onActivateKey(projectId: number) {
    let token = AuthHelper.getAccessToken() ?? '';
    let request: IActivateECApiKeyRequest = {projectId: projectId};
    try {
      await Api.activateEngineCompartmentApiKey(token, request);

      await this.getApiKeyForConnectingToEngineCompartment(projectId);
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t deactivate engine compartment api key on the server', err)
      );
    }
  }

  private async onDeactivateKey(projectId: number) {
    let token = AuthHelper.getAccessToken() ?? '';
    let request: IDeactivateECApiKeyRequest = {projectId: projectId};
    try {
      await Api.deactivateEngineCompartmentApiKey(token, request);

      await this.getApiKeyForConnectingToEngineCompartment(projectId);
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t deactivate engine compartment api key on the server', err)
      );
    }
  }

  private async onDeleteApiKeyClick(projectId: number) {
    let state = {...this.state};
    state.projectIdToDelete = projectId;
    state.accessKeyToDelete = this.state.accessKey;
    state.isDeleteDialogOpen = true;
    this.setState(state);
  }

  private async onConfirmDeleteApiKeyClicked(projectId: number) {
    let token = AuthHelper.getAccessToken() ?? '';
    try {
      await Api.deleteEngineCompartmentApiKey(token, projectId);

      await this.getApiKeyForConnectingToEngineCompartment(projectId);
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t delete engine compartment api key on the server', err)
      );
    }

    this.closeRemoveLineDialog();
  }

  private onCancelDeleteApiKeyClicked() {
    this.closeRemoveLineDialog();
  }

  private closeRemoveLineDialog() {
    let state = {...this.state};
    state.projectIdToDelete = undefined;
    state.accessKeyToDelete = undefined;
    state.isDeleteDialogOpen = false;
    this.setState(state);
  }
}

export default withEngineCompartmentAuthorize(EngineCompartmentApiKeyPage);
