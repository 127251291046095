import React, { Component } from 'react';
import { Col, Nav, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Routes from "../../../../../data/Routes";
import Api from "../../../../../tools/api/api";
import AuthHelper from "../../../../../tools/auth/AuthHelper";
import PermissionHelper from "../../../../../tools/permissions/PermissionHelper";
import ValidationErrors from "../../../../../tools/validation-helper/ValidationErrors";
import Validations from "../../../../../tools/validation-helper/Validations";
import withEngineCompartmentAuthorize from "../../../../common/authorize/withEngineCompartmentAuthorize";
import ContainerPageSize from "../../../../common/layout-components/container-page-size/ContainerPageSize";
import { LoadingBarHeight } from "../../../../common/layout-components/loading-bar/ILoadingBarProps";
import LoadingBar from "../../../../common/layout-components/loading-bar/LoadingBar";
import ProjectSubMenu from "../../../../common/layout-components/project-sub-menu/ProjectSubMenu";
import SubMenu from "../../../../common/layout-components/sub-menu/SubMenu";
import { VerticalSpaceSize } from "../../../../common/layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../../common/layout-components/vertical-space/VerticalSpace";
import ValidationSummary from "../../../../common/validation-summary/ValidationSummary";
import { IProjectsInfoPageProps } from "./IProjectsInfoPageProps";
import { IProjectsInfoPageState } from "./IProjectsInfoPageState";
import Item from "./item/Item";

class ProjectsInfoPage extends Component<IProjectsInfoPageProps, IProjectsInfoPageState> {

  constructor(props: IProjectsInfoPageProps) {
    super(props);

    this.state = {
      currentProjectId: null,
      projectInfo: null,
      isLoading: true,
      validationErrors: null
    };
  }

  render() {
    return (
      <>
        <ProjectSubMenu projectId={this.state.currentProjectId ?? 0}/>

        {PermissionHelper.doesUserHavePermissionGloballyOrInProject(PermissionHelper.getAllPermissions().PRJ_INFO_EDIT, this.state.currentProjectId ?? 0)
          ?
          <SubMenu>
            <LinkContainer to={Routes.buildProjectInfoEditUrl(this.state.currentProjectId ?? 0)}>
              <Nav.Link>Edit</Nav.Link>
            </LinkContainer>
          </SubMenu>
          : null
        }
        <VerticalSpace size={VerticalSpaceSize.small}/>

        <ContainerPageSize>
          <VerticalSpace size={VerticalSpaceSize.small}/>
          {
            this.state.isLoading ? <LoadingBar size={LoadingBarHeight.normal}/> :
              <>
                {
                  this.state.validationErrors != null
                    ?
                    <Row>
                      <Col md={{span: 6, offset: 3}}>
                        <ValidationSummary errors={this.state.validationErrors ?? {}}/>
                      </Col>
                    </Row>
                    :
                    <>
                      <h1 className="text-break">{this.state.projectInfo?.fullName}</h1>
                      <VerticalSpace size={VerticalSpaceSize.small}/>

                      <Row>
                        <Col sm="6" md="4">
                          <div className="my-1">
                            <h5>{this.state.projectInfo?.heartbeatsAmountPer24Hours} heartbeats</h5>
                            Within last 24 hours
                          </div>
                        </Col>
                        <Col sm="6" md="4">
                          <div className="my-1">
                            <h5>{this.state.projectInfo?.errorsAmountPer24Hours} errors</h5>
                            Within last 24 hours
                          </div>
                        </Col>
                      </Row>
                      <VerticalSpace size={VerticalSpaceSize.small}/>

                      <div className='pt-3'>
                        {
                          this.state.projectInfo && this.state.projectInfo.info.length > 0 &&
                          <div className="d-none d-lg-block">
                              <Row>
                                  <Col md={4}><b>Name</b></Col>
                                  <Col md={8}><b>Value</b></Col>
                              </Row>
                              <hr className="my-1" style={{opacity: 1}}/>
                          </div>
                        }
                        {
                          this.state.projectInfo && this.state.projectInfo.info.map(info => {
                            return (
                              <Item key={info.id} classNames={"my-3 text-break"}
                                    id={info.id} name={info.name} value={info.value} type={info.type}/>
                            );
                          })
                        }
                      </div>
                    </>
                }
              </>
          }
        </ContainerPageSize>
      </>
    );
  }

  async componentDidMount() {
    let projectId = parseInt(this.props.projectId as string);
    this.setState({currentProjectId: projectId});

    let token = AuthHelper.getAccessToken() ?? '';
    try {
      let response = await Api.getProjectInfo(token, projectId);

      this.setState({
        projectInfo: response,
        isLoading: false
      });
    } catch (err) {
      this.setErrors(
        Validations.buildApiCommunicationErrors('Can\'t get project info from the server', err)
      );
    }
  }

  private setErrors(validationErrors: ValidationErrors | null) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }
}

export default withEngineCompartmentAuthorize(ProjectsInfoPage);