import moment from "moment";
import React, { Component } from 'react';
import { Col, Row } from "react-bootstrap";
import Api from "../../../../../tools/api/api";
import { IViewOtherDatesRequest } from "../../../../../tools/api/api-interfaces/common/IViewOtherDatesRequest";
import AuthHelper from "../../../../../tools/auth/AuthHelper";
import ValidationErrors from "../../../../../tools/validation-helper/ValidationErrors";
import Validations from "../../../../../tools/validation-helper/Validations";
import withEngineCompartmentAuthorize from "../../../../common/authorize/withEngineCompartmentAuthorize";
import ContainerPageSize from "../../../../common/layout-components/container-page-size/ContainerPageSize";
import { LoadingBarHeight } from "../../../../common/layout-components/loading-bar/ILoadingBarProps";
import LoadingBar from "../../../../common/layout-components/loading-bar/LoadingBar";
import ProjectSubMenu from "../../../../common/layout-components/project-sub-menu/ProjectSubMenu";
import TelemetrySubMenu from "../../../../common/layout-components/telemetry-sub-menu/TelemetrySubMenu";
import { VerticalSpaceSize } from "../../../../common/layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../../common/layout-components/vertical-space/VerticalSpace";
import HeartbeatsComponent from "../../../../common/telemetry-components/heartbeats-component/HeartbeatsComponent";
import ValidationSummary from '../../../../common/validation-summary/ValidationSummary';
import { ITelemetryPageProps } from "./ITelemetryPageProps";
import { ITelemetryPageState } from "./ITelemetryPageState";

class TelemetryPage extends Component<ITelemetryPageProps, ITelemetryPageState> {

  constructor(props: ITelemetryPageProps) {
    super(props);

    this.state = {
      projectId: null,
      projectFullName: "",
      from: moment().toDate(),
      to: moment().toDate(),
      heartbeats: [],
      chart: null,
      isLoading: true,
      validationErrors: {}
    };

    this.setFromDate = this.setFromDate.bind(this);
    this.setToDate = this.setToDate.bind(this);
    this.onViewClick = this.onViewClick.bind(this);
  }

  render() {
    return (
      <>
        <ProjectSubMenu projectId={this.state.projectId ?? 0}/>

        <TelemetrySubMenu projectId={this.state.projectId ?? 0}/>

        <VerticalSpace size={VerticalSpaceSize.small}/>

        <ContainerPageSize>
          <VerticalSpace size={VerticalSpaceSize.small}/>
          {
            this.state.isLoading ? <LoadingBar size={LoadingBarHeight.normal}/> :
              <>
                {
                  <>
                    <h1 className="text-break">{this.state.projectFullName}</h1>
                    <VerticalSpace size={VerticalSpaceSize.small}/>
                    <Row>
                      <Col md={{span: 6, offset: 3}}>
                        <ValidationSummary errors={this.state.validationErrors} excludeKeys={["From", "To",]}/>
                      </Col>
                    </Row>

                    <h3>Heartbeats</h3>


                    <HeartbeatsComponent from={this.state.from}
                                         to={this.state.to}
                                         heartbeats={this.state.heartbeats}
                                         chart={this.state.chart}
                                         onFromDateClick={(date: Date) => this.setFromDate(date)}
                                         onToDateClick={(date: Date) => this.setToDate(date)}
                                         onViewClick={() => this.onViewClick()}
                                         validationErrors={this.state.validationErrors}
                    />
                  </>
                }
              </>
          }
        </ContainerPageSize>
      </>
    );
  }

  async componentDidMount() {
    let projectId = parseInt(this.props.projectId as string);
    this.setState({projectId: projectId});

    let token = AuthHelper.getAccessToken() ?? '';

    try {
      const response = await Api.getHeartbeatsData(token, projectId);

      let state = {...this.state};
      state.projectFullName = response.projectFullName;
      state.from = moment(response.fromDate).toDate();
      state.to = moment(response.toDate).toDate();
      state.heartbeats = response.data.heartbeats;
      state.chart = response.data.chart;
      state.isLoading = false;
      this.setState(state);
    } catch (e) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get heartbeats data from the server', e)
      );
    }
  }

  private async onViewClick() {
    this.setState({isLoading: true});

    let token = AuthHelper.getAccessToken() ?? '';

    if (!this.state.from || !Date.parse(this.state.from.toISOString() ?? "")) {
      this.addValidationError('From', 'Please select from date');
      return;
    }

    if (!this.state.to || !Date.parse(this.state.to.toISOString() ?? "")) {
      this.addValidationError('To', 'Please select to date');
      return;
    }

    let request: IViewOtherDatesRequest = {
      from: moment(this.state.from).format("YYYY-MM-DDTHH:mm:ss"),
      to: moment(this.state.to).format("YYYY-MM-DDTHH:mm:ss"),
    };

    let projectId: number = this.state.projectId ?? 0;

    try {
      let response = await Api.viewOtherDates(token, projectId, request);

      this.setState({
        heartbeats: response.heartbeats,
        chart: response.chart,
        isLoading: false
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get heartbeats data with other dates', err)
      );
    }
  }

  private setFromDate(date: Date) {
    let state = {...this.state};
    state.from = date;
    state.validationErrors = {};
    this.setState(state);
  }

  private setToDate(date: Date) {
    let state = {...this.state};
    state.to = date;
    state.validationErrors = {};
    this.setState(state);
  }

  private addValidationError(key: string, message: string) {
    let state = {...this.state};
    state.validationErrors = {};
    Validations.addError(state.validationErrors, key, message);
    this.setState(state);
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }
}

export default withEngineCompartmentAuthorize(TelemetryPage);