export default class Routes {
  public static home: string = '/';
  public static projects: string = '/projects';
  public static manageProjectsTree: string = '/manage-projects-tree';
  public static moveProjectGroup: string = '/project/group/:groupId/move/:groupIdToRedirect';
  public static moveProject: string = '/project/:projectId/move/:groupIdToRedirect';
  public static projectGroup: string = '/projects-tree/group/:groupId';
  public static projectInfo: string = '/project/:projectId/info';
  public static editProjectInfo: string = '/project/:projectId/info/edit';
  public static apiKeysHome: string = '/project/:projectId/api-keys/home';
  public static projectApiKeys: string = '/project/:projectId/api-keys/project-keys';
  public static createProjectApiKey: string = '/project/:projectId/api-keys/project-key/create';
  public static editProjectApiKey: string = '/project/:projectId/api-keys/project-key/edit/:apiKeyId';
  public static engineCompartmentApiKey: string = '/project/:projectId/api-keys/engine-compartment-key';
  public static projectTelemetry: string = '/project/:projectId/telemetry';
  public static projectHeartbeats: string = '/project/:projectId/telemetry/heartbeats';
  public static projectPerformance: string = '/project/:projectId/telemetry/performance';
  public static projectLogs: string = '/project/:projectId/logs';
  public static projectSecurity: string = '/project/:projectId/security';

  public static buildProjectGroupUrl(groupId: number): string {
    return `/projects-tree/group/${groupId}`;
  };

  public static buildProjectGroupMoveUrl(groupId: number, groupIdToRedirect: number): string {
    return `/project/group/${groupId}/move/${groupIdToRedirect}`;
  };

  public static buildProjectMoveUrl(projectId: number, groupIdToRedirect: number): string {
    return `/project/${projectId}/move/${groupIdToRedirect}`;
  };

  public static buildProjectInfoUrl(projectId: number): string {
    return `/project/${projectId}/info`;
  };

  public static buildProjectInfoEditUrl(projectId: number): string {
    return `/project/${projectId}/info/edit`;
  };

  public static buildProjectApiKeysHomeUrl(projectId: number): string {
    return `/project/${projectId}/api-keys/home/`;
  };

  public static buildProjectApiKeysUrl(projectId: number): string {
    return `/project/${projectId}/api-keys/project-keys`;
  };

  public static buildCreateProjectApiKeyUrl(projectId: number): string {
    return `/project/${projectId}/api-keys/project-key/create`;
  };

  public static buildEditProjectApiKeyUrl(projectId: number, apiKeyId: number): string {
    return `/project/${projectId}/api-keys/project-key/edit/` + apiKeyId;
  };

  public static buildEngineCompartmentApiKeysUrl(projectId: number): string {
    return `/project/${projectId}/api-keys/engine-compartment-key`;
  };

  public static buildProjectTelemetryUrl(projectId: number): string {
    return `/project/${projectId}/telemetry`;
  };

  public static buildProjectHeartbeatsUrl(projectId: number): string {
    return `/project/${projectId}/telemetry/heartbeats`;
  };

  public static buildProjectPerformanceUrl(projectId: number): string {
    return `/project/${projectId}/telemetry/performance`;
  };

  public static buildProjectLogsUrl(projectId: number): string {
    return `/project/${projectId}/logs`;
  };

  public static buildProjectSecurityUrl(projectId: number): string {
    return `/project/${projectId}/security`;
  };

  public static users: string = '/users';
  public static createUser: string = '/user/create';
  public static updateUser: string = '/user/update/:userId';

  public static buildUpdateUserUrl(userId: number): string {
    return '/user/update/' + userId;
  };

  public static systemHeartbeats: string = '/system/heartbeats';
  public static systemPerformance: string = '/system/performance';
  public static systemLogs: string = '/system/logs';
  public static systemSettings: string = '/system/settings';
  public static systemSecurity: string = '/system/security-page';
  public static setupSecurityGroup: string = '/security/group/:securityGroupId/setup';

  public static buildSetupSecurityGroupUrl(securityGroupId: number): string {
    return `/security/group/${securityGroupId}/setup`;
  };

  public static profile: string = '/profile';

  public static noPermitted: string = '/no-permitted';
  public static login: string = '/login';
}