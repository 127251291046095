import classNames from "classnames";
import React, { Component } from "react";
import { Alert, Col, Form, Nav, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import IconButton from "../../buttons/icon-button/IconButton";
import PerformanceChart from "../../chart-components/performance-chart/PerformanceChart";
import DateTimePickerComponent from "../../date-time-picker-component/DateTimePickerComponent";
import { VerticalSpaceSize } from "../../layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../layout-components/vertical-space/VerticalSpace";
import ValidationMessages from "../../validation-messages/ValidationMessages";
import { IPerformanceComponentProps } from "./IPerformanceComponentProps";
import styles from './PerformanceComponent.module.scss';

export default class PerformanceComponent extends Component<IPerformanceComponentProps> {

  constructor(props: Readonly<IPerformanceComponentProps>) {
    super(props);

    this.onCounterChangeClick = this.onCounterChangeClick.bind(this);
    this.setFromDate = this.setFromDate.bind(this);
    this.setToDate = this.setToDate.bind(this);
    this.onShowClick = this.onShowClick.bind(this);
  }

  render() {
    return (
      <>
        <Row>
          <Col md={"4"} sm={"12"} className={"my-2"}>
            <Form.Group controlId="fromDate">
              <Form.Label className={styles.label}>From</Form.Label><br/>
              <DateTimePickerComponent datePickerId={"fromDate"} selected={this.props.from}
                                       onChange={(date: Date) => this.setFromDate(date)}/>
              <ValidationMessages fieldName="From" errors={this.props.validationErrors ?? {}}/>
            </Form.Group>
          </Col>
          <Col md={"4"} sm={"12"} className={"my-2"}>
            <Form.Label className={styles.label}>To</Form.Label><br/>
            <DateTimePickerComponent datePickerId={"toDate"} selected={this.props.to}
                                     onChange={(date: Date) => this.setToDate(date)}/>
            <ValidationMessages fieldName="To" errors={this.props.validationErrors ?? {}}/>
          </Col>
        </Row>

        {
          this.props.allSourcesAndCounters && this.props.allSourcesAndCounters.length > 0 &&
          <>
              <Row className="mt-3 text-break">
                {
                  this.props.allSourcesAndCounters.map((source, i) => {
                    return (
                      <Col md={6} key={"source" + i}>
                        <div className={classNames(["overflow-auto", styles.maxHeight15Em])}>
                          <Form.Label className={styles.label}>{source.sourceName}</Form.Label><br/>
                          {
                            source.counters.map((counter, j) => {
                              return (
                                <div key={"counter-" + j}>
                                  <Form.Check id={`counter-${i}-${j}`}
                                              type="checkbox"
                                              className={"mx-1"}
                                              label={counter.name}
                                              name={counter.name}
                                              checked={counter.isSelected}
                                              onChange={() => this.onCounterChangeClick(counter.name, source.sourceName)}
                                  />
                                </div>
                              );
                            })
                          }
                        </div>
                      </Col>
                    );
                  })
                }
              </Row>
          </>
        }

        <VerticalSpace size={VerticalSpaceSize.small}/>
        <IconButton buttonType={"button"} onClick={() => this.onShowClick("Alphabetically")}
                    variant="outline-primary" title="Show"/>

        {
          this.props.counters && this.props.counters.length === 0 &&
          this.props.allSourcesAndCounters && this.props.allSourcesAndCounters.length > 0 &&
          <div>
              <VerticalSpace size={VerticalSpaceSize.small}/>
              <Row>
                  <Col md={{span: "6", offset: "3"}}>
                      <Alert variant="info" className={"text-center"}>
                          Please select at least one counter to see any performance data
                      </Alert>
                  </Col>
              </Row>
          </div>
        }

        {
          this.props.counters && this.props.counters.length === 0 &&
          this.props.allSourcesAndCounters && this.props.allSourcesAndCounters.length === 0 &&
          <div>
              <VerticalSpace size={VerticalSpaceSize.small}/>
              <Row>
                  <Col md={{span: "6", offset: "3"}}>
                      <Alert variant="info" className={"text-center"}>
                          No performance data.
                      </Alert>
                  </Col>
              </Row>
          </div>
        }

        <VerticalSpace size={VerticalSpaceSize.small}/>

        {
          this.props.counters && this.props.counters.length > 0 &&
          <>
            {/* Large screen */}
              <table className="table">
                  <thead>
                  <tr>
                    {/* Only big screens */}
                      <th className="d-none d-lg-table-cell">
                          <LinkContainer to={"#"} className={"p-0"}
                                         onClick={() => this.onShowClick("Alphabetically")}>
                              <Nav.Link><span>Source</span></Nav.Link>
                          </LinkContainer>
                      </th>
                      <th className="d-none d-lg-table-cell">Counter</th>
                      <th className="d-none d-lg-table-cell">
                          <LinkContainer to={"#"} className={"p-0"}
                                         onClick={() => this.onShowClick("Min")}>
                              <Nav.Link><span>Min</span></Nav.Link>
                          </LinkContainer>
                      </th>
                      <th className="d-none d-lg-table-cell">
                          <LinkContainer to={"#"} className={"p-0"}
                                         onClick={() => this.onShowClick("Max")}>
                              <Nav.Link><span>Max</span></Nav.Link>
                          </LinkContainer>
                      </th>
                      <th className="d-none d-lg-table-cell">
                          <LinkContainer to={"#"} className={"p-0"}
                                         onClick={() => this.onShowClick("Avg")}>
                              <Nav.Link><span>Avg</span></Nav.Link>
                          </LinkContainer>
                      </th>
                      <th className="d-none d-lg-table-cell">
                          <LinkContainer to={"#"} className={"p-0"}
                                         onClick={() => this.onShowClick("Sum")}>
                              <Nav.Link><span>Sum</span></Nav.Link>
                          </LinkContainer>
                      </th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    this.props.counters.map((x, i) =>
                      <tr key={i} className="text-break">

                        {/* Only big screens */}
                        <td className="d-none d-lg-table-cell">{x.sourceName}</td>
                        <td className="d-none d-lg-table-cell">{x.counterName}</td>
                        <td className="d-none d-lg-table-cell">{x.min.toFixed(2)}</td>
                        <td className="d-none d-lg-table-cell">{x.max.toFixed(2)}</td>
                        <td className="d-none d-lg-table-cell">{x.avg.toFixed(2)}</td>
                        <td className="d-none d-lg-table-cell">{x.sum.toFixed(2)}</td>

                        {/* Small and medium screens */}
                        <td className="d-table-cell d-lg-none">
                          <span className="d-flex align-items-baseline">
                            <LinkContainer to={"#"} className={"p-0"}
                                           onClick={() => this.onShowClick("Alphabetically")}>
                              <Nav.Link><span>Source: </span></Nav.Link>
                            </LinkContainer> &nbsp;{x.sourceName}<br/>
                          </span>
                          <span className="d-flex align-items-baseline">
                            <b>Counter: </b> &nbsp;{x.counterName} <br/>
                          </span>
                          <span className="d-flex align-items-baseline">
                            <LinkContainer to={"#"} className={"p-0"}
                                           onClick={() => this.onShowClick("Min")}>
                              <Nav.Link><span>Min: </span></Nav.Link>
                            </LinkContainer> &nbsp;{x.min.toFixed(2)}<br/>
                          </span>
                          <span className="d-flex align-items-baseline">
                            <LinkContainer to={"#"} className={"p-0"}
                                           onClick={() => this.onShowClick("Max")}>
                              <Nav.Link><span>Max: </span></Nav.Link>
                            </LinkContainer> &nbsp;{x.max.toFixed(2)}<br/>
                          </span>
                          <span className="d-flex align-items-baseline">
                            <LinkContainer to={"#"} className={"p-0"}
                                           onClick={() => this.onShowClick("Avg")}>
                              <Nav.Link><span>Avg: </span></Nav.Link>
                            </LinkContainer> &nbsp;{x.avg.toFixed(2)}<br/>
                          </span>
                          <span className="d-flex align-items-baseline">
                            <LinkContainer to={"#"} className={"p-0"}
                                           onClick={() => this.onShowClick("Sum")}>
                              <Nav.Link><span>Sum: </span></Nav.Link>
                            </LinkContainer> &nbsp;{x.sum.toFixed(2)}<br/>
                          </span>
                        </td>
                      </tr>
                    )}
                  </tbody>
              </table>

              <VerticalSpace size={VerticalSpaceSize.small}/>

              <Row>
                  <Col md={"12"}>
                      <PerformanceChart chartData={this.props.chart ?? {dates: [], lines: []}}/>
                  </Col>
              </Row>
          </>
        }
      </>
    );
  }

  private onCounterChangeClick(counterName: string, sourceName: string): void {
    if (this.props.onCounterChangeClick !== undefined) {
      this.props.onCounterChangeClick(counterName, sourceName);
    }
  }

  private setFromDate(date: Date) {
    if (this.props.onFromDateClick !== undefined) {
      this.props.onFromDateClick(date);
    }
  }

  private setToDate(date: Date) {
    if (this.props.onToDateClick !== undefined) {
      this.props.onToDateClick(date);
    }
  }

  private onShowClick(sortingOrder: string) {
    if (this.props.onShowClick !== undefined) {
      this.props.onShowClick(sortingOrder);
    }
  }
}
