import React, { Fragment } from "react";
import { IProjectGroup } from "../../../../../tools/api/api-interfaces/projects/IGetHomeDataResponse";
import GroupItem from "../groups/GroupItem";
import { IGroupsWithProjectsTreeProps } from "./IGroupsWithProjectsTreeProps";

const GroupsWithProjectsTree = ({groups: childGroups}: IGroupsWithProjectsTreeProps) => {
  const createTree = (group: IProjectGroup) =>
    group.childGroups && (
      <>
        <GroupItem
          groupId={group.groupId}
          groupName={group.groupName}
          projects={group.projects}
          key={group.groupId}
        >
          {group.childGroups.map((group: IProjectGroup) => {
            return <Fragment key={group.groupId}>{createTree(group)}</Fragment>;
          })}
        </GroupItem>
      </>
    );

  return (
    <div>
      {childGroups.map((group: IProjectGroup, i: any) => (
        <div key={i}>{createTree(group)}</div>
      ))}
    </div>
  );
};

export default GroupsWithProjectsTree;