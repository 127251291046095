import moment from "moment";
import React, { Component } from 'react';
import { Col, Form, Row } from "react-bootstrap";
import { Redirect } from 'react-router-dom';
import Routes from "../../../data/Routes";
import Api from "../../../tools/api/api";
import IGetAuthTokenRequest from "../../../tools/api/api-interfaces/login/IGetAuthTokenRequest";
import IGetAuthTokenResponse from "../../../tools/api/api-interfaces/login/IGetAuthTokenResponse";
import AuthHelper from "../../../tools/auth/AuthHelper";
import ValidationErrors from "../../../tools/validation-helper/ValidationErrors";
import Validations from '../../../tools/validation-helper/Validations';
import withEngineCompartmentAuthorize from "../../common/authorize/withEngineCompartmentAuthorize";
import IconButton from "../../common/buttons/icon-button/IconButton";
import ContainerPageSize from "../../common/layout-components/container-page-size/ContainerPageSize";
import { LoadingBarHeight } from "../../common/layout-components/loading-bar/ILoadingBarProps";
import LoadingBar from "../../common/layout-components/loading-bar/LoadingBar";
import { VerticalSpaceSize } from "../../common/layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../common/layout-components/vertical-space/VerticalSpace";
import ValidationMessages from '../../common/validation-messages/ValidationMessages';
import ValidationSummary from "../../common/validation-summary/ValidationSummary";
import { ILoginPageProps } from './ILoginPageProps';
import { ILoginPageState } from './ILoginPageState';
import "./LoginPage.scss";

class LoginPage extends Component<ILoginPageProps, ILoginPageState> {
  _isMounted = false;

  constructor(props: ILoginPageProps) {
    super(props);

    this.state = {
      email: "",
      password: "",
      validationErrors: {},
      isLoading: true,
      redirect: null
    };

    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onSubmitLogin = this.onSubmitLogin.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.setState({isLoading: false});
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (this.state.redirect !== null) {
      return <Redirect to={this.state.redirect}/>;
    }

    if (this.props.auth.isAuthenticated) {
      return <Redirect to={Routes.projects}/>;
    }

    return (
      <>
        <VerticalSpace size={VerticalSpaceSize.small}/>
        <ContainerPageSize>
          {this.state.isLoading
            ?
            <LoadingBar size={LoadingBarHeight.normal}/>
            :
            <>
              <VerticalSpace size={VerticalSpaceSize.small}/>
              <Row className={"marginTopAndBottom17vh"}>
                <h1 className="text-center">Kiwanis - engine compartment</h1>
                <Col md={{span: 6, offset: 3}}>
                  <ValidationSummary errors={this.state.validationErrors} excludeKeys={['Email', 'Password']}/>
                  <Form onSubmit={this.onSubmitLogin}>
                    <Form.Group controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="Enter your email"
                        aria-describedby="emailHelp"
                        value={this.state.email}
                        onFocus={this.onFocus}
                        onChange={this.onEmailChange}
                      />
                    </Form.Group>

                    <ValidationMessages fieldName="Email" errors={this.state.validationErrors}/>
                    <VerticalSpace size={VerticalSpaceSize.small}/>

                    <Form.Group controlId="password">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        placeholder="Enter your password"
                        value={this.state.password}
                        onFocus={this.onFocus}
                        onChange={this.onPasswordChange}
                      />
                    </Form.Group>

                    <ValidationMessages fieldName="Password" errors={this.state.validationErrors}/>
                    <VerticalSpace size={VerticalSpaceSize.small}/>

                    <IconButton buttonType={"submit"} onClick={() => function() {}} variant="outline-primary"
                                title="Sign in"/>
                  </Form>
                </Col>
              </Row>
            </>
          }
        </ContainerPageSize>
      </>
    );
  }

  private onEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setAllValidationErrors({});

    this.setState({email: e.target.value});
  }

  private onPasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setAllValidationErrors({});

    this.setState({password: e.target.value});
  }

  private onFocus() {
    this.setAllValidationErrors({});
  }

  async onSubmitLogin(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const state: ILoginPageState = {...this.state};
    state.isLoading = true;
    this.setState(state);

    let data: IGetAuthTokenRequest = {
      email: this.state.email,
      password: this.state.password
    };

    try {
      let response: IGetAuthTokenResponse = await Api.getAuthToken(data);

      let expires = moment(response.expiresOn);
      AuthHelper.setAccessToken(response.token, expires.toDate());

      let globalPermissions = response.globalPermissions.toString();
      AuthHelper.setGlobalPermissions(globalPermissions);

      let projectPermissions = JSON.stringify(response.projectPermissions);
      AuthHelper.setProjectPermissions(projectPermissions);

      if (this._isMounted) {
        this.setState({isLoading: false});
      }
    } catch (err) {
      this.setAllValidationErrors(
        Validations.buildApiCommunicationErrors('Get auth token has failed', err)
      );

      return;
    }

    state.redirect = Routes.home;
    if (this._isMounted) {
      this.setState(state);
    }
  }


  private setAllValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }
}

export default withEngineCompartmentAuthorize(LoginPage);