import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Routes from '../../../data/Routes';
import withEngineCompartmentAuthorize from './withEngineCompartmentAuthorize';
import { IAuthorizeProps } from './IAuthorizeProps';

class Authorize extends Component<IAuthorizeProps> {
  render() {
    if (this.props.auth.isAuthenticated) {
      return <React.Fragment>{this.props.children}</React.Fragment>;
    }

    return <Redirect to={Routes.login}/>;
  }
}

export default withEngineCompartmentAuthorize(Authorize);