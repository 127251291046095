import React, { Component } from 'react';
import { ILoadingBarProps, LoadingBarHeight } from './ILoadingBarProps';
import styles from "./LoadingBar.module.scss";
import logo from "./gear-cog-wheel.svg";

export default class LoadingBar extends Component<ILoadingBarProps> {
  render() {
    let height: string;

    switch (this.props.size) {
      case LoadingBarHeight.small:
        height = '1em';
        break;
      default:
      case LoadingBarHeight.normal:
        height = '3em';
        break;
      case LoadingBarHeight.large:
        height = '8em';
        break;
      case LoadingBarHeight.huge:
        height = '20em';
        break;
    }

    return (
      <div className={styles.LoaderContainer}>
        <div className={styles.Loader}>
          <img src={logo} className={styles.LoaderImage} alt="loader" style={{height: height}}/>
        </div>
      </div>
    );
  }
}
