import React from 'react';
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Routes from "../../../data/Routes";
import IconButton from "../../common/buttons/icon-button/IconButton";
import ContainerPageSize from "../../common/layout-components/container-page-size/ContainerPageSize";
import { VerticalSpaceSize } from "../../common/layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../common/layout-components/vertical-space/VerticalSpace";

class NoPermittedPage extends React.Component {
  render() {
    return (
      <>
        <VerticalSpace size={VerticalSpaceSize.small}/>
        <ContainerPageSize>
          <div className="text-center">
            <Row>
              <Col md={{span: 6, offset: 3}} className={"marginTopAndBottom17vh"}>
                <h1>403 - Forbidden</h1>

                <p>You don’t have permission to access this page</p>
                <br/>
                <Link to={Routes.login}>
                  <IconButton onClick={() => function onBackToSite() {
                  }} variant="outline-primary" title="Back to site"/>
                </Link>
              </Col>
            </Row>
          </div>
        </ContainerPageSize>
      </>
    );
  }
}

export default NoPermittedPage;