import classNames from 'classnames';
import React, { Component } from 'react';
import ReactModal from 'react-modal';
import IconButton from "../../../../common/buttons/icon-button/IconButton";
import styles from './DeleteGroupWithPermissionsModal.module.scss';
import { IDeleteGroupWithPermissionsModalProps } from "./IDeleteGroupWithPermissionsModalProps";
import { IDeleteGroupWithPermissionsModalState } from "./IDeleteGroupWithPermissionsModalState";

export default class DeleteGroupWithPermissionsModal extends Component<IDeleteGroupWithPermissionsModalProps, IDeleteGroupWithPermissionsModalState> {

  private static removeDialogIndex = 100;

  constructor(props: IDeleteGroupWithPermissionsModalProps) {
    super(props);

    this.state = {
      dialogId: 'delete_group_dialog_' + (DeleteGroupWithPermissionsModal.removeDialogIndex++)
    };

    this.onConfirmClicked = this.onConfirmClicked.bind(this);
  }

  render() {
    return (
      <ReactModal
        appElement={document.getElementById('root') as HTMLElement}
        isOpen={this.props.showDialog}
        id={this.state.dialogId}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        className={classNames([styles.dialog, "shadow p-4"])}
      >
        <h2>Delete project security group</h2>

        <p>Are you sure you want to delete <b className={"text-break"}>{this.props.groupNameToDelete}</b>?</p>

        <div className="d-flex justify-content-end">
          <IconButton onClick={this.onConfirmClicked} variant="outline-primary" title="Delete" styles={{marginRight: '1em'}}/>
          <IconButton onClick={this.props.onCancel} variant="outline-primary" title="Cancel"/>
        </div>
      </ReactModal>
    );
  }

  onConfirmClicked() {
    if (this.props.onConfirm !== undefined && this.props.onConfirm !== null) {
      this.props.onConfirm(this.props.groupIdToDelete ?? 0);
    }
  }
}
