import React, { Component } from "react";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Routes from "../../../../data/Routes";
import PermissionHelper from "../../../../tools/permissions/PermissionHelper";
import SubMenu from "../sub-menu/SubMenu";
import { IProjectSubMenuProps } from "./IProjectSubMenuProps";

export default class ProjectSubMenu extends Component<IProjectSubMenuProps> {
  render() {
    return (
      <>
        <SubMenu>
          <LinkContainer to={Routes.buildProjectInfoUrl(this.props.projectId)}>
            <Nav.Link><span className="navItemLink">Project info</span></Nav.Link>
          </LinkContainer>

          {
            PermissionHelper.doesUserHavePermissionGloballyOrInProject(PermissionHelper.getAllPermissions().PRJ_API_VIEW, this.props.projectId) ||
            PermissionHelper.doesUserHavePermissionGloballyOrInProject(PermissionHelper.getAllPermissions().PRJ_API_MNG, this.props.projectId)
              ?
              <LinkContainer to={Routes.buildProjectApiKeysHomeUrl(this.props.projectId)}>
                <Nav.Link><span className="navItemLink">API Keys</span></Nav.Link>
              </LinkContainer>
              : null
          }

          <LinkContainer to={Routes.buildProjectTelemetryUrl(this.props.projectId)}>
            <Nav.Link><span className="navItemLink">Telemetry</span></Nav.Link>
          </LinkContainer>

          <LinkContainer to={Routes.buildProjectLogsUrl(this.props.projectId)}>
            <Nav.Link><span className="navItemLink">Logs</span></Nav.Link>
          </LinkContainer>

          {
            PermissionHelper.doesUserHavePermissionGlobally(PermissionHelper.getAllPermissions().SYS_SECURITY)
              ?
              <LinkContainer to={Routes.buildProjectSecurityUrl(this.props.projectId)}>
                <Nav.Link><span className="navItemLink">Project security</span></Nav.Link>
              </LinkContainer>
              : null
          }
        </SubMenu>
      </>
    );
  }
}
