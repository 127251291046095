import React, { Fragment } from "react";
import { IGroup } from "../../../../../tools/api/api-interfaces/projects/manage/IGroup";
import ProjectGroupItem from "../project-group-item/ProjectGroupItem";
import { IGroupsTreeProps } from "./IGroupsTreeProps";

const GroupsTree = ({groups: children, onMoveGroupClick}: IGroupsTreeProps) => {

  const createTree = (group: IGroup) =>
    group.children && (
      <>
        <ProjectGroupItem
          groupId={group.id}
          groupName={group.name}
          key={group.id}
          onMoveGroupClick={groupId => onMoveGroupClick(groupId)}
        >
          {group.children.map((group: IGroup) => {
            return <Fragment key={group.id}>{createTree(group)}</Fragment>;
          })}
        </ProjectGroupItem>
      </>
    );

  return (
    <div>
      {children.map((group: IGroup, i: any) => (
        <div key={i}>{createTree(group)}</div>
      ))}
    </div>
  );
};

export default GroupsTree;