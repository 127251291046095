import React, { Component } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import { IDateTimePickerComponentProps } from "./IDateTimePickerComponentProps";
import en from 'date-fns/locale/en-US';
registerLocale('en', en);

export default class DateTimePickerComponent extends Component<IDateTimePickerComponentProps> {
  render() {
    return <DatePicker id={this.props.datePickerId}
                       selected={this.props.selected}
                       className={"form-control"}
                       dateFormat="MM/dd/yyyy h:mm aa"
                       timeFormat={"h:mm aa"}
                       showTimeInput
                       timeInputLabel="Time:"
                       onChange={this.props.onChange}
                       locale={en}
                       showMonthDropdown
                       showYearDropdown
                       dropdownMode="select"
    />;
  }
}