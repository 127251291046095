import classNames from 'classnames';
import React, { Component } from 'react';
import { Col, Form, Row } from "react-bootstrap";
import IconButton from "../../../../../common/buttons/icon-button/IconButton";
import ValidationMessages from "../../../../../common/validation-messages/ValidationMessages";
import { ILineToEditProps } from "./ILineToEditProps";
import styles from './LineToEdit.module.scss';

export default class LineToEdit extends Component<ILineToEditProps> {
  constructor(props: Readonly<ILineToEditProps>) {
    super(props);

    this.onLineUp = this.onLineUp.bind(this);
    this.onLineDown = this.onLineDown.bind(this);
    this.onLineEdit = this.onLineEdit.bind(this);
    this.onLineDelete = this.onLineDelete.bind(this);
    this.onLineSave = this.onLineSave.bind(this);
    this.onLineRevert = this.onLineRevert.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
  }

  render() {
    const componentClasses = this.props.classNames === '' || this.props.classNames === undefined
      ? ""
      : this.props.classNames;

    return (
      <div className={classNames([componentClasses, styles.item])}>
        {
          this.props.isNewLine || this.props.isEditMode
            ?
            <Row>
              <Col lg={2}>
                <div className="mb-lg-0 mb-2">
                  <div className="d-block d-lg-none">
                    <Form.Label> &nbsp;</Form.Label> <br/>
                  </div>
                  <IconButton onClick={() => this.onLineSave(this.props.id)}
                              iconType={"submit"} toolTipTitle={"Save"}
                              variant={"outline-primary"} styles={{margin: "0.15em"}}/>
                  {
                    this.props.isEditMode
                      ?
                      <IconButton onClick={() => this.onLineRevert(this.props.id)}
                                  iconType={"revert"} toolTipTitle={"Revert"}
                                  variant={"outline-primary"} styles={{margin: "0.15em"}}/>
                      :
                      <IconButton onClick={() => this.onLineDelete(this.props.id)}
                                  iconType={"revert"} toolTipTitle={"Revert"}
                                  variant={"outline-primary"} styles={{margin: "0.15em"}}/>
                  }
                </div>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <div className="d-block d-lg-none">
                    <Form.Label className={styles.label}>Name</Form.Label>
                  </div>
                  <Form.Control
                    type="text"
                    name="name"
                    autoFocus={true}
                    placeholder={"Please enter name"}
                    maxLength={100}
                    value={this.props.name}
                    onChange={this.onNameChange}
                  />
                  <ValidationMessages fieldName="Name" errors={this.props.errors}/>
                </Form.Group>
              </Col>
              <Col lg={7}>
                <Form.Group className="mb-3">
                  <div className="d-block d-lg-none">
                    <Form.Label className={styles.label}>Value</Form.Label>
                  </div>
                  <Form.Control
                    type="text"
                    name="value"
                    placeholder={
                      this.props.type === "URL"
                        ? "Please enter url"
                        : this.props.type === "MULTI_LINE" ? "Please enter multiline text" : "Please enter text"
                    }
                    as={this.props.isMultiLineText ? "textarea" : "input"}
                    value={this.props.value}
                    onChange={this.onValueChange}
                  />
                  <ValidationMessages fieldName="Type" errors={this.props.errors}/>
                </Form.Group>
              </Col>
            </Row>
            :
            <Row>
              <Col lg={2}>
                <div className="mb-lg-0 mb-2">
                  <IconButton onClick={() => this.onLineUp(this.props.id)} iconType={"up"} variant={"outline-primary"}
                              styles={{margin: "0.15em"}} toolTipTitle={"Move up"}/>
                  <IconButton onClick={() => this.onLineDown(this.props.id)} iconType={"down"}
                              variant={"outline-primary"} styles={{margin: "0.15em"}} toolTipTitle={"Move down"}/>
                  <IconButton onClick={() => this.onLineEdit(this.props.id)} iconType={"edit"}
                              variant={"outline-primary"} styles={{margin: "0.15em"}} toolTipTitle={"Edit"}/>
                  <IconButton onClick={() => this.onLineDelete(this.props.id)} iconType={"remove"}
                              variant={"outline-primary"} styles={{margin: "0.15em"}} toolTipTitle={"Remove"}/>
                </div>
              </Col>
              <Col lg={3} className="text-break">
                <b>{this.props.name}</b>
              </Col>
              <Col lg={7}>
                {this.props.type === "URL"
                  ? <a href={this.props.value} target={"_blank"} rel="noreferrer" className="text-break">{this.props.value}</a>
                  : <div style={{whiteSpace: "pre-line"}} className="text-break">{this.props.value}</div>
                }
              </Col>
            </Row>
        }
        <hr className="my-2"/>
      </div>
    );
  }

  private onLineUp(lineId: number) {
    if (this.props.onLineUpClick !== undefined) {
      this.props.onLineUpClick(lineId);
    }
  }

  private onLineDown(lineId: number) {
    if (this.props.onLineDownClick !== undefined) {
      this.props.onLineDownClick(lineId);
    }
  }

  private onLineEdit(lineId: number) {
    if (this.props.onLineEditClick !== undefined) {
      this.props.onLineEditClick(lineId);
    }
  }

  private onLineDelete(lineId: number) {
    if (this.props.onLineDeleteClick !== undefined) {
      this.props.onLineDeleteClick(lineId);
    }
  }

  private onLineSave(lineId: number) {
    if (this.props.onLineSaveClick !== undefined) {
      this.props.onLineSaveClick(lineId);
    }
  }

  private onLineRevert(lineId: number) {
    if (this.props.onLineRevertClick !== undefined) {
      this.props.onLineRevertClick(lineId);
    }
  }

  private onNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (this.props.onLineNameChange !== undefined) {
      this.props.onLineNameChange(e.currentTarget.value);
    }
  }

  private onValueChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (this.props.onLineValueChange !== undefined) {
      this.props.onLineValueChange(e.currentTarget.value);
    }
  }
}
