export enum LoadingBarHeight{
    small,
    normal,
    large,
    huge
}

export interface ILoadingBarProps {
    size: LoadingBarHeight;
}
