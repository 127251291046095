import moment from "moment";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Api from "../../../../tools/api/api";
import IFilterRequest from "../../../../tools/api/api-interfaces/projects/logs/IFilterRequest";
import AuthHelper from "../../../../tools/auth/AuthHelper";
import ValidationErrors from "../../../../tools/validation-helper/ValidationErrors";
import Validations from "../../../../tools/validation-helper/Validations";
import withEngineCompartmentAuthorize from "../../../common/authorize/withEngineCompartmentAuthorize";
import ContainerPageSize from "../../../common/layout-components/container-page-size/ContainerPageSize";
import { LoadingBarHeight } from "../../../common/layout-components/loading-bar/ILoadingBarProps";
import LoadingBar from "../../../common/layout-components/loading-bar/LoadingBar";
import PaginationComponent from "../../../common/layout-components/pagination/PaginationComponent";
import SystemSubMenu from "../../../common/layout-components/system-sub-menu/SystemSubMenu";
import { VerticalSpaceSize } from "../../../common/layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../common/layout-components/vertical-space/VerticalSpace";
import LogsComponent from "../../../common/telemetry-components/logs-component/LogsComponent";
import ValidationSummary from "../../../common/validation-summary/ValidationSummary";
import { ISystemLogsPageProps } from "./ISystemLogsPageProps";
import { ISystemLogsPageState } from "./ISystemLogsPageState";

class SystemLogsPage extends Component<ISystemLogsPageProps, ISystemLogsPageState> {
  constructor(props: ISystemLogsPageProps) {
    super(props);

    this.state = {
      knownLoggers: [],
      knownLevels: [],
      logs: [],
      fromTimestamp: "",
      toTimestamp: "",
      from: moment().toDate(),
      to: moment().toDate(),
      pageIndex: 0,
      totalPages: 0,
      isLoading: true,
      validationErrors: null
    };

    this.onSearchClick = this.onSearchClick.bind(this);
  }


  render() {
    return (
      <>
        {
          this.state.isLoading ? <LoadingBar size={LoadingBarHeight.normal}/> :
            <>
              <SystemSubMenu/>

              <VerticalSpace size={VerticalSpaceSize.small}/>

              <ContainerPageSize>

                <VerticalSpace size={VerticalSpaceSize.small}/>
                <h1 className="text-break">Engine compartment logs</h1>
                <VerticalSpace size={VerticalSpaceSize.small}/>

                <ValidationSummary errors={this.state.validationErrors ?? {}} excludeKeys={["From", "To",]}/>

                <LogsComponent from={this.state.from}
                               to={this.state.to}
                               knownLevels={this.state.knownLevels}
                               knownLoggers={this.state.knownLoggers}
                               logs={this.state.logs}
                               validationErrors={this.state.validationErrors ?? {}}
                               onFromDateClick={(date: Date) => this.setFromDate(date)}
                               onToDateClick={(date: Date) => this.setToDate(date)}
                               onLevelChangeClick={(index: number) => this.onLevelChange(index)}
                               onLoggerChangeClick={(index: number) => this.onLoggerChange(index)}
                               onSearchClick={this.onSearchClick}
                />

                <PaginationComponent pageCount={this.state.totalPages}
                                     currentPage={this.state.pageIndex}
                                     onPageChange={(page) => this.handlePageClick(page)}
                />

              </ContainerPageSize>
            </>
        }
      </>
    );
  }

  async componentDidMount() {
    let token = AuthHelper.getAccessToken() ?? '';

    try {
      let response = await Api.getSystemLogs(token);

      this.setState({
        knownLoggers: response.knownLoggers,
        knownLevels: response.knownLevels,
        logs: response.logs,
        fromTimestamp: response.fromTimestamp,
        toTimestamp: response.toTimestamp,
        from: moment(response.fromTimestamp).toDate(),
        to: moment(response.toTimestamp).toDate(),
        pageIndex: response.pageIndex,
        totalPages: response.totalPages,
        isLoading: false
      });
    } catch (err) {
      this.setValidationErrors(Validations.buildApiCommunicationErrors('Can\'t get system logs', err));
    }
  }

  private setFromDate(date: Date) {
    let state = {...this.state};
    state.from = date;
    state.validationErrors = {};
    this.setState(state);
  }

  private setToDate(date: Date) {
    let state = {...this.state};
    state.to = date;
    state.validationErrors = {};
    this.setState(state);
  }

  private onLevelChange(index: number) {
    let knownLevels = [...this.state.knownLevels];
    knownLevels[index] = {...knownLevels[index], isSelected: !knownLevels[index].isSelected};
    this.setState({knownLevels: knownLevels});
  }

  private onLoggerChange(index: number) {
    let knownLoggers = [...this.state.knownLoggers];
    knownLoggers[index] = {...knownLoggers[index], isSelected: !knownLoggers[index].isSelected};
    this.setState({knownLoggers: knownLoggers});
  }

  private async handlePageClick(pageIndex: number) {
    this.onSearchClick(pageIndex);
  }

  private async onSearchClick(pageIndex?: number) {
    if (this.state.pageIndex !== pageIndex) {
      this.setState({isLoading: true});

      let token = AuthHelper.getAccessToken() ?? '';

      if (!this.state.from || !Date.parse(this.state.from.toISOString() ?? "")) {
        this.addValidationError('From', 'Please select from date');
        return;
      }

      if (!this.state.to || !Date.parse(this.state.to.toISOString() ?? "")) {
        this.addValidationError('To', 'Please select to date');
        return;
      }

      let levels: string[] = [];
      let loggers: string[] = [];

      this.state.knownLevels.forEach(x => {
        if (x.isSelected) {
          levels.push(x.value);
        }
      });

      this.state.knownLoggers.forEach(x => {
        if (x.isSelected) {
          loggers.push(x.value);
        }
      });

      let request: IFilterRequest = {
        from: moment(this.state.from).format("YYYY-MM-DDTHH:mm:ss"),
        to: moment(this.state.to).format("YYYY-MM-DDTHH:mm:ss"),
        levels: levels,
        loggers: loggers,
        pageIndex: pageIndex ?? 0
      };

      try {
        let response = await Api.filterSystemLogs(token, request);

        this.setState({
          knownLoggers: response.knownLoggers,
          knownLevels: response.knownLevels,
          logs: response.logs,
          totalPages: response.totalPages,
          pageIndex: response.pageIndex,
          isLoading: false
        });
      } catch (err) {
        this.setValidationErrors(Validations.buildApiCommunicationErrors('Can\'t filter system logs', err));
      }
    }
  }

  private addValidationError(key: string, message: string) {
    let state = {...this.state};
    state.validationErrors = {};
    Validations.addError(state.validationErrors, key, message);
    this.setState(state);
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }
}

export default withEngineCompartmentAuthorize(SystemLogsPage);