import classNames from "classnames";
import React, { Component } from 'react';
import { Nav, Navbar } from "react-bootstrap";
import Routes from "../../../../data/Routes";
import s from "./SubMenu.module.scss";

export default class SubMenu extends Component {
  render() {
    return (
      <>
        <Navbar collapseOnSelect variant="light" expand="lg" className={classNames(s.subMenuStyle)}
                style={{backgroundImage: `url('/img/gold-stripe-pattern.png')`}}>
          <Nav activeKey={Routes.home}>
            <span className={s.subMenuLeftPadding}> </span>
            {this.props.children}
          </Nav>
        </Navbar>
      </>
    );
  }
}
