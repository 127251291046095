import React, { Component } from "react";
import { Alert, Col, Nav, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import Routes from "../../../../data/Routes";
import Api from "../../../../tools/api/api";
import IActivateRequest from "../../../../tools/api/api-interfaces/users/IActivateRequest";
import IDisableRequest from "../../../../tools/api/api-interfaces/users/IDisableRequest";
import AuthHelper from "../../../../tools/auth/AuthHelper";
import ValidationErrors from "../../../../tools/validation-helper/ValidationErrors";
import Validations from "../../../../tools/validation-helper/Validations";
import withEngineCompartmentAuthorize from "../../../common/authorize/withEngineCompartmentAuthorize";
import IconButton from "../../../common/buttons/icon-button/IconButton";
import ContainerPageSize from "../../../common/layout-components/container-page-size/ContainerPageSize";
import { LoadingBarHeight } from "../../../common/layout-components/loading-bar/ILoadingBarProps";
import LoadingBar from "../../../common/layout-components/loading-bar/LoadingBar";
import SubMenu from "../../../common/layout-components/sub-menu/SubMenu";
import { VerticalSpaceSize } from "../../../common/layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../common/layout-components/vertical-space/VerticalSpace";
import ValidationSummary from "../../../common/validation-summary/ValidationSummary";
import DeleteUserConfirmationModal from "../delete-user-confirmation-modal/DeleteUserConfirmationModal";
import { IUsersPageProps } from "./IUsersPageProps";
import { IUsersPageState } from "./IUsersPageState";
import styles from './UsersPage.module.scss';

class UsersPage extends Component<IUsersPageProps, IUsersPageState> {
  constructor(props: IUsersPageProps) {
    super(props);

    this.state = {
      users: null,
      isDeleteDialogOpen: false,
      userIdToDelete: undefined,
      userEmailToDelete: undefined,
      isLoading: true,
      validationErrors: null,
    };

    this.onConfirmDeleteUserClick = this.onConfirmDeleteUserClick.bind(this);
    this.onCancelDeleteUserClick = this.onCancelDeleteUserClick.bind(this);
  }

  render() {
    const Email = "Email";
    const Name = "Name";
    const Groups = "Groups";
    return (
      <>
        {
          this.state.isLoading ? <LoadingBar size={LoadingBarHeight.normal}/> :
            <>
              <SubMenu>
                <LinkContainer to={Routes.createUser}>
                  <Nav.Link><span className="navItemLink">Add user</span></Nav.Link>
                </LinkContainer>
              </SubMenu>
              <VerticalSpace size={VerticalSpaceSize.small}/>

              <ContainerPageSize>
                <DeleteUserConfirmationModal
                  showDialog={this.state.isDeleteDialogOpen}
                  userIdToDelete={this.state.userIdToDelete}
                  userEmailToDelete={this.state.userEmailToDelete}
                  onConfirm={this.onConfirmDeleteUserClick}
                  onCancel={this.onCancelDeleteUserClick}
                />

                <VerticalSpace size={VerticalSpaceSize.small}/>
                <>
                  <h1>Users</h1>
                  <Row>
                    <Col md={{span: "8", offset: "2"}}>
                      <ValidationSummary errors={this.state.validationErrors ?? {}}/>
                    </Col>
                  </Row>
                  {
                    this.state.users !== null && this.state.users.length > 0
                      ?
                      <>
                        <div className="d-none d-md-block">
                          <Row>
                            <Col md={3} style={{maxWidth: "11rem"}}> </Col>
                            <Col md={3}><b>{Email}</b></Col>
                            <Col md={3}><b>{Name}</b></Col>
                            <Col md={3}><b>{Groups}</b></Col>
                          </Row>
                          <hr className="my-1" style={{opacity: 1}}/>
                        </div>

                        <div className="mt-3 text-break">
                          {
                            this.state.users.map(user => {
                              return (
                                <div key={user.id}>
                                  <div>
                                    <Row>
                                      <Col md={3} style={{maxWidth: "11rem"}}>
                                        <div className="mb-lg-0 mb-2">
                                          <Link to={Routes.buildUpdateUserUrl(user.id)}>
                                            <IconButton onClick={() => function onEditUser() {}}
                                                        iconType={"edit"} variant={"outline-primary"}
                                                        styles={{margin: "0.15em"}} toolTipTitle={"Edit"}/>
                                          </Link>

                                          {
                                            user.isActive
                                              ? <IconButton onClick={() => this.onDeactivateUser(user.id)}
                                                            iconType={"active"} toolTipTitle={"Disable"}
                                                            variant={"outline-primary"} styles={{margin: "0.15em"}}/>

                                              : <IconButton onClick={() => this.onActivateUser(user.id)}
                                                            iconType={"notActive"} toolTipTitle={"Enable"}
                                                            variant={"outline-primary"} styles={{margin: "0.15em"}}/>
                                          }

                                          <IconButton onClick={() => this.onUserDeleteClick(user.id)}
                                                      iconType={"remove"} toolTipTitle={"Delete"}
                                                      variant={"outline-primary"} styles={{margin: "0.15em"}}/>
                                        </div>
                                      </Col>
                                      <Col md={3}>
                                        <span className="d-inline-block d-md-none"><b>{Email}: </b>
                                          <span className={!user.isActive ? styles.disableTextColor : ""}> {user.email} </span> <br/>
                                        </span>
                                        <span className="d-none d-md-block">
                                          <span className={!user.isActive ? styles.disableTextColor : ""}> {user.email} </span>
                                        </span>
                                        {user.isActive ? <></> : <><b><i> (disabled)</i></b></>}
                                      </Col>
                                      <Col md={3}>
                                        <span className="d-inline-block d-md-none"><b>{Name}: </b>
                                          <span className={!user.isActive ? styles.disableTextColor : ""}>{user.name}</span> <br/>
                                        </span>
                                        <span className="d-none d-md-block">
                                          <span className={!user.isActive ? styles.disableTextColor : ""}>{user.name}</span>
                                        </span>
                                      </Col>
                                      <Col md={3}>
                                        <span className="d-inline-block d-md-none"><b>{Groups}: </b>&nbsp;</span>
                                        {
                                          user.groups.map(group => {
                                            return (
                                              <div key={group.id}
                                                   className={!user.isActive ? styles.disableTextColor : ""}>
                                                &#10004;&nbsp;{group.name} <br className="d-none d-md-block"/>
                                              </div>
                                            );
                                          })
                                        }

                                        {user.groups.length !== null && user.groups.length <= 0 ?
                                          <span>&mdash;</span> : <></>}
                                      </Col>
                                    </Row>
                                    <hr/>
                                  </div>
                                </div>
                              );
                            })
                          }
                        </div>
                      </>
                      :
                      <>
                        <div>
                          <VerticalSpace size={VerticalSpaceSize.small}/>
                          <Row>
                            <Col md={{span: "6", offset: "3"}}>
                              <Alert variant="info" className={"text-center"}>
                                No users found.
                              </Alert>
                            </Col>
                          </Row>
                        </div>
                      </>
                  }

                </>
              </ContainerPageSize>

            </>
        }

      </>
    );
  }

  async componentDidMount() {
    let token = AuthHelper.getAccessToken() ?? '';

    try {
      let response = await Api.getUsers(token);

      this.setState({
        users: response.users,
        isLoading: false
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get users from the server', err)
      );
    }
  }

  private async onActivateUser(userId: number) {
    let token = AuthHelper.getAccessToken() ?? '';
    let request: IActivateRequest = {userId: userId};
    try {
      await Api.activateUser(token, request);

      let response = await Api.getUsers(token);
      this.setState({users: response.users,});
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t activate user on the server', err)
      );
    }
  }

  private async onDeactivateUser(userId: number) {
    let token = AuthHelper.getAccessToken() ?? '';
    let request: IDisableRequest = {userId: userId};
    try {
      await Api.deactivateUser(token, request);

      let response = await Api.getUsers(token);
      this.setState({users: response.users,});
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t deactivate user on the server', err)
      );
    }
  }

  private onUserDeleteClick(userId: number) {
    let state = {...this.state};
    state.userIdToDelete = userId;
    let matchingUsers = state.users?.filter(x => x.id === userId);
    if (matchingUsers !== undefined && matchingUsers !== null && matchingUsers.length > 0) {
      let user = matchingUsers[0];
      state.userEmailToDelete = user.email;
      state.isDeleteDialogOpen = true;
      this.setState(state);
    }
  }

  private async onConfirmDeleteUserClick(userId: number) {
    let token = AuthHelper.getAccessToken() ?? '';

    try {
      await Api.deleteUser(token, userId);

      let response = await Api.getUsers(token);
      this.setState({users: response.users,});
    } catch (err) {
      this.setValidationErrors(Validations.buildApiCommunicationErrors('Can\'t delete user', err));
      this.closeDeleteUserDialog();
      return;
    }

    this.closeDeleteUserDialog();
  }

  private onCancelDeleteUserClick() {
    this.closeDeleteUserDialog();
  }

  private closeDeleteUserDialog() {
    let state = {...this.state};
    state.userIdToDelete = undefined;
    state.userEmailToDelete = undefined;
    state.isDeleteDialogOpen = false;
    this.setState(state);
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }
}

export default withEngineCompartmentAuthorize(UsersPage);