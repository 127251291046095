import React, { Component } from "react";
import { Alert, Col, Nav, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Routes from "../../../../data/Routes";
import Api from "../../../../tools/api/api";
import AuthHelper from "../../../../tools/auth/AuthHelper";
import PermissionHelper from "../../../../tools/permissions/PermissionHelper";
import ValidationErrors from "../../../../tools/validation-helper/ValidationErrors";
import Validations from "../../../../tools/validation-helper/Validations";
import withEngineCompartmentAuthorize from "../../../common/authorize/withEngineCompartmentAuthorize";
import ContainerPageSize from "../../../common/layout-components/container-page-size/ContainerPageSize";
import { LoadingBarHeight } from "../../../common/layout-components/loading-bar/ILoadingBarProps";
import LoadingBar from "../../../common/layout-components/loading-bar/LoadingBar";
import SubMenu from "../../../common/layout-components/sub-menu/SubMenu";
import { VerticalSpaceSize } from "../../../common/layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../common/layout-components/vertical-space/VerticalSpace";
import ValidationSummary from "../../../common/validation-summary/ValidationSummary";
import GroupsWithProjectsTree from "./groupsWithProjectsTree/GroupsWithProjectsTree";
import { IProjectsPageProps } from "./IProjectsPageProps";
import { IProjectsPageState } from "./IProjectsPageState";
import Projects from "./projects/Projects";
import "./ProjectsPage.scss";

class ProjectsPage extends Component<IProjectsPageProps, IProjectsPageState> {
  constructor(props: IProjectsPageProps) {
    super(props);

    this.state = {
      groupsWithProjects: null,
      isLoading: true,
      validationErrors: null
    };
  }


  render() {
    return (
      <>
        {
          this.state.isLoading ? <LoadingBar size={LoadingBarHeight.normal}/> :
            <>
              <>
                {PermissionHelper.doesUserHavePermissionGlobally(PermissionHelper.getAllPermissions().SYS_PRJ_MNG)
                  ?
                  <SubMenu>
                    <LinkContainer to={Routes.manageProjectsTree}>
                      <Nav.Link><span className="navItemLink">Manage projects tree</span></Nav.Link>
                    </LinkContainer>
                  </SubMenu>
                  : null
                }

                <VerticalSpace size={VerticalSpaceSize.small}/>
                <ContainerPageSize>
                  <VerticalSpace size={VerticalSpaceSize.small}/>

                  <h1>Projects</h1>
                  <Row>
                    <Col md={{span: "8", offset: "2"}}>
                      <ValidationSummary errors={this.state.validationErrors ?? {}}/>
                    </Col>
                  </Row>
                  {
                    this.state.groupsWithProjects === null
                      ?
                      <>
                        <div>
                          <VerticalSpace size={VerticalSpaceSize.small}/>
                          <Row>
                            <Col md={{span: "6", offset: "3"}}>
                              <Alert variant="info" className={"text-center"}>
                                No projects found.
                              </Alert>
                            </Col>
                          </Row>
                        </div>
                      </>
                      :
                      <>
                        <GroupsWithProjectsTree groups={this.state.groupsWithProjects.groups}/>

                        <Projects projects={this.state.groupsWithProjects.projectWithNoGroup}/>
                      </>

                  }
                </ContainerPageSize>
              </>
            </>
        }
      </>
    );
  }

  async componentDidMount() {
    try {
      let token = AuthHelper.getAccessToken() ?? '';
      let response = await Api.getGroupsWithProjects(token);

      this.setState({
        groupsWithProjects: response,
        isLoading: false
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get groups with projects from the server', err)
      );
    }
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }
}

export default withEngineCompartmentAuthorize(ProjectsPage);