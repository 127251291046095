import { ChartData, ChartDataset } from "chart.js";
import moment from "moment";
import React from 'react';
import { Line } from "react-chartjs-2/dist";
import { IChartData } from "../../../../tools/api/api-interfaces/common/IChartData";
import ChartHelper from "../ChartHelper";
import NoChartData from "../no-chart-data/NoChartData";
import { IHeartbeatsChartProps } from "./IHeartbeatsChartProps";

export default class HeartbeatsChart extends React.Component<IHeartbeatsChartProps> {
  render() {
    let total = 0;

    this.props.chartData.dates.forEach(x => total += x.length);

    if (total === 0) {
      return <NoChartData/>;
    }

    let data: ChartData = this.buildChartData(this.props.chartData);

    return <Line type={'line'} data={data} />;
  }

  private buildChartData(data: IChartData) {
    let labels = data.dates.map<string>(x => moment(x).format("MM/DD/YY h:mm:ss A"));

    let datasets = data.lines.map<ChartDataset>((x, i) => {
      let color = ChartHelper.getHeartbeatsDataColor(i);
      return {
        type: "line",
        label: x.label,
        data: x.values,
        backgroundColor: color,
        borderColor: color
      };
    });

    let result: ChartData = {
      labels: labels,
      datasets: datasets
    };

    return result;
  }
}
