import moment from "moment";
import React, { Component } from 'react';
import { Col, Row } from "react-bootstrap";
import Api from "../../../../tools/api/api";
import { IViewOtherDatesRequest } from "../../../../tools/api/api-interfaces/common/IViewOtherDatesRequest";
import AuthHelper from "../../../../tools/auth/AuthHelper";
import ValidationErrors from "../../../../tools/validation-helper/ValidationErrors";
import Validations from "../../../../tools/validation-helper/Validations";
import withEngineCompartmentAuthorize from "../../../common/authorize/withEngineCompartmentAuthorize";
import ContainerPageSize from "../../../common/layout-components/container-page-size/ContainerPageSize";
import { LoadingBarHeight } from "../../../common/layout-components/loading-bar/ILoadingBarProps";
import LoadingBar from "../../../common/layout-components/loading-bar/LoadingBar";
import SystemSubMenu from "../../../common/layout-components/system-sub-menu/SystemSubMenu";
import { VerticalSpaceSize } from "../../../common/layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../common/layout-components/vertical-space/VerticalSpace";
import HeartbeatsComponent from "../../../common/telemetry-components/heartbeats-component/HeartbeatsComponent";
import ValidationSummary from "../../../common/validation-summary/ValidationSummary";
import { ISystemHeartbeatsPageProps } from "./ISystemHeartbeatsPageProps";
import { ISystemHeartbeatsPageState } from "./ISystemHeartbeatsPageState";

class SystemHeartbeatsPage extends Component<ISystemHeartbeatsPageProps, ISystemHeartbeatsPageState> {

  constructor(props: ISystemHeartbeatsPageProps) {
    super(props);

    this.state = {
      from: moment().toDate(),
      to: moment().toDate(),
      heartbeats: [],
      chart: null,
      isLoading: true,
      validationErrors: {}
    };

    this.onViewClick = this.onViewClick.bind(this);
  }

  render() {
    return (
      <>
        <SystemSubMenu />

        <VerticalSpace size={VerticalSpaceSize.small}/>

        <ContainerPageSize>
          <VerticalSpace size={VerticalSpaceSize.small}/>
          {
            this.state.isLoading ? <LoadingBar size={LoadingBarHeight.normal}/> :
              <>
                {
                  <>
                    <h1 className="text-break">Engine compartment heartbeats</h1>

                    <VerticalSpace size={VerticalSpaceSize.small}/>
                    <Row>
                      <Col md={{span: 6, offset: 3}}>
                        <ValidationSummary errors={this.state.validationErrors} excludeKeys={["From", "To",]}/>
                      </Col>
                    </Row>

                    <HeartbeatsComponent from={this.state.from}
                                         to={this.state.to}
                                         heartbeats={this.state.heartbeats}
                                         chart={this.state.chart}
                                         onFromDateClick={(date: Date) => this.setFromDate(date)}
                                         onToDateClick={(date: Date) => this.setToDate(date)}
                                         onViewClick={() => this.onViewClick()}
                                         validationErrors={this.state.validationErrors}
                    />

                  </>
                }
              </>
          }
        </ContainerPageSize>
      </>
    );
  }

  async componentDidMount() {
    let token = AuthHelper.getAccessToken() ?? '';

    try {
      const response = await Api.getSystemHeartbeatsData(token);

      let state = {...this.state};
      state.from = moment(response.fromDate).toDate();
      state.to = moment(response.toDate).toDate();
      state.heartbeats = response.data.heartbeats;
      state.chart = response.data.chart;
      state.isLoading = false;
      this.setState(state);
    } catch (e) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get system heartbeats data from the server', e)
      );
    }
  }

  private async onViewClick() {
    this.setState({isLoading: true});

    let token = AuthHelper.getAccessToken() ?? '';

    if (!this.state.from || !Date.parse(this.state.from.toISOString() ?? "")) {
      this.addValidationError('From', 'Please select from date');
      return;
    }

    if (!this.state.to || !Date.parse(this.state.to.toISOString() ?? "")) {
      this.addValidationError('To', 'Please select to date');
      return;
    }

    let request: IViewOtherDatesRequest = {
      from: moment(this.state.from).format("YYYY-MM-DDTHH:mm:ss"),
      to: moment(this.state.to).format("YYYY-MM-DDTHH:mm:ss"),
    };

    try {
      let response = await Api.viewSystemOtherDates(token, request);

      this.setState({
        heartbeats: response.heartbeats,
        chart: response.chart,
        isLoading: false
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get system heartbeats data with other dates', err)
      );
    }
  }

  private setFromDate(date: Date) {
    let state = {...this.state};
    state.from = date;
    state.validationErrors = {};
    this.setState(state);
  }

  private setToDate(date: Date) {
    let state = {...this.state};
    state.to = date;
    state.validationErrors = {};
    this.setState(state);
  }

  private addValidationError(key: string, message: string) {
    let state = {...this.state};
    state.validationErrors = {};
    Validations.addError(state.validationErrors, key, message);
    this.setState(state);
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }
}

export default withEngineCompartmentAuthorize(SystemHeartbeatsPage);