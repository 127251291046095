import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import {
  ArrowCounterclockwise,
  ArrowDown,
  ArrowLeftRight,
  ArrowRepeat,
  ArrowUp,
  Check,
  Pencil,
  ToggleOff,
  ToggleOn,
  Tools,
  X
} from "react-bootstrap-icons";
import style from './IconButton.module.scss';
import { IIconButtonProps } from './IIconButtonProps';

export default class IconButton extends Component<IIconButtonProps> {

  constructor(props: Readonly<IIconButtonProps>) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  render() {
    return (
      <>
        <Button type={this.props.buttonType} variant={this.props.variant} onClick={this.onClickHandler}
                style={this.props.styles} className={style.customButton} disabled={this.props.disabled}
                size={this.props.size}
                title={this.props.toolTipTitle}>
          {
            (this.props.title !== undefined && this.props.title !== '' && this.props.title !== null)
              ?
              <>
                <span className='d-inline-block pr-2'>{this.renderIcon()}</span>
                <span className="align-baseline">{this.props.title}</span>
              </>
              :
              <>
                <span className='d-inline-block'>{this.renderIcon()}</span>
              </>
          }
        </Button>
      </>
    );
  }

  renderIcon() {
    switch (this.props.iconType) {
      case 'submit':
        return <Check width="1em" height="1em"/>;
      case 'up':
        return <ArrowUp width="1em" height="1em"/>;
      case 'down':
        return <ArrowDown width="1em" height="1em"/>;
      case 'move':
        return <ArrowLeftRight width="1em" height="1em"/>;
      case 'edit':
        return <Pencil width="1em" height="1em"/>;
      case 'setup':
        return <Tools width="1em" height="1em"/>;
      case 'revert':
        return <ArrowCounterclockwise width="1em" height="1em"/>;
      case 'repeat':
        return <ArrowRepeat width="1em" height="1em"/>;
      case 'remove':
        return <X width="1em" height="1em"/>;
      case 'active':
        return <ToggleOn width="1em" height="1em"/>;
      case 'notActive':
        return <ToggleOff width="1em" height="1em"/>;
      default:
        return <> </>;
    }
  }

  private onClickHandler() {
    if (this.props.onClick !== undefined) {
      this.props.onClick(this.props.value);
    }
  }
}
