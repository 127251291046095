import React, { Component } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import Api from "../../../tools/api/api";
import AuthHelper from "../../../tools/auth/AuthHelper";
import ValidationErrors from "../../../tools/validation-helper/ValidationErrors";
import Validations from "../../../tools/validation-helper/Validations";
import withEngineCompartmentAuthorize from "../../common/authorize/withEngineCompartmentAuthorize";
import IconButton from "../../common/buttons/icon-button/IconButton";
import ContainerPageSize from "../../common/layout-components/container-page-size/ContainerPageSize";
import { LoadingBarHeight } from "../../common/layout-components/loading-bar/ILoadingBarProps";
import LoadingBar from "../../common/layout-components/loading-bar/LoadingBar";
import { VerticalSpaceSize } from "../../common/layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../common/layout-components/vertical-space/VerticalSpace";
import ValidationMessages from "../../common/validation-messages/ValidationMessages";
import ValidationSummary from "../../common/validation-summary/ValidationSummary";
import { IProfilePageProps } from "./IProfilePageProps";
import { IProfilePageState } from "./IProfilePageState";
import styles from "./ProfilePage.module.scss";

class ProfilePage extends Component<IProfilePageProps, IProfilePageState> {
  constructor(props: IProfilePageProps) {
    super(props);

    this.state = {
      userName: "",
      email: "",
      oldPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
      success: false,
      isLoading: false,
      validationErrors: {}
    };

    this.onOldPasswordChange = this.onOldPasswordChange.bind(this);
    this.onNewPasswordChange = this.onNewPasswordChange.bind(this);
    this.onNewPasswordConfirmationChange = this.onNewPasswordConfirmationChange.bind(this);
    this.onChangeProfilePassword = this.onChangeProfilePassword.bind(this);
  }

  render() {
    return (
      <>
        <VerticalSpace size={VerticalSpaceSize.small}/>
        <ContainerPageSize>
          <VerticalSpace size={VerticalSpaceSize.small}/>
          {
            this.state.isLoading ? <LoadingBar size={LoadingBarHeight.normal}/> :
              <>
                <h1>Change password</h1>
                <VerticalSpace size={VerticalSpaceSize.small}/>

                <Row>
                  <Col md={{span: "8", offset: "2"}}>
                    <ValidationSummary errors={this.state.validationErrors}
                                       excludeKeys={["OldPassword", "NewPassword", "NewPasswordConfirmation"]}/>
                  </Col>
                </Row>

                {this.state.success &&
                <Row>
                    <Col md={{span: "8", offset: "2"}}>
                        <Alert variant="success">
                            <h5 className="text-center">Success</h5>
                        </Alert>
                    </Col>
                </Row>
                }

                <Form onSubmit={this.onChangeProfilePassword}>
                  <Row>
                    <Col md={6} lg={5}>
                      <Form.Label className={styles.label}>Old password</Form.Label>
                      <Form.Control
                        type="password"

                        autoComplete="new-password"
                        placeholder="Enter old password"
                        value={this.state.oldPassword}
                        onChange={this.onOldPasswordChange}
                      />
                      <ValidationMessages fieldName="OldPassword" errors={this.state.validationErrors}/>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col md={6} lg={5}>
                      <Form.Label className={styles.label}>New password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter new password"
                        autoComplete="new-password"
                        value={this.state.newPassword}
                        onChange={this.onNewPasswordChange}
                      />
                      <ValidationMessages fieldName="NewPassword" errors={this.state.validationErrors}/>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col md={6} lg={5}>
                      <Form.Label className={styles.label}>New password confirmation</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter new password confirmation"
                        value={this.state.newPasswordConfirmation}
                        onChange={this.onNewPasswordConfirmationChange}
                      />
                      <ValidationMessages fieldName="NewPasswordConfirmation" errors={this.state.validationErrors}/>
                    </Col>
                  </Row>

                  <VerticalSpace size={VerticalSpaceSize.normal}/>

                  <IconButton buttonType={"submit"} onClick={() => function() {
                  }} title="Save"
                              variant={"outline-primary"} styles={{marginRight: "1em"}}/>
                </Form>
              </>
          }
        </ContainerPageSize>
      </>
    );
  }

  async onChangeProfilePassword(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    this.setState({isLoading: true});

    let token = AuthHelper.getAccessToken() ?? '';
    let request: any = {
      oldPassword: this.state.oldPassword,
      newPassword: this.state.newPassword,
      newPasswordConfirmation: this.state.newPasswordConfirmation
    };

    try {
      await Api.changePassword(token, request);

      let state = {...this.state};
      state.success = true;
      state.oldPassword = "";
      state.newPassword = "";
      state.newPasswordConfirmation = "";
      state.isLoading = false;
      this.setState(state);
    } catch (err) {
      this.setAllValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t change password on the server', err)
      );
    }
  }

  private onOldPasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setAllValidationErrors({});

    this.setState({oldPassword: e.target.value});
  }

  private onNewPasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setAllValidationErrors({});

    this.setState({newPassword: e.target.value});
  }

  private onNewPasswordConfirmationChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setAllValidationErrors({});

    this.setState({newPasswordConfirmation: e.target.value});
  }

  private setAllValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }
}

export default withEngineCompartmentAuthorize(ProfilePage);