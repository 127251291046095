import React, { Component } from "react";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Routes from "../../../../data/Routes";
import SubMenu from "../sub-menu/SubMenu";
import { ITelemetrySubMenuProps } from "./ITelemetrySubMenuProps";

export default class TelemetrySubMenu extends Component<ITelemetrySubMenuProps> {
  render() {
    return (
      <>
        <SubMenu>
          <LinkContainer to={Routes.buildProjectHeartbeatsUrl(this.props.projectId)}>
            <Nav.Link><span className="navItemLink">Heartbeats</span></Nav.Link>
          </LinkContainer>

          <LinkContainer to={Routes.buildProjectPerformanceUrl(this.props.projectId)}>
            <Nav.Link><span className="navItemLink">Performance</span></Nav.Link>
          </LinkContainer>
        </SubMenu>
      </>
    );
  }
}
