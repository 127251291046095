import ISimpleEvent from './ISimpleEvent';
import { SimpleEventHandler } from './SimpleEventHandler';

export default class SimpleEvent<T> implements ISimpleEvent<T> {
  private handlers: SimpleEventHandler<T>[] = [];

  on(handler: SimpleEventHandler<T>): void {
    this.handlers.push(handler);
  }

  off(handler: SimpleEventHandler<T>): void {
    this.handlers = this.handlers.filter(h => h !== handler);
  }

  trigger(data: T) {
    this.handlers.forEach(h => h(data));
  }
}