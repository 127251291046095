import classNames from "classnames";
import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { VerticalSpaceSize } from "../vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../vertical-space/VerticalSpace";
import styles from './ContainerPageSize.module.scss';

export default class ContainerPageSize extends Component {
  render() {
    return (
      <>
        <Container className={classNames(["pb-3", styles.container])}>
          {this.props.children}
        </Container>
        <VerticalSpace size={VerticalSpaceSize.small}/>
      </>
    );
  }
}
