import React, { Component } from "react";
import { Col, Nav, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Routes from "../../../../../data/Routes";
import Api from "../../../../../tools/api/api";
import AuthHelper from "../../../../../tools/auth/AuthHelper";
import ValidationErrors from "../../../../../tools/validation-helper/ValidationErrors";
import Validations from "../../../../../tools/validation-helper/Validations";
import withEngineCompartmentAuthorize from "../../../../common/authorize/withEngineCompartmentAuthorize";
import ApiKeysSubMenu from "../../../../common/layout-components/api-keys-sub-menu/ApiKeysSubMenu";
import ContainerPageSize from "../../../../common/layout-components/container-page-size/ContainerPageSize";
import { LoadingBarHeight } from "../../../../common/layout-components/loading-bar/ILoadingBarProps";
import LoadingBar from "../../../../common/layout-components/loading-bar/LoadingBar";
import ProjectSubMenu from "../../../../common/layout-components/project-sub-menu/ProjectSubMenu";
import { VerticalSpaceSize } from "../../../../common/layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../../common/layout-components/vertical-space/VerticalSpace";
import ValidationSummary from "../../../../common/validation-summary/ValidationSummary";
import IProjectApiKeysHomePageProps from "./IProjectApiKeysHomePageProps";
import IProjectApiKeysHomePageState from "./IProjectApiKeysHomePageState";

class ProjectApiKeysHomePage extends Component<IProjectApiKeysHomePageProps, IProjectApiKeysHomePageState> {
  constructor(props: IProjectApiKeysHomePageProps) {
    super(props);

    this.state = {
      currentProjectId: null,
      projectFullName: "",
      validationErrors: null,
      isLoading: true
    };
  }

  render() {
    const ProjectAPIKeys = "Keys for third parties to connect to project API";
    const EngineCompartmentAPIKeys = "Keys that the project uses to connect to Engine Compartment API";

    return (
      <>
        <ProjectSubMenu projectId={this.state.currentProjectId ?? 0}/>

        <ApiKeysSubMenu projectId={this.state.currentProjectId ?? 0}/>

        <VerticalSpace size={VerticalSpaceSize.small}/>

        <ContainerPageSize>
          <VerticalSpace size={VerticalSpaceSize.small}/>
          {
            this.state.isLoading ? <LoadingBar size={LoadingBarHeight.normal}/> :
              <>
                {
                  this.state.validationErrors != null
                    ?
                    <Row>
                      <Col md={{span: 6, offset: 3}}>
                        <ValidationSummary errors={this.state.validationErrors ?? {}}/>
                      </Col>
                    </Row>
                    :
                    <>
                      <h1 className={"text-break"}>{this.state.projectFullName}</h1>

                      <VerticalSpace size={VerticalSpaceSize.small}/>

                      <div>There are two types of API keys.
                        First type is API keys that third parties use when they connect to project API.
                      </div>
                      <LinkContainer to={Routes.buildProjectApiKeysUrl(this.state.currentProjectId ?? 0)} className="p-0 pt-1">
                        <Nav.Link><span className="navItemLink">{ProjectAPIKeys}</span></Nav.Link>
                      </LinkContainer>

                      <VerticalSpace size={VerticalSpaceSize.small}/>
                      <VerticalSpace size={VerticalSpaceSize.small}/>

                      <div>Second type is API keys that the project uses to call Engine Compartment's API</div>

                      <LinkContainer className="p-0 pt-1" to={Routes.buildEngineCompartmentApiKeysUrl(this.state.currentProjectId ?? 0)}>
                        <Nav.Link><span className="navItemLink">{EngineCompartmentAPIKeys}</span></Nav.Link>
                      </LinkContainer>
                    </>
                }
              </>
          }
        </ContainerPageSize>
      </>
    );
  }

  async componentDidMount() {
    let projectId = parseInt(this.props.projectId as string);
    this.setState({currentProjectId: projectId});

    let token = AuthHelper.getAccessToken() ?? '';
    try {
      let response = await Api.getProjectFullName(token, projectId);

      this.setState({
        projectFullName: response.projectFullName,
        isLoading: false
      });
    } catch (err) {
      this.setErrors(
        Validations.buildApiCommunicationErrors('Can\'t get project full name from the server', err)
      );
    }
  }

  private setErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }
}

export default withEngineCompartmentAuthorize(ProjectApiKeysHomePage);