import React, { Component } from 'react';
import { IProjectsProps } from "./IProjectsProps";
import { Nav } from "react-bootstrap";
import Routes from "../../../../../data/Routes";
import { LinkContainer } from "react-router-bootstrap";

class Projects extends Component<IProjectsProps> {
  render() {
    return (
      <>
        {
          this.props.projects.map((x, i) => {
            return (
                <div key={x.projectId} className={"text-break"}>
                  <LinkContainer className="p-1" to={Routes.buildProjectInfoUrl(x.projectId)}>
                    <Nav.Link>{x.projectName}</Nav.Link>
                  </LinkContainer>
                </div>
            );
          })
        }
      </>
    );
  }
}

export default Projects;