import classNames from 'classnames';
import React, { Component } from 'react';
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Routes from "../../../../../../data/Routes";
import PermissionHelper from "../../../../../../tools/permissions/PermissionHelper";
import IconButton from "../../../../../common/buttons/icon-button/IconButton";
import styles from './ApiKeyLine.module.scss';
import { IApiKeyLineProps } from "./IApiKeyLineProps";

export default class ApiKeyLine extends Component<IApiKeyLineProps> {
  constructor(props: Readonly<IApiKeyLineProps>) {
    super(props);

    this.onActivateApiKey = this.onActivateApiKey.bind(this);
    this.onDeactivateApiKey = this.onDeactivateApiKey.bind(this);
    this.onDeleteApiKey = this.onDeleteApiKey.bind(this);
  }

  render() {
    const componentClasses = this.props.classNames === '' || this.props.classNames === undefined
      ? "" : this.props.classNames;

    return (
      <div className={classNames([componentClasses, styles.item])}>
        <Row>
          {
            PermissionHelper.doesUserHavePermissionGloballyOrInProject(PermissionHelper.getAllPermissions().PRJ_API_MNG, this.props.projectId)
              ?
              <Col lg={2}>
                <div className="mb-lg-0 mb-2">
                  <Link to={Routes.buildEditProjectApiKeyUrl(this.props.projectId, this.props.apiKeyId)}>
                    <IconButton onClick={() => function onLineEdit() {}} iconType={"edit"}
                                variant="outline-primary" styles={{margin: "0.15em"}} toolTipTitle={"Edit"}/>
                  </Link>

                  {this.props.isActive
                    ? <IconButton onClick={() => this.onDeactivateApiKey(this.props.apiKeyId)} iconType={"active"}
                                  variant={"outline-primary"} styles={{margin: "0.15em"}} toolTipTitle={"Disable"}/>
                    : <IconButton onClick={() => this.onActivateApiKey(this.props.apiKeyId)} iconType={"notActive"}
                                  variant={"outline-primary"} styles={{margin: "0.15em"}} toolTipTitle={"Enable"}/>
                  }
                  <IconButton onClick={() => this.onDeleteApiKey(this.props.apiKeyId)} iconType={"remove"}
                              variant={"outline-primary"} styles={{margin: "0.15em"}} toolTipTitle={"Delete"}/>
                </div>
              </Col>
              : null
          }

          <Col lg={2}>
            <span className="d-inline-block d-lg-none"><b>Access key: </b>
              <span className={!this.props.isActive ? styles.disableTextColor : ""}> {this.props.access} </span> <br/>
            </span>
            <span className="d-none d-lg-block">
               <span className={!this.props.isActive ? styles.disableTextColor : ""}> {this.props.access} </span>
            </span>
            {this.props.isActive ? <></> : <><b><i> (disabled)</i></b></>}
          </Col>
          <Col lg={3}>
            <span className="d-inline-block d-lg-none"><b>Secret: </b>
             <span className={!this.props.isActive ? styles.disableTextColor : ""} style={{whiteSpace: "pre-wrap"}}>
               {this.props.secret}
             </span> <br/>
            </span>
            <span className="d-none d-lg-block">
              <span className={!this.props.isActive ? styles.disableTextColor : ""} style={{whiteSpace: "pre-wrap"}}>
                {this.props.secret}
              </span>
            </span>
          </Col>
          <Col lg={2}>
            <span className="d-inline-block d-lg-none"><b>Roles: </b>&nbsp;</span>
            {
              this.props.roles.map(x => {
                return (
                  <span key={x.id} className={!this.props.isActive ? styles.disableTextColor : ""}>
                  &#10004;&nbsp;{x.apiName} <br className="d-none d-lg-block"/>
                </span>
                );
              })
            }
            {
              this.props.roles.length !== null && this.props.roles.length === 0
                ? <span className={!this.props.isActive ? styles.disableTextColor : ""}>&mdash;</span>
                : <></>
            }
          </Col>
          <Col lg={3}>
            <span className="d-inline-block d-lg-none"><b>Notes: </b>
              <span className={!this.props.isActive ? styles.disableTextColor : ""}>
                {
                  this.props.notes
                    ? this.props.notes
                    : <span className={!this.props.isActive ? styles.disableTextColor : ""}>&mdash;</span>
                }
              </span><br/>
            </span>
            <span className="d-none d-lg-block">
                <span className={!this.props.isActive ? styles.disableTextColor : ""}>
                  {
                    this.props.notes
                      ? this.props.notes
                      : <span className={!this.props.isActive ? styles.disableTextColor : ""}>&mdash;</span>
                  }
                </span>
            </span>
          </Col>
        </Row>
        <hr/>
      </div>
    );
  }

  private onActivateApiKey(apiKeyId: number) {
    if (this.props.onActivateApiKeyClick !== undefined) {
      this.props.onActivateApiKeyClick(apiKeyId);
    }
  }

  private onDeactivateApiKey(apiKeyId: number) {
    if (this.props.onDeactivateApiKeyClick !== undefined) {
      this.props.onDeactivateApiKeyClick(apiKeyId);
    }
  }

  private onDeleteApiKey(apiKeyId: number) {
    if (this.props.onDeleteApiKeyClick !== undefined) {
      this.props.onDeleteApiKeyClick(apiKeyId);
    }
  }
}
