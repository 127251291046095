import classNames from 'classnames';
import React, { Component } from 'react';
import { Col, Row } from "react-bootstrap";
import { IItemProps } from './IItemProps';
import styles from './Item.module.scss';

export default class Item extends Component<IItemProps> {
  render() {
    const componentClasses = this.props.classNames === '' || this.props.classNames === undefined
      ? ""
      : this.props.classNames;

    return (
      <div className={classNames([componentClasses, styles.item])}>
        <Row>
          <Col lg={4}>
            <b>{this.props.name}</b>
          </Col>
          <Col lg={8}>
            {this.props.type === "URL"
              ? <a href={this.props.value} target={"_blank"} rel="noreferrer">{this.props.value}</a>
              : <div style={{whiteSpace: "pre-line"}}>{this.props.value}</div>
            }
          </Col>
        </Row>
      </div>
    );
  }
}
