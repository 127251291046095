import React, { Component } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Routes from "../../../../data/Routes";
import Api from "../../../../tools/api/api";
import IEditUserRequest, { IPasswordChange } from "../../../../tools/api/api-interfaces/users/IEditUserRequest";
import AuthHelper from "../../../../tools/auth/AuthHelper";
import ValidationErrors from "../../../../tools/validation-helper/ValidationErrors";
import Validations from "../../../../tools/validation-helper/Validations";
import withEngineCompartmentAuthorize from "../../../common/authorize/withEngineCompartmentAuthorize";
import IconButton from "../../../common/buttons/icon-button/IconButton";
import ContainerPageSize from "../../../common/layout-components/container-page-size/ContainerPageSize";
import { VerticalSpaceSize } from "../../../common/layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../common/layout-components/vertical-space/VerticalSpace";
import ValidationMessages from "../../../common/validation-messages/ValidationMessages";
import ValidationSummary from "../../../common/validation-summary/ValidationSummary";
import { IUpdateUserPageProps } from "./IUpdateUserPageProps";
import { IUpdateUserPageState } from "./IUpdateUserPageState";
import styles from './UpdateUserPage.module.scss';

class UpdateUserPage extends Component<IUpdateUserPageProps, IUpdateUserPageState> {
  constructor(props: IUpdateUserPageProps) {
    super(props);

    this.state = {
      currentUserId: 0,
      email: "",
      name: "",
      isResetPassword: false,
      newPassword: "",
      newPasswordConfirmation: "",
      redirect: null,
      isLoading: true,
      validationErrors: {}
    };

    this.onEmailChange = this.onEmailChange.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onResetPasswordClick = this.onResetPasswordClick.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onPasswordConfirmationChange = this.onPasswordConfirmationChange.bind(this);
    this.onUpdateUser = this.onUpdateUser.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  render() {
    let userId = parseInt(this.props.userId ?? "");

    let validationErrors: ValidationErrors = {};
    if (isNaN(userId)) {
      Validations.addError(validationErrors, '*', 'User ID is missing or incorrect');
    }

    if (this.state.redirect !== null) {
      return <Redirect to={this.state.redirect}/>;
    }

    return (
      <>
        <VerticalSpace size={VerticalSpaceSize.small}/>
        <ContainerPageSize>
          <VerticalSpace size={VerticalSpaceSize.small}/>
          <>
            <h1>Update user</h1>
            <Row>
              <Col md={{span: "8", offset: "2"}}>
                <ValidationSummary errors={this.state.validationErrors}
                                   excludeKeys={["Email", "Name", "NewPassword", "NewPasswordConfirmation"]}/>
              </Col>
            </Row>
            <Form>
              <Row>
                <Col md={6} lg={5}>
                  <Form.Label className={styles.label}>Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter email"
                    maxLength={100}
                    value={this.state.email}
                    onChange={this.onEmailChange}
                  />
                  <ValidationMessages fieldName="Email" errors={this.state.validationErrors}/>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col md={6} lg={5}>
                  <Form.Label className={styles.label}>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    maxLength={1000}
                    value={this.state.name}
                    onChange={this.onNameChange}
                  />
                  <ValidationMessages fieldName="Name" errors={this.state.validationErrors}/>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col md={6} lg={5}>
                  <Form.Check type="checkbox"
                              label="Reset password"
                              name="resetPassword"
                              checked={this.state.isResetPassword}
                              onChange={this.onResetPasswordClick}
                              className={styles.label}
                  />
                </Col>
              </Row>

              {this.state.isResetPassword
                ?
                <>
                  <br/>
                  <Row>
                    <Col md={6} lg={5}>
                      <Form.Label className={styles.label}>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter password"
                        autoComplete="new-password"
                        value={this.state.newPassword}
                        onChange={this.onPasswordChange}
                      />
                      <ValidationMessages fieldName="NewPassword" errors={this.state.validationErrors}/>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col md={6} lg={5}>
                      <Form.Label className={styles.label}>Password confirmation</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter password confirmation"
                        value={this.state.newPasswordConfirmation}
                        onChange={this.onPasswordConfirmationChange}
                      />
                      <ValidationMessages fieldName="NewPasswordConfirmation" errors={this.state.validationErrors}/>
                    </Col>
                  </Row>
                </>
                : <></>
              }
              <VerticalSpace size={VerticalSpaceSize.normal}/>
              <IconButton onClick={this.onUpdateUser} title="Save" variant={"outline-primary"} styles={{marginRight: "1em"}}/>
              <IconButton onClick={this.onCancel} title="Cancel" variant={"outline-primary"}/>
            </Form>
          </>
        </ContainerPageSize>
      </>
    );
  }

  async componentDidMount() {
    let token = AuthHelper.getAccessToken() ?? '';

    let userId: number;
    if (this.props.userId) {
      userId = +this.props.userId;
    } else {
      userId = 0;
    }

    try {
      let response = await Api.getUserDetails(token, userId);

      this.setState({currentUserId: userId, email: response.email, name: response.name});
    } catch (err) {
      this.setAllValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get user details from the server', err)
      );
    }
  }

  async onUpdateUser() {
    let token = AuthHelper.getAccessToken() ?? '';
    let passwordChange: IPasswordChange = {
      newPassword: this.state.newPassword,
      newPasswordConfirmation: this.state.newPasswordConfirmation
    };
    let request: IEditUserRequest = {
      id: this.state.currentUserId,
      email: this.state.email,
      name: this.state.name,
      passwordChange: this.state.isResetPassword ? passwordChange : null
    };

    try {
      await Api.updateUser(token, request);

      let state = {...this.state};
      state.redirect = Routes.users;
      this.setState(state);
    } catch (err) {
      this.setAllValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t update user on the server', err)
      );
    }
  }

  private onEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setAllValidationErrors({});

    this.setState({email: e.target.value});
  }

  private onNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setAllValidationErrors({});

    this.setState({name: e.target.value});
  }

  private onResetPasswordClick() {
    let state = {...this.state};
    state.isResetPassword = !this.state.isResetPassword;
    state.newPassword = "";
    state.newPasswordConfirmation = "";
    this.setState(state);
  }

  private onPasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setAllValidationErrors({});

    this.setState({newPassword: e.target.value});
  }

  private onPasswordConfirmationChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setAllValidationErrors({});

    this.setState({newPasswordConfirmation: e.target.value});
  }

  private onCancel() {
    let state = {...this.state};
    state.redirect = Routes.users;
    this.setState(state);
  }

  private setAllValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    this.setState(state);
  }
}

export default withEngineCompartmentAuthorize(UpdateUserPage);