import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import { IPaginationComponentProps } from "./IPaginationComponentProps";
import "./PaginationComponent.scss";

export default class PaginationComponent extends Component<IPaginationComponentProps> {
  constructor(props: IPaginationComponentProps) {
    super(props);

    this.onPageClick = this.onPageClick.bind(this);
  }

  render() {
    return (
      <>
        {
          this.props.pageCount > 0 &&
          <div className={"d-flex"}>
              <ReactPaginate pageCount={this.props.pageCount}
                             pageRangeDisplayed={2}
                             marginPagesDisplayed={1}
                             previousLabel={"<"}
                             nextLabel={">"}
                             disableInitialCallback={false}
                             activeClassName={'active'}
                             containerClassName={"pagination"}
                             initialPage={this.props.currentPage}
                             onPageChange={(page: { selected: number }) => this.onPageClick(page)}
              />
          </div>
        }
      </>
    );
  }

  private onPageClick(page: { selected: number }) {
    if (this.props.onPageChange !== undefined) {
      this.props.onPageChange(page.selected);
    }
  }
}
