import AuthHelper from "../auth/AuthHelper";
import { IProjectPermission } from "../api/api-interfaces/login/IGetAuthTokenResponse";

export default class PermissionHelper {

  public static doesUserHavePermissionGlobally(permission: string): boolean {
    const comma = ',';
    let permissions = AuthHelper.getGlobalPermissions()?.split(comma);

    return !!permissions?.includes(permission);
  }

  public static doesUserHavePermissionGloballyOrInProject(permission: string, projectId: number): boolean {
    if (this.doesUserHavePermissionGlobally(permission)) {
      return true;
    } else {
      let projectPermissions: IProjectPermission[] = JSON.parse(AuthHelper.getProjectPermissions() ?? "");

      if (projectPermissions) {
        let projectWithPermissionCodes = projectPermissions.find(x => x.projectId === projectId);
        if (projectWithPermissionCodes) {
          return !!projectWithPermissionCodes.permissionCodes.find(x => x === permission);
        } else return false;
      } else return false;
    }
  }

  public static getAllPermissions() {
    return {
      PRJ_API_MNG: "PRJ_API_MNG",
      PRJ_API_VIEW: "PRJ_API_VIEW",
      PRJ_INFO_EDIT: "PRJ_INFO_EDIT",
      SYS_PRJ_MNG: "SYS_PRJ_MNG",
      SYS_SECURITY: "SYS_SECURITY",
      SYS_SETTINGS: "SYS_SETTINGS",
      SYS_USR_MNG: "SYS_USR_MNG",
    };
  }
}