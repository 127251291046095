export default class ChartHelper {
  public static getHeartbeatsDataColor(index: number): string {
    let colors = this.getArrayOfColorsForHeartbeats();
    return colors[index];
  }

  public static getDataColor(index: number): string {
    let colors = this.getArrayOfColors();
    return colors[index];
  }

  public static generateOfColors(int: number) {
    let arrayOfColors: string[] = [];

    for (let counter: number = 1; counter < int; counter++) {
      arrayOfColors.push(this.generateRandomColor());
    }

    return arrayOfColors;
  }

  private static generateRandomColor(): string {
    let r = Math.floor(Math.random() * 255);
    let g = Math.floor(Math.random() * 255);
    let b = Math.floor(Math.random() * 255);

    return "rgb(" + r + "," + g + "," + b + ")";
  }

  private static getArrayOfColorsForHeartbeats() {
    return [
      "rgb(255,61,49)",
      "rgb(0,128,0)",
      "rgb(198,233,1)",
      "rgb(169,168,169)",
      "rgb(46,79,93)",
      "rgb(46,50,30)",
      "rgb(27,221,49)",
      "rgb(99,212,199)",
      "rgb(108,202,204)",
      "rgb(91,221,110)",
      "rgb(40,173,228)",
      "rgb(86,110,173)",
      "rgb(220,21,197)",
      "rgb(101,2,25)",
      "rgb(142,242,116)",
      "rgb(181,227,216)",
      "rgb(234,39,73)",
      "rgb(247,154,66)",
      "rgb(204,70,101)",
      "rgb(184,96,127)",
      "rgb(61,49,105)",
      "rgb(13,140,104)",
      "rgb(141,237,88)",
      "rgb(145,154,64)"
    ];
  }

  private static getArrayOfColors() {
    return [
      "rgb(180,151,89)",
      "rgb(198,233,1)",
      "rgb(0,56,116)",
      "rgb(169,168,169)",
      "rgb(46,79,93)",
      "rgb(46,50,30)",
      "rgb(27,221,49)",
      "rgb(99,212,199)",
      "rgb(108,202,204)",
      "rgb(91,221,110)",
      "rgb(40,173,228)",
      "rgb(86,110,173)",
      "rgb(220,21,197)",
      "rgb(101,2,25)",
      "rgb(142,242,116)",
      "rgb(181,227,216)",
      "rgb(234,39,73)",
      "rgb(247,154,66)",
      "rgb(204,70,101)",
      "rgb(184,96,127)",
      "rgb(61,49,105)",
      "rgb(13,140,104)",
      "rgb(141,237,88)",
      "rgb(145,154,64)",
      "rgb(57,193,159)",
      "rgb(204,54,48)",
      "rgb(134,74,69)",
      "rgb(54,107,216)",
      "rgb(209,0,182)",
      "rgb(109,71,200)",
      "rgb(95,245,220)",
      "rgb(83,27,175)",
      "rgb(201,209,247)",
      "rgb(70,11,92)",
      "rgb(210,74,245)",
      "rgb(222,135,3)",
      "rgb(77,8,65)",
      "rgb(224,141,128)",
      "rgb(41,130,246)",
      "rgb(34,24,6)",
      "rgb(240,206,238)",
      "rgb(23,111,141)",
      "rgb(183,185,202)",
      "rgb(107,96,149)",
      "rgb(25,56,161)",
      "rgb(219,210,103)",
      "rgb(227,52,136)",
      "rgb(149,49,67)",
      "rgb(113,92,114)",
      "rgb(101,102,243)",
      "rgb(142,229,162)",
      "rgb(95,179,88)",
      "rgb(66,199,120)",
      "rgb(249,59,189)",
      "rgb(43,222,173)",
      "rgb(95,190,155)",
      "rgb(231,192,84)",
      "rgb(73,17,57)",
      "rgb(63,72,86)",
      "rgb(63,239,5)",
      "rgb(117,106,234)",
      "rgb(66,211,136)",
      "rgb(12,96,52)",
      "rgb(156,176,212)",
      "rgb(16,52,236)",
      "rgb(157,244,254)",
      "rgb(49,180,169)",
      "rgb(191,112,53)",
      "rgb(27,202,8)",
      "rgb(249,243,192)",
      "rgb(139,211,221)",
      "rgb(118,236,128)",
      "rgb(96,66,43)",
      "rgb(221,212,49)",
      "rgb(18,50,26)",
      "rgb(117,136,12)",
      "rgb(193,238,102)",
      "rgb(167,76,1)",
      "rgb(191,42,119)",
      "rgb(231,219,167)",
      "rgb(171,94,224)",
      "rgb(16,209,181)",
      "rgb(166,152,80)",
      "rgb(133,165,8)",
      "rgb(104,238,227)",
      "rgb(39,198,99)",
      "rgb(157,181,35)",
      "rgb(160,243,32)",
      "rgb(54,180,35)",
      "rgb(246,26,84)",
      "rgb(236,229,230)",
      "rgb(178,138,226)",
      "rgb(191,141,87)",
      "rgb(140,244,99)",
      "rgb(53,24,58)",
      "rgb(145,233,173)",
      "rgb(154,218,34)",
      "rgb(74,128,94)",
      "rgb(137,82,229)",
      "rgb(179,211,174)",
      "rgb(80,147,199)",
      "rgb(222,125,177)",
      "rgb(7,137,88)",
      "rgb(236,148,226)",
      "rgb(110,118,184)",
      "rgb(241,30,63)",
      "rgb(194,74,84)",
      "rgb(18,99,168)",
      "rgb(52,189,95)",
      "rgb(72,39,155)",
      "rgb(120,139,249)",
      "rgb(3,184,21)",
      "rgb(7,252,57)",
      "rgb(24,45,114)",
      "rgb(32,183,44)",
      "rgb(183,84,149)",
      "rgb(135,163,90)",
      "rgb(98,178,140)",
      "rgb(220,158,42)",
      "rgb(93,55,10)",
      "rgb(11,46,34)",
      "rgb(251,183,56)",
      "rgb(5,201,27)",
      "rgb(184,84,28)",
      "rgb(204,217,196)",
      "rgb(186,191,71)",
      "rgb(31,109,189)",
      "rgb(177,192,118)",
      "rgb(186,227,14)",
      "rgb(169,102,176)",
      "rgb(89,48,59)",
      "rgb(252,208,97)",
      "rgb(3,121,113)",
      "rgb(203,106,186)",
      "rgb(119,128,75)",
      "rgb(35,22,253)",
      "rgb(179,250,195)",
      "rgb(82,247,216)",
      "rgb(206,45,112)",
      "rgb(1,196,135)",
      "rgb(207,208,67)",
      "rgb(116,46,183)",
      "rgb(185,164,88)",
      "rgb(48,217,175)",
      "rgb(128,234,92)",
      "rgb(209,86,178)",
      "rgb(93,179,95)",
      "rgb(22,33,214)",
      "rgb(61,33,220)",
      "rgb(231,204,167)",
      "rgb(44,186,18)",
      "rgb(38,206,165)",
      "rgb(178,152,36)",
      "rgb(76,42,156)",
      "rgb(86,196,58)",
      "rgb(168,39,220)",
      "rgb(37,114,237)",
      "rgb(26,22,110)",
      "rgb(95,248,0)",
      "rgb(13,234,79)",
      "rgb(17,174,88)",
      "rgb(118,88,247)",
      "rgb(56,129,194)",
      "rgb(91,88,188)",
      "rgb(94,187,57)",
      "rgb(173,44,93)",
      "rgb(121,251,29)",
      "rgb(23,32,149)",
      "rgb(77,18,157)",
      "rgb(66,10,141)",
      "rgb(222,152,164)",
      "rgb(108,223,107)",
      "rgb(194,63,62)",
      "rgb(33,40,65)",
      "rgb(57,71,218)",
      "rgb(234,128,218)",
      "rgb(7,64,15)",
      "rgb(65,249,109)",
      "rgb(50,209,43)",
      "rgb(118,31,208)",
      "rgb(206,119,28)",
      "rgb(246,248,103)",
      "rgb(217,155,76)",
      "rgb(131,247,249)",
      "rgb(3,156,121)",
      "rgb(72,121,56)",
      "rgb(14,207,202)",
      "rgb(154,196,112)",
      "rgb(152,27,84)",
      "rgb(52,115,212)",
      "rgb(159,171,245)",
      "rgb(36,249,125)",
      "rgb(58,42,34)",
      "rgb(70,151,107)",
      "rgb(20,246,151)",
      "rgb(108,27,71)",
      "rgb(8,254,88)",
      "rgb(219,84,149)",
      "rgb(5,202,162)",
      "rgb(110,90,49)",
      "rgb(226,35,105)",
      "rgb(254,74,14)",
      "rgb(208,51,183)",
      "rgb(101,213,162)",
      "rgb(170,74,178)",
      "rgb(4,31,186)",
      "rgb(207,213,77)",
      "rgb(21,231,113)",
      "rgb(246,58,231)",
      "rgb(116,112,23)",
      "rgb(195,104,59)",
      "rgb(189,75,17)",
      "rgb(81,141,42)",
      "rgb(230,250,96)",
      "rgb(179,16,30)",
      "rgb(228,240,243)",
      "rgb(104,241,70)",
      "rgb(139,70,169)",
      "rgb(176,52,202)",
      "rgb(200,125,136)",
      "rgb(187,5,61)",
      "rgb(198,43,113)",
      "rgb(157,252,160)",
      "rgb(19,32,147)",
      "rgb(80,193,80)",
      "rgb(6,34,199)",
      "rgb(54,236,223)",
      "rgb(226,203,127)",
      "rgb(194,163,219)",
      "rgb(89,4,32)",
      "rgb(100,62,173)",
      "rgb(135,142,33)",
      "rgb(67,94,107)",
      "rgb(238,13,180)",
      "rgb(172,72,89)",
      "rgb(45,215,158)",
      "rgb(214,44,164)",
      "rgb(244,180,177)",
      "rgb(180,22,180)",
      "rgb(92,254,230)",
      "rgb(251,194,4)",
      "rgb(177,125,54)",
      "rgb(179,93,190)",
      "rgb(215,113,68)",
      "rgb(74,150,69)",
      "rgb(218,201,134)",
      "rgb(247,186,46)",
      "rgb(109,167,196)",
      "rgb(253,12,37)",
      "rgb(100,6,93)",
      "rgb(34,109,95)",
      "rgb(67,218,100)",
      "rgb(201,10,129)",
      "rgb(197,236,94)",
      "rgb(28,4,109)",
      "rgb(150,139,23)",
      "rgb(212,98,126)",
      "rgb(154,192,13)",
      "rgb(206,210,224)",
      "rgb(57,94,42)",
      "rgb(174,181,44)",
      "rgb(190,244,12)",
      "rgb(63,14,191)",
      "rgb(244,119,116)",
      "rgb(157,112,207)",
      "rgb(207,246,206)",
      "rgb(58,87,197)",
      "rgb(125,174,231)",
      "rgb(242,131,153)",
      "rgb(231,49,251)",
      "rgb(184,238,229)",
      "rgb(76,251,20)",
      "rgb(7,5,220)",
      "rgb(35,116,39)",
      "rgb(174,65,172)",
      "rgb(129,98,199)",
      "rgb(212,158,119)",
      "rgb(242,10,75)",
      "rgb(202,121,3)",
      "rgb(162,29,109)",
      "rgb(202,58,228)",
      "rgb(97,149,13)",
      "rgb(73,13,18)",
      "rgb(159,114,26)",
      "rgb(162,251,30)",
      "rgb(226,147,15)",
      "rgb(97,163,67)",
      "rgb(34,225,90)",
      "rgb(9,105,90)",
      "rgb(84,36,221)",
      "rgb(58,84,151)",
      "rgb(124,115,251)",
      "rgb(30,226,122)",
      "rgb(243,86,32)",
      "rgb(69,230,2)",
      "rgb(110,39,80)",
      "rgb(171,89,114)",
      "rgb(67,158,66)",
      "rgb(118,172,120)",
      "rgb(71,54,141)",
      "rgb(19,232,12)",
      "rgb(87,238,45)",
      "rgb(27,173,207)",
      "rgb(94,242,60)",
      "rgb(76,213,82)",
      "rgb(46,156,27)",
      "rgb(10,132,166)",
      "rgb(117,60,99)",
      "rgb(97,86,132)",
      "rgb(99,10,247)",
      "rgb(210,178,87)",
      "rgb(143,159,177)",
      "rgb(249,18,9)",
      "rgb(51,90,62)",
      "rgb(249,113,50)",
      "rgb(204,142,95)",
      "rgb(9,46,139)",
      "rgb(62,131,233)",
      "rgb(171,182,158)",
      "rgb(50,70,232)",
      "rgb(150,136,209)",
      "rgb(17,218,53)",
      "rgb(144,156,80)",
      "rgb(132,111,30)",
      "rgb(177,23,72)",
      "rgb(219,150,3)",
      "rgb(90,210,13)",
      "rgb(8,33,133)",
      "rgb(246,143,6)",
      "rgb(221,203,26)",
      "rgb(202,253,31)",
      "rgb(58,4,183)",
      "rgb(168,65,183)",
      "rgb(216,22,180)",
      "rgb(140,126,218)",
      "rgb(83,138,164)",
      "rgb(126,244,97)",
      "rgb(193,215,210)",
      "rgb(193,120,170)",
      "rgb(142,108,152)",
      "rgb(114,110,177)",
      "rgb(124,155,170)",
      "rgb(166,72,226)",
      "rgb(134,252,230)",
      "rgb(146,86,115)",
      "rgb(144,139,84)",
      "rgb(254,113,122)",
      "rgb(75,124,223)",
      "rgb(160,50,178)",
      "rgb(220,177,203)",
      "rgb(253,76,50)",
      "rgb(116,52,92)",
      "rgb(7,221,183)",
      "rgb(151,176,221)",
      "rgb(243,67,180)",
      "rgb(53,216,120)",
      "rgb(221,124,105)",
      "rgb(241,46,248)",
      "rgb(5,94,177)",
      "rgb(88,15,215)",
      "rgb(188,213,174)",
      "rgb(38,177,234)",
      "rgb(99,48,84)",
      "rgb(67,246,212)",
      "rgb(149,129,158)",
      "rgb(128,227,127)",
      "rgb(4,229,89)",
      "rgb(9,14,73)",
      "rgb(174,149,123)",
      "rgb(132,31,28)",
      "rgb(29,13,204)",
      "rgb(176,183,180)",
      "rgb(205,137,11)",
      "rgb(199,41,237)",
      "rgb(123,1,169)",
      "rgb(9,254,194)",
      "rgb(159,212,191)",
      "rgb(108,98,95)",
      "rgb(53,213,170)",
      "rgb(88,59,65)",
      "rgb(128,217,136)",
      "rgb(92,137,18)",
      "rgb(245,114,51)",
      "rgb(208,138,58)",
      "rgb(217,67,140)",
      "rgb(166,36,218)",
      "rgb(142,2,174)",
      "rgb(222,65,140)",
      "rgb(176,1,31)",
      "rgb(159,44,34)",
      "rgb(210,102,174)",
      "rgb(134,206,101)",
      "rgb(143,189,233)",
      "rgb(233,94,65)",
      "rgb(138,135,209)",
      "rgb(235,179,220)",
      "rgb(247,119,75)",
      "rgb(49,141,128)",
      "rgb(205,116,254)",
      "rgb(121,97,70)",
      "rgb(160,70,142)",
      "rgb(160,200,170)",
      "rgb(10,89,182)",
      "rgb(142,39,176)",
      "rgb(71,21,91)",
      "rgb(195,35,159)",
      "rgb(60,60,77)",
      "rgb(47,123,233)",
      "rgb(159,108,61)",
      "rgb(66,138,97)",
      "rgb(144,157,45)",
      "rgb(109,31,247)",
      "rgb(207,139,66)",
      "rgb(154,102,29)",
      "rgb(231,164,105)",
      "rgb(215,143,156)",
      "rgb(161,197,122)",
      "rgb(181,120,50)",
      "rgb(146,184,176)",
      "rgb(234,254,59)",
      "rgb(153,25,0)",
      "rgb(160,75,217)",
      "rgb(176,28,89)",
      "rgb(77,74,106)",
      "rgb(6,21,77)",
      "rgb(146,154,193)",
      "rgb(217,55,42)",
      "rgb(61,99,112)",
      "rgb(106,203,125)",
      "rgb(30,90,154)",
      "rgb(139,220,168)",
      "rgb(232,75,134)",
      "rgb(17,147,101)",
      "rgb(143,142,161)",
      "rgb(64,186,217)",
      "rgb(88,137,245)",
      "rgb(80,10,30)",
      "rgb(55,121,30)",
      "rgb(179,147,186)",
      "rgb(43,52,139)",
      "rgb(98,168,78)",
      "rgb(0,84,199)",
      "rgb(212,164,246)",
      "rgb(63,82,231)",
      "rgb(195,211,239)",
      "rgb(187,50,175)",
      "rgb(83,229,105)",
      "rgb(200,86,177)",
      "rgb(243,90,69)",
      "rgb(97,130,183)",
      "rgb(151,23,249)",
      "rgb(40,65,48)",
      "rgb(118,146,136)",
      "rgb(245,164,163)",
      "rgb(57,159,139)",
      "rgb(43,175,145)",
      "rgb(193,5,247)",
      "rgb(173,218,85)",
      "rgb(117,118,244)",
      "rgb(23,2,45)",
      "rgb(17,213,189)",
      "rgb(217,180,10)",
      "rgb(97,53,158)",
      "rgb(107,114,247)",
      "rgb(95,252,199)",
      "rgb(65,240,227)",
      "rgb(245,2,202)",
      "rgb(104,154,215)",
      "rgb(188,96,143)",
      "rgb(89,57,210)",
      "rgb(28,132,95)",
      "rgb(103,148,126)",
      "rgb(247,28,21)",
      "rgb(104,233,58)",
      "rgb(178,97,222)",
      "rgb(220,179,63)",
      "rgb(248,0,224)",
      "rgb(207,179,15)",
      "rgb(4,108,184)",
      "rgb(223,73,125)",
      "rgb(75,47,56)",
      "rgb(164,59,95)",
      "rgb(168,179,110)",
      "rgb(72,50,41)",
      "rgb(225,19,176)",
      "rgb(142,184,240)",
      "rgb(97,198,177)",
      "rgb(249,13,4)",
      "rgb(224,220,159)",
      "rgb(126,58,19)",
      "rgb(195,246,252)",
      "rgb(60,201,166)",
      "rgb(69,0,245)",
      "rgb(212,208,59)",
      "rgb(91,101,78)",
      "rgb(23,217,252)",
      "rgb(121,210,113)",
      "rgb(98,81,210)"
    ];
  }
}