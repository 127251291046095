import React, { ChangeEvent, Component } from "react";
import { Alert, Col, Form, Nav, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Redirect } from "react-router-dom";
import Routes from "../../../../../data/Routes";
import Api from "../../../../../tools/api/api";
import { IAddPermissionToGroupRequest } from "../../../../../tools/api/api-interfaces/system/security/IAddPermissionToGroupRequest";
import { IAddUserToGroupRequest } from "../../../../../tools/api/api-interfaces/system/security/IAddUserToGroupRequest";
import AuthHelper from "../../../../../tools/auth/AuthHelper";
import ValidationErrors from "../../../../../tools/validation-helper/ValidationErrors";
import Validations from "../../../../../tools/validation-helper/Validations";
import withEngineCompartmentAuthorize from "../../../../common/authorize/withEngineCompartmentAuthorize";
import IconButton from "../../../../common/buttons/icon-button/IconButton";
import ContainerPageSize from "../../../../common/layout-components/container-page-size/ContainerPageSize";
import { LoadingBarHeight } from "../../../../common/layout-components/loading-bar/ILoadingBarProps";
import LoadingBar from "../../../../common/layout-components/loading-bar/LoadingBar";
import { VerticalSpaceSize } from "../../../../common/layout-components/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../../common/layout-components/vertical-space/VerticalSpace";
import ValidationMessages from "../../../../common/validation-messages/ValidationMessages";
import ValidationSummary from "../../../../common/validation-summary/ValidationSummary";
import { ISetupSecurityGroupPageProps } from "./ISetupSecurityGroupPageProps";
import { ISetupSecurityGroupPageState } from "./ISetupSecurityGroupPageState";

const DefaultPermissionCode: string = "select";
const DefaultUserId: number = 0;

class SetupSecurityGroupPage extends Component<ISetupSecurityGroupPageProps, ISetupSecurityGroupPageState> {

  constructor(props: ISetupSecurityGroupPageProps) {
    super(props);

    this.state = {
      userId: DefaultUserId,
      permissionCode: DefaultPermissionCode,
      groupId: 0,
      groupName: "",
      users: null,
      permissions: null,
      projectPermissions: [],
      validationErrors: {},
      isLoading: true,
      redirect: null
    };

    this.onDeleteUserFromGroupClick = this.onDeleteUserFromGroupClick.bind(this);
    this.onAddUserClick = this.onAddUserClick.bind(this);
    this.onChangeUser = this.onChangeUser.bind(this);
    this.onDeletePermissionFromGroupClick = this.onDeletePermissionFromGroupClick.bind(this);
    this.onChangePermission = this.onChangePermission.bind(this);
    this.onAddGroupPermission = this.onAddGroupPermission.bind(this);
  }

  render() {
    if (this.state.redirect !== null) {
      return <Redirect to={this.state.redirect}/>;
    }

    return (
      <>
        {
          this.state.isLoading ? <LoadingBar size={LoadingBarHeight.normal}/> :
            <>
              <VerticalSpace size={VerticalSpaceSize.small}/>

              <ContainerPageSize>
                <>
                  <VerticalSpace size={VerticalSpaceSize.small}/>
                  <h1>{this.state.groupName}</h1>
                  <VerticalSpace size={VerticalSpaceSize.small}/>

                  <Row>
                    <Col md={{span: 6, offset: 3}}>
                      <ValidationSummary errors={this.state.validationErrors ?? {}}
                                         excludeKeys={["UserId", "PermissionCode"]}/>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={"6"} className={"my-2"}>
                      <h5>Members</h5>

                      {
                        this.state.users?.usersInGroup.map((user) => {
                          return (
                            <Row key={user.id} className="align-items-center my-1">
                              <Col xs={"2"} style={{maxWidth: "4rem"}}>
                                <IconButton onClick={() => this.onDeleteUserFromGroupClick(user.id)}
                                            variant="outline-primary"
                                            iconType={"remove"} styles={{marginRight: '1em'}}/>
                              </Col>
                              <Col xs={{span: 10, order: 1}}>
                                <span className="text-break">{user.name}</span>
                              </Col>
                            </Row>
                          );
                        })
                      }

                      <Row>
                        <Col xs={"2"} style={{maxWidth: "4rem"}}>
                          <IconButton onClick={this.onAddUserClick} variant="outline-primary"
                                      iconType={"submit"} styles={{marginRight: '1em'}}/>
                        </Col>
                        <Col xs={{span: 10, order: 1}}>
                          <Form.Group controlId="membersSelect">
                            <Form.Control as="select" value={this.state.userId}
                                          onChange={(e: ChangeEvent<HTMLInputElement>) => this.onChangeUser(e)}>
                              <option value={DefaultUserId}>-- Please select --</option>
                              {
                                this.state.users?.allUsers.map((user, index) => {
                                  return <option key={index} value={user.id}>{user.name}</option>;
                                })
                              }
                            </Form.Control>
                            <ValidationMessages fieldName="UserId" errors={this.state.validationErrors}/>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>

                    <Col md={"6"} className={"my-2"}>
                      <h5>Group permissions</h5>

                      {
                        this.state.permissions?.groupPermissions.map((permission, index) => {
                          return (
                            <Row key={index} className="align-items-center my-1">
                              <Col xs={"2"} style={{maxWidth: "4rem"}}>
                                <IconButton onClick={() => this.onDeletePermissionFromGroupClick(permission.code)}
                                            variant="outline-primary" iconType={"remove"}
                                            styles={{marginRight: '1em'}}/>
                              </Col>
                              <Col xs={{span: 10, order: 1}}>
                                <span className="text-break">{permission.name}</span>
                              </Col>
                            </Row>
                          );
                        })
                      }

                      <Row>
                        <Col xs={"2"} style={{maxWidth: "4rem"}}>
                          <IconButton onClick={this.onAddGroupPermission} variant="outline-primary"
                                      iconType={"submit"} styles={{marginRight: '1em'}}/>
                        </Col>
                        <Col xs={{span: 10, order: 1}}>
                          <Form.Group controlId="groupPermissionSelect">
                            <Form.Control as="select" value={this.state.permissionCode}
                                          onChange={(e: ChangeEvent<HTMLInputElement>) => this.onChangePermission(e)}>
                              <option value={DefaultPermissionCode}>-- Please select --</option>
                              {
                                this.state.permissions?.allPermissions.map((permission, index) => {
                                  return <option key={index} value={permission.code}>{permission.name}</option>;
                                })
                              }
                            </Form.Control>
                            <ValidationMessages fieldName="PermissionCode" errors={this.state.validationErrors}/>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <VerticalSpace size={VerticalSpaceSize.small}/>

                  <Row>
                    <Col md={"6"}> </Col>
                    <Col md={"6"}>
                      <h5>Project permissions</h5>

                      {
                        this.state.projectPermissions && this.state.projectPermissions.length === 0 &&
                        <div>
                            <VerticalSpace size={VerticalSpaceSize.small}/>
                            <Row>
                                <Col md={{span: "6", offset: "3"}}>
                                    <Alert variant="info" className={"text-center"}>
                                        No project permissions.
                                    </Alert>
                                </Col>
                            </Row>
                        </div>
                      }

                      {
                        this.state.projectPermissions.map((projectPermission, index) => {
                          return (
                            <div key={"projectPermission_" + index} className="text-break">
                              <LinkContainer to={"#"} className={"p-0"}
                                             onClick={() => this.onProjectNameClick(projectPermission.projectId)}>
                                <Nav.Link>{projectPermission.projectName}</Nav.Link>
                              </LinkContainer>

                              {
                                projectPermission.permissions.map((permission, j) => {
                                  return (
                                    <div key={"permission_" + j} className="mx-4">
                                      {permission.name}
                                    </div>
                                  );
                                })
                              }

                            </div>
                          );
                        })
                      }
                    </Col>
                  </Row>
                </>
              </ContainerPageSize>
            </>
        }
      </>
    );
  }

  async componentDidMount() {
    let groupId = parseInt(this.props.securityGroupId as string);

    let token = AuthHelper.getAccessToken() ?? '';

    try {
      const response = await Api.getSecurityGroupDetails(token, groupId);

      let state = {...this.state};
      state.groupId = groupId;
      state.groupName = response.groupName;
      state.users = response.users;
      state.permissions = response.permissions;
      state.projectPermissions = response.projectPermissions;
      state.isLoading = false;
      this.setState(state);
    } catch (e) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get security group details from the server', e)
      );
    }
  }

  private async onDeleteUserFromGroupClick(userId: number) {
    let token = AuthHelper.getAccessToken() ?? '';

    try {
      const response = await Api.deleteUserFromGroup(token, this.state.groupId, userId);
      this.setState({users: response.users});
    } catch (e) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t delete user from group on the server', e)
      );
    }
  }

  private onChangeUser(event: ChangeEvent<HTMLInputElement>) {
    let userId = parseInt(event.target.value);

    if (userId > DefaultUserId) {
      this.setState({userId: userId, validationErrors: {}});
    } else {
      this.setState({userId: DefaultUserId, validationErrors: {}});
    }
  }

  private async onAddUserClick() {
    if (this.state.userId === DefaultUserId) {
      this.addValidationError('UserId', 'Please select user');
      return;
    }

    let token = AuthHelper.getAccessToken() ?? '';
    let request: IAddUserToGroupRequest = {
      userId: this.state.userId,
      groupId: this.state.groupId
    };

    try {
      const response = await Api.addUserToGroup(token, request);
      this.setState({
        users: response.users,
        userId: DefaultUserId
      });
    } catch (e) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t add user to group on the server', e)
      );
    }
  }

  private async onDeletePermissionFromGroupClick(permissionCode: string) {
    let token = AuthHelper.getAccessToken() ?? '';

    try {
      const response = await Api.deletePermissionFromGroup(token, this.state.groupId, permissionCode);
      this.setState({permissions: response.permissions});
    } catch (e) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t delete permission from group on the server', e)
      );
    }
  }

  private onChangePermission(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value !== DefaultPermissionCode) {
      this.setState({permissionCode: event.target.value, validationErrors: {}});
    } else {
      this.setState({permissionCode: DefaultPermissionCode, validationErrors: {}});
    }
  }

  private async onAddGroupPermission() {
    if (this.state.permissionCode === DefaultPermissionCode) {
      this.addValidationError('PermissionCode', 'Please select permission');
      return;
    }

    let token = AuthHelper.getAccessToken() ?? '';
    let request: IAddPermissionToGroupRequest = {
      permissionCode: this.state.permissionCode,
      groupId: this.state.groupId
    };

    try {
      const response = await Api.addPermissionToGroup(token, request);
      this.setState({
        permissions: response.permissions,
        permissionCode: DefaultPermissionCode
      });
    } catch (e) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t add permission to group on the server', e)
      );
    }
  }

  private addValidationError(key: string, message: string) {
    let state = {...this.state};
    state.validationErrors = {};
    Validations.addError(state.validationErrors, key, message);
    this.setState(state);
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }

  private onProjectNameClick(projectId: number) {
    this.setState({redirect: Routes.buildProjectSecurityUrl(projectId)});
  }
}

export default withEngineCompartmentAuthorize(SetupSecurityGroupPage);